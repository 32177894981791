/*.......*/
.rs-picker-daterange-panel {
  background-color: var(--base-header-color);
  border: 1px solid var(--color-white-10);
  color: var(--white-color);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0
}
[hidden] {
  display: none
}
a {
  background-color: transparent
}
a:active,
a:hover {
  outline: 0
}
svg:not(:root) {
  overflow: hidden
}
button {
  border-radius: 0;
  overflow: visible
}
button {
  text-transform: none
}
button {
  -webkit-appearance: button;
  cursor: pointer
}
button[disabled] {
  cursor: not-allowed
}
button::-moz-focus-inner {
  border: 0;
  padding: 0
}
table {
  border-collapse: collapse;
  border-spacing: 0
}
th {
  padding: 0
}
[tabindex="-1"] {
  outline: 0
}
button {
  border-width: 0
}
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}
html {
  -webkit-tap-highlight-color: transparent
}
[role=button] {
  cursor: pointer
}
.rs-btn {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border: none;
  border: var(--rs-btn-default-border, none);
  border-radius: 6px;
  color: #575757;
  color: var(--rs-btn-default-text);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: color .2s linear, background-color .3s linear;
  transition: color .2s linear, background-color .3s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap
}
@media not all and (-webkit-min-device-pixel-ratio:0),
not all and (min-resolution:0.001dpcm) {
  .rs-btn {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000)
  }
}
.rs-btn-primary {
  background: var(--color-gradient);
  color: var(--color-black);
  border: 1px solid transparent !important
}
.rs-btn-primary:focus {
  outline: 0;
  box-shadow: none;
  color: initial;
  background-color: initial;
  border-color: transparent !important
}
.rs-btn-primary:hover {
  color: var(--color-black);
  background-color: inherit;
  border: 1px solid !important;
  border-color: transparent !important;
  transition: var(--transiton-3s)
}
.rs-btn-primary:active {
  background-color: #1675e0;
  background-color: var(--rs-btn-primary-active-bg);
  color: #fff;
  color: var(--rs-btn-primary-text)
}
.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
  color: var(--color-black);
  opacity: .5
}
.rs-btn-subtle {
  background-color: transparent;
  border: none;
  color: #8e8e93;
  color: var(--rs-btn-subtle-text)
}
.rs-btn-subtle:focus,
.rs-btn-subtle:hover {
  background-color: var(--color-white-10);
  color: var(--color-whate)
}
.rs-btn-subtle:active {
  background-color: var(--color-white-10);
  color: var(--color-whate)
}
.rs-btn-subtle.rs-btn-disabled,
.rs-btn-subtle:disabled {
  background: 0 0;
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text)
}
.rs-btn-link {
  background-color: transparent;
  border: none;
  color: var(--color-white-60)
}
.rs-btn-link:focus,
.rs-btn-link:hover {
  background-color: transparent;
  color: var(--color-whate);
  text-decoration: underline
}
.rs-btn-link:active {
  background-color: transparent;
  color: #004299;
  color: var(--rs-btn-link-active-text)
}
.rs-btn-link.rs-btn-disabled,
.rs-btn-link:disabled {
  color: var(--color-white-60)
}
.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px
}
.rs-btn-icon.rs-btn-sm {
  line-height: 16px;
  padding: 7px
}
.rs-btn-icon.rs-btn-sm>.rs-icon {
  font-size: 16px
}
.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px
}
.rs-btn-icon.rs-btn-xs {
  line-height: 12px;
  padding: 6px
}
.rs-btn-icon.rs-btn-xs>.rs-icon {
  font-size: 12px
}
.rs-btn-group {
  display: inline-block;
  vertical-align: middle
}
.rs-btn-group,
.rs-btn-group>.rs-btn {
  position: relative
}
.rs-btn-group>.rs-btn:active,
.rs-btn-group>.rs-btn:focus {
  z-index: 2
}
.rs-btn-group:not(.rs-btn-group-vertical)>.rs-btn {
  float: left
}
.rs-btn-group:not(.rs-btn-group-vertical)>.rs-btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}
.rs-btn-group:not(.rs-btn-group-vertical)>.rs-btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}
.rs-btn-toolbar {
  line-height: 0
}
.rs-btn-toolbar>:not(:first-child):not(.rs-btn-block) {
  margin-left: 10px
}
.rs-calendar {
  min-height: 266px;
  overflow: hidden;
  padding-top: 12px;
  position: relative
}
.rs-calendar-panel .rs-calendar-header {
  padding-bottom: 6px;
  width: 100%
}
.rs-calendar-panel .rs-calendar-header:after,
.rs-calendar-panel .rs-calendar-header:before {
  content: " ";
  display: table
}
.rs-calendar-panel .rs-calendar-header:after {
  clear: both
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right
}
.rs-calendar-panel .rs-calendar-header-backward,
.rs-calendar-panel .rs-calendar-header-forward {
  float: none !important
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important
}
.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  padding-bottom: 8px;
  padding-top: 8px
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  background-color: #3498ff;
  background-color: var(--rs-calendar-today-bg);
  border-radius: 50%;
  color: #fff;
  color: var(--rs-calendar-today-text);
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 20px
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: none;
  box-shadow: none
}
.rs-calendar-panel .rs-calendar-table-cell-day {
  display: block;
  margin-top: 3px;
  text-align: center
}
.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: -2px;
  top: 54px;
  width: calc(100% - 24px)
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
  height: 644px
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  height: 100px;
  overflow: hidden;
  width: 100%
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  vertical-align: top
}
.rs-calendar-panel .rs-calendar-table-cell {
  line-height: 0;
  padding: 0 1px
}
.rs-calendar-panel .rs-calendar-table-cell-content {
  border-radius: 0;
  width: 100%
}
.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
  border-bottom-left-radius: 6px
}
.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
  border-bottom-right-radius: 6px
}
.rs-calendar-month-dropdown-scroll div:focus {
  outline: 0
}
.rs-calendar-header {
  padding-left: 12px;
  padding-right: 12px;
  width: 280px
}
.rs-calendar-header:after,
.rs-calendar-header:before {
  content: " ";
  display: table
}
.rs-calendar-header:after {
  clear: both
}
.rs-calendar-header-month-toolbar {
  display: inline-block
}
.rs-calendar-header-month-toolbar {
  float: left
}
.rs-calendar-header-title {
  margin: 0 4px
}
.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
  background: 0 0;
  cursor: not-allowed;
  opacity: .3
}
.rs-calendar-header-btn-disabled:after {
  display: none !important
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:after,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:before {
  content: " ";
  display: table
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:after {
  clear: both
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: left
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: right
}
[dir=rtl] .rs-calendar-header-backward .rs-icon,
[dir=rtl] .rs-calendar-header-forward .rs-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}
.rs-calendar-view {
  padding: 4px 12px 12px
}
.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%
}
.rs-calendar-table-row {
  display: table-row
}
.rs-calendar-table-cell, .rs-calendar-table-header-cell {
  display: table-cell;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
  width: 1%
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover {
  color: #c5c6c7;
  color: var(--rs-text-disabled)
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  background: 0 0;
  cursor: not-allowed;
  text-decoration: line-through
}
.rs-calendar-table-cell-content {
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px
}
.rs-calendar-table-cell-content:hover {
  background-color: var(--color-white-10)
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  background: var(--color-white-10);
  border-radius: 500px
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  font-size: 12px;
  line-height: 1.66666667;
  padding-bottom: 2px;
  padding-top: 2px
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: 0 0;
  cursor: auto
}
.rs-calendar-month-dropdown {
  background-color: var(--color-border);
  border-top: 1px solid var(--color-border) !important;
  display: none;
  margin-top: 0;
  overflow: hidden;
  position: absolute;
  top: 44px;
  width: 100%;
  z-index: 1
}
.rs-calendar-month-dropdown-scroll {
  height: 230px
}
.rs-calendar-month-dropdown-row {
  padding: 5px 12px 5px 52px;
  position: relative
}
.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dotted var(--color-border)
}
.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  margin: 1px;
  text-align: center;
  vertical-align: middle;
  width: calc(16.66667% - 2px)
}
@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .rs-calendar-month-dropdown-cell {
    width: calc(16.66667% - 2.16667px)
  }
}
@supports (-ms-ime-align:auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc(16.66667% - 2.16667px)
  }
}
.rs-calendar-month-dropdown-cell-content {
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px
}
.rs-calendar-month-dropdown-cell-content:hover {
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg)
}
.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
  background: 0 0;
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  cursor: not-allowed;
  text-decoration: line-through
}
@-webkit-keyframes i {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}
@keyframes i {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}
@-webkit-keyframes j {
  0% {
    -webkit-transform: none;
    transform: none
  }
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}
@keyframes j {
  0% {
    -webkit-transform: none;
    transform: none
  }
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}
@-webkit-keyframes k {
  0% {
    -webkit-transform: none;
    transform: none
  }
  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}
@keyframes k {
  0% {
    -webkit-transform: none;
    transform: none
  }
  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}
.rs-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}
.rs-picker-toolbar {
  border-top: 1px solid var(--dropdown-dorder-color);;
  padding: 12px;
  background-color: var(--color-white-05);
}
.rs-picker-menu .rs-picker-toolbar {
  max-width: 100%
}
.rs-picker-menu .rs-calendar {
  display: block;
  margin: 0 auto;
  min-width: 246px
}
.rs-picker-menu .rs-calendar-header {
  width: 100%
}
.rs-picker-menu .rs-calendar-table {
  width: unset
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 5;
}
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content{
  opacity: 0.5;
} 
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--color-white-10);
  color: var(--base-font-color);
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  -ms-border-radius: 500px;
  -o-border-radius: 500px
}
.rs-picker-menu .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  height: 24px;
  padding-top: 0
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  display: inline-block;
  padding-left: 0;
  padding-right: 0
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  /* background-color: var(--color-white-10); */
  color: var(--color-white)
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 227px
}
.rs-picker-daterange-men u .rs-calendar {
  display: inline-block;
  height: 276px;
  padding-bottom: 12px
}
.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 1px solid var(--color-white-10)
}
.rs-picker-daterange-menu .rs-calendar-header {
  text-align: center;
  width: 100%
}
.rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
  float: none
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 6;
}
.rs-picker-daterange-header {
  border-bottom: 1px solid var(--dropdown-dorder-color);
  font-size: 14px;
  line-height: 1.42857143;
  padding: 8px 12px;
  background-color: var(--dropdown-header-bg);;
}
.rs-picker-daterange-calendar-group {
  height: 274px;
  min-width: 492px;
  display: flex;
  background-color: var(--dropdown-bg-color);
}
.rs-picker-daterange-predefined {
  border-right: 0;
  height: 366px;
  padding: 4px 0
}
.rs-picker-daterange-predefined .rs-btn {
  display: block
}
.rs-panel {
  border-radius: 6px;
  overflow: hidden
}
.rs-panel-body,
.rs-panel-header {
  padding: 20px
}
.rs-panel-body-fill {
  padding: 0 !important
}
.rs-panel-header {
  color: #272c36;
  color: var(--rs-text-heading);
  font-size: 16px;
  line-height: 1.25
}
.rs-panel-title {
  margin: 0
}
.rs-panel-title a {
  color: inherit
}
.rs-panel-title a:active,
.rs-panel-title a:focus,
.rs-panel-title a:hover {
  text-decoration: none
}
.rs-panel-header+.rs-panel-body {
  padding-top: 0
}
.rs-panel-group {
  border-radius: 6px;
  overflow: hidden
}
.rs-panel-group>.rs-panel {
  border: none
}
.rs-panel-group>.rs-panel+.rs-panel {
  position: relative
}
.rs-panel-group>.rs-panel+.rs-panel:before {
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  content: "";
  left: 20px;
  position: absolute;
  right: 20px;
  top: 0
}
.rs-picker-disabled {
  opacity: .3
}
.rs-picker-subtle .rs-btn {
  background-color: transparent;
  border: none;
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  -webkit-transition: none;
  transition: none
}
.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-btn:hover {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text)
}
.rs-picker-subtle .rs-btn:active {
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg);
  color: #272c36;
  color: var(--rs-btn-subtle-active-text)
}
.rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-picker-subtle .rs-btn:disabled {
  background: 0 0;
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text)
}
.rs-picker-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  /* left:  auto !important;
  right: 10px !important; */
  top: 142px!important;
}
.rs-picker-menu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
  -webkit-box-shadow: var(--rs-shadow-overlay);
  box-shadow: var(--rs-shadow-overlay);
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
  -webkit-transition: none;
  transition: none;
  z-index: 1050;
}
.datepicker-top-position {
  top: 190px!important;
  transform: translateX(26px);
}
.rs-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
  display: block;
  background-color: var(--left-panel-bg);
  border: 1px solid var(--color-white-10);
  color: var(--white-color);
  border-radius: 3px;
}
.rs-calendar-month-dropdown-year {
  left: 12px;
  position: absolute;
  top: calc(50% - 0.5em);
}
.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-selected:before {
  content: "";
  display: block;
  height: 24px;
  margin-top: 4px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.rs-calendar-table-cell-in-range,
.rs-calendar-table-cell-selected {
  position: relative;
}
.rs-calendar-table-cell-in-range:before {
  background-color: var(--color-white-05);
}
.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-selected:before {
  content: "";
  display: block;
  height: 24px;
  margin-top: 4px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.rs-calendar-table-cell {
  display: table-cell;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
  width: 1%;
}
.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month {
  visibility: hidden;
}
.rs-calendar-table-cell-in-range,
.rs-calendar-table-cell-selected {
  position: relative;
}
.rs-picker .rs-picker-toggle.rs-btn {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.rs-btn-default input.rs-picker-toggle-textbox{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.rs-btn-default input.rs-picker-toggle-textbox.rs-picker-toggle-read-only{
  opacity: 0;
  height: 100%;
}
.rs-picker-toggle-value{
  opacity: 0;
  display: none;
}
.rs-btn-default ::placeholder {
  display: none;
}
.rs-btn-default .rs-picker-toggle-placeholder{
  display: none;
}
.rs-btn-default .rs-icon{
  display: none;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--color-white-03);
  color: var(--colr-white);
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  background-color: var(--color-white-10);
  box-shadow: none;
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
}
.rs-calendar-month-dropdown-cell-content {
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  height: 30px;
  width: 30px;
}
.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  margin: 1px;
  text-align: center;
  vertical-align: middle;
  width: calc(16.66667% - 2px);
}