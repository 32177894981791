:root.light-theme .glow-shadow,
:root.light-theme .glow-shadow .popover .popover-body {
    /*-- new root var --*/
    --body-bg:#F6F9FF;
    --color-black: #000000;
    --leftpenal-header-color-95: #181818;
    --color-opacity-black: rgba(0, 0, 0, 0);
    --base-font-color: var(--color-black);
    --guppie_green: #00ff6a;
    --radium_green:#1EBF61;
    --dark-yellow:#EF9817;
    --text-warning: #ff8900;
    --color-warning: #FCA120;
    --lemon-yellow:#FFD000;
    --calendar-bg-warning:#ffd3a0;
    --dark-blue:#000645;
    --bg-danger:#dc3545;
    --blue100: #0f00ff;
    --sea-green: #198754;
    --sky-blue:#367CF6;
    --calednar-inprogress-background: #579BFC;
    --color-blue:#11ABF1;
    --white91:#e7e7e7;
    --white-color: #ffffff;
    --white255:var(--white-color);
    --white79:#c7c7cc;
    --mainly_blue:#0A1D29;
    --white95:#eef7f6;
    --equally_black:#0F0F0F;
    --base-color: #0F2A3C;
    --base-color-1:#263848;
    --base-color-2:#2c4250;
    --base-body-color: #E4ECF7;
    --queen_blue: #59718C;
    --color-whiteF2: #F2F2F2;
    --color-white-05: var(--color-whiteF2);
    --seperator-line: #CFD4D8;
    --squid_ink: #0f2a3cb8;
    --squid_ink58: #16293A;
    --squid_ink69: #1d3545;
    --squid_ink70: #1B3546;
    --base-header-bg: #1c3545;
    --action-icon-color: #112C3E;
    --alice_blue: #ebf1f9;
    --icone-hover-color-black-2: #222222;
    --light-theme-border-color: #E2E2E2;
    --alice_blue0: #f3f8ff;
    --alice_blue80: #f5f9fd;
    --alice_blue243: #e3eaf39a;
    --alice_blue250: #EFF4FA;
    --alice_blue251: #eff4fb;
    --paynes-gray: #576976;
    --blue-border:#d8e2f0;
    --header-bg:#ECF1F8;
    --white-bg: var(--white-color);
    --base-header-color: var(--white-color);
    --inprogress-status: #BEC5CA;
    --calendar-bg-green:#b4fcd2;
    --calednar-todo-border: #BDC4C9;
    --calendar-date-color: #0C0B0B;
    --base-body-color-new: #364C63;
    --light-body-bg: hsl(214.29deg 53.85% 94.9%);
    --btn-secondary: var(--light-body-bg);
    --comman-round-after: #D0D4D8;
    --calendar-bg-todo:#ddedff;
    --calendar-bg-blue:#a4e2ff;
    --color-green-theme-light: linear-gradient( 135deg,var(--guppie_green) 0%,#00fdd5 100%);
    --color-gradient: linear-gradient(135deg, var(--guppie_green) 0%,rgb(0 253 213) 100%);
    --tooltip-color: var(--base-font-color);
    --base-modal-backdrop-color: var(--base-body-color);
    --color-white-60: var(--base-color);
    --action-icon-bg: #ECF1F9;
    --datepicker-text-color: rgba(0, 0, 0, 0.502);
    --white-op90: rgb(255 255 255 / 90%);
    --black-op01: rgba(0, 0, 0, 0.1);
    --black-op5: rgb(0 0 0 / 5%);
    --black-op10: rgba(0, 0, 0, 0.10);
    --black-op15: rgb(255 255 255 / 15%);
    --black-op20: rgb(0 0 0 / 20%);
    --black-op40: rgb(0 0 0 / 40%);
    --black-op50: rgb(0 0 0 / 50%);
    --black-op60: rgb(0 0 0 / 60%);
    --black-op70: rgb(0 0 0 / 70%);
    --black-op08: rgb(0 0 0 / 8%);
    --black-op90: rgb(0 0 0 / 90%);
    --blue-op5: rgb(0 6 69 / 5%);
    --blue-op10: rgba(0, 6, 69, 0.10);
    --blue-op20: rgb(15 23 42 / 20%);
    --blue-op40: rgb(0 6 69 / 40%);
    --blue-op50: rgb(0 6 69 / 50%);
    --alice_blue_op3:rgba(228, 236, 247, 0.3);
    --alice_blue_op5:rgba(228, 236, 247, 0.50);
    --alice_blue_op241:rgb(225 232 241);
    --white_op3: rgba(255, 255, 255, 0.3);
    --white_op5: rgb(255 255 255 / 5%);
    --white_op70: rgb(235 241 249 / 70%);
    --white_op80: rgb(255 255 255 / 80%);
    --color-white-07: rgb(0 0 0 / 7%);
    --sky_blue_op10: rgb(15 42 60 / 10%);
    --sky_blue_op40: rgb(15 42 60 / 40%);
    --sky_blue_op50: rgb(89 113 140 / 50%);
    --dark_navy_blue-op5: rgba(0, 6, 69, 0.05);
    --lightbase-header-color: rgb(0 0 0 / 10%);
    --color-white-old-05: rgba(255, 255, 255, 0.05);
    --bs-border-color: rgb(29 53 69 / 30%);
    --color-white-10: rgb(12 11 11 / 15%);
    --color-white-20: rgb(29, 53, 69, 20%);
    --color-top-calendar:rgb(242 246 252);
    --color-white3: rgb(0 0 0 / 3%);
    --color-chet-text-box: rgba(12, 11, 11, 0.8);
    --calendar-modal-color: rgb(251 251 251);
    --staf-user-border-color: rgba(28, 53, 69, 0.4);
    --svg-light-theme-left-panel-color: rgb(10 56 116 / 70%);
    --color-border-light: rgba(29, 53, 69, 0.4);
    --dropdown-border-color: rgba(29, 53, 69, 0.1);
    --light-theme-footer-mid-btn-animation-bg: rgb(113 152 193 / 8%);
    --onboarding-footer-tab: rgb(179 195 217);
    --calendar-table-cell-bg: rgb(28 53 68 / 6%);
    --comman-popover-bg: rgba(255, 255, 255, 1);
    --footer-mid-circle-bg: rgb(0 0 0 / 3%);
    --squid-ink-opa015: rgba(27, 53, 70, 0.15);
    --squid-ink-opa02: rgba(27, 53, 70, 0.2);
    --squid-ink-opa05: rgba(27, 53, 70, 0.05);
    --alice-blue-opa245: rgb(228 236 245);
    --alice-blue-opa53: rgb(228 236 245 / 53%);
    --alice-blue-opa98: rgb(255 255 255 / 98%);
    --alice-blue-opa00: rgb(255 255 255 / 0%);
    --alice-blue-opa89: rgb(255 255 255) 89.06%;
    --alice-blue-opa60: rgb(255 255 255 / 60%);
    --alice-blue-opaf7: #e4ecf7;
    --alice-blue: rgb(255 255 255);
    --alice-blue-opa60: rgb(255 255 255 / 70%);
    --alice-blue-opa06: rgba(255, 255, 255, 0.6);
    --color-white-03-solid: var(--white-color);
    --color-black-03:rgba(0,0,0,0.03);
    --accordian-header-bg:#cacaca;
    --base-modal-bg: var(--white-color);
    --bs-white: rgb(0 0 0);
    --multi-img-bg:#EAEDF1;
    --card-color:rgb(246 249 254);
    --light-chatbox-bg:#e1e7f1;
    --color-white2: rgb(0 0 0 / 2%);
    --base-font-color-80: rgb(12 11 11 / 80%);
    --color-white-old-80: rgba(255, 255, 255, 0.8);
    --list-accordian-title-boxshadow:1px 2px 5px 0px rgba(28, 53, 68, 10%);
    --color-white-04:var(--white-color);
    --lock-slider-bg: var(--white-color);
    --badge-background:#00000012;
    --comman-table-thead-bg:var(--white-color);
    --triangle-border-color: var(--white-color);
    --tooltip-shadow: 0px 0px 5px 2px rgb(0 0 0 / 1%);
    --tooltip-after-arrow: #dbdada;
    --bg-white-hex-03: var(--white-color);
    --black-box-shhadow-20:0px 0px 14px 2px var(--black-op20);
    --color-box-shadow-dropdown:0px 0px 10px 0px var(--color-opacity-black);
    --black-box-shadow-1:0px 0px 1px 3px rgb(0 0 0 / 4%);
    --badge-bg-new: hsl(213.33deg 39.13% 95.49%);
    --onboarding-footer-tab-active: #3a4e5a;
    --topicmodal-calendar-bg: #e3eaf3;
    --popover-text-color: var(--color-white);
    --dropdown-border: #eaeaea;
    --custom-modal-header-color:var(--base-header-color);
    --custom-modal-footer-color:var(--base-header-color);
    --color-gradient-45-check: linear-gradient(-45deg, var(--guppie_green), #00fdd5);
    --task-card-header-box-shadow-light: 1px 2px 5px 0px rgba(28, 53, 68, 20%);
    --after-login-header-shadow: 0px 0px 10px 0px rgba(28 53 68 / 20%);
    --footer-action-icon-box-shadow: 0px 0px 3px 0px var(--black-op40);
    --action-icon-box-shadow-light: 0px 0px 10px 0px var(----lightbase-header-color);
    --badge-inner-bg:var(--white-color);
    --autocomplete-bg: var(--light-body-bg);
    --color-white-30: var(--white-color);
    --footer-brop-bg: linear-gradient(180deg, rgba(246, 249, 255, 0.00) 0%, rgba(246, 249, 255, 0.50) 45.98%, #F6F9FF 100%);
    --color-white: #455461;
    --color-white-80: var(--color-black);
    --tooltip-bg: var(--white-color);
    /*Tooptip */
    --loader-filter: hue-rotate(110deg)invert(5)saturate(1.5)contrast(23.5);
    --dropdown-bg-color: var(--white-color);
    --dropdown-box-shadow-color: 0px 0px 10px 0px rgba(0,0,0,0.2);
    --comman-bg-overlay:linear-gradient(270deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0.5) 100%);
    /* --- box shadow color --- */
    --black-boxshadow-2:0px 0px 290px 0px rgba(0,0,0,0.2);
    --color-box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.07);
    --color-shadow-light: 0px 0px 10px 0px rgba(28 53 68 / 12%);
    --color-card-shadow:0px 0px 3px 0px rgba(0, 0, 0, 0.05);
    --box-shadow-dropdown: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    --modal-overlay: rgb(228 236 247 / 68%) 0px 0px 0px 5000px;
    /* --- font weight --- */
    --tooltip-fw: var(--fw-semibold);
    /* box shadow var */
    --tooltip-after-box-shadow: 0 0 3px 0 var(--alice_blue);
    --comman-box-boxshadow: 0px 0px 25px 0px var(--squid-ink-opa015);
    --top-calendar-shadow: -5px 0px 14px 2px var(--squid-ink-opa02);
    --comman-input-with-selectmenu-shadow: 0px 2px 10px 0px var(--squid-ink-opa05);
    --top-after-login-header-shadow: 0px 4px 15px 0 var(--black-op5);
    --liteversion-input-checlist-shadow: 0px 4px 18px 0px rgba(27, 53, 70, 0.20);
    --kpi-main-box-shadow: -40px 0 20px -8px var(--white-bg);
    --overall-scroll-gery-shadow: 30px 0px 0px 0px var(--alice-blue-opaf7), -30px 0px 0px 0px var(--alice-blue-opaf7);
    --overall-scroll-white-shadow: 30px 0px 0px 0px var(--white-bg), -30px 0px 0px 0px var(--white-bg)
}
/* RegNewFlow strt */
.light-theme .glow-shadow .RegNewFlow svg.c-icons[fill]{
    color: var(--color-black);
}
.fcolorblue{
    color: var(--blue100);
}
.fcolorgreen{
    color: var(--sea-green);
}
.fcolororange{
    color: var(--color-warning);
}
.light-theme .glow-shadow .border.activeblueborder{
    border-color: var(--blue100) !important;
  }
  .light-theme .glow-shadow .border.activegreenborder{
    border-color: var(--sea-green) !important;
  }
  .light-theme .glow-shadow .border.activeorangeborder{
    border-color: var(--color-warning) !important;
  }
.RegNewFlow .onboarding-header{
    font-family: var(--title-family);
}
.light-theme body .RegNewFlow,
.light-theme .RegNewFlow .badge,
.light-theme .RegNewFlow a, .light-theme .RegNewFlow .color-white-80,
.light-theme .RegNewFlow .color-white,
.light-theme .RegNewFlow .form-check .form-check-label a.color-green,
.cblue{
    color: var(--color-black);
}
.light-theme .RegNewFlow .sub-header,
.light-theme .RegNewFlow .row-btn-box {
    color: var(--black-op50);
}
.light-theme .RegNewFlow .color-white-60.light-theme-color-white-70,
.light-theme .glow-shadow .color-white-60.light-theme-color-white-70{
    color: var(--black-op50);
}
.light-theme .glow-shadow .RegNewFlow  svg.color-green.c-icons[fill],
.light-theme .RegNewFlow .color-green{
    color: var(--radium_green);
}
.light-theme .RegNewFlow .star-rating-items.active svg.c-icons[fill]{
    color: var(--dark-yellow);
}
.color-greenpill{
    color: var(--radium_green) !important;
}
.color-redpill{
    color: var(--color-red)!important;
  }
  .bgsucessgreen{
    background-color: var(--sea-green);
  }
.light-theme .RegNewFlow .badge-custom,
.light-theme .RegNewFlow .btn-primary.light-theme-blue-bg
{
    background: var(--color-black);
    color: var(--white-bg) !important;
}
.light-theme .RegNewFlow .btn-primary ,
.light-theme .RegNewFlow .progress-slider .progress .progress-bar{
    background: var(--color-black);
    color: var(--white-bg);
    border-radius: 3px;
}
.light-theme .RegNewFlow .btn-primary.yellowbg{
    background: var(--lemon-yellow);
    color: var(--color-black);
}
.light-theme .RegNewFlow .btn-primary.yellowbg:hover{
    background:transparent;
    border-color: var(--lemon-yellow) !important;
}
.light-theme .RegNewFlow .btn-primary.yellowbg svg.c-icons[fill]{
  filter: invert(1);
}
.light-theme .RegNewFlow .btn{
    font-family: var(--title-family);
    font-weight: 600;
}
.light-theme .RegNewFlow .btn-primary svg.c-icons[fill]{
    color: var(--white-bg);
}
.light-theme .RegNewFlow input:checked ~ .row-btn-box{
    background: var(--white-bg);
    color: var(--dark-blue);
}
.light-theme .form-check .RegNewFlow .form-check-label{
    color: var(--black-op50);
}
.light-theme .RegNewFlow .space-select-box .color-white-60{
    color: var(--black-op50);
}
.light-theme .RegNewFlow .color-white-60{
    color: var(--black-op50);
    font-weight: 400;
}
.light-theme .RegNewFlow .bg-white-05,
.light-theme .RegNewFlow .btn-white-05,
.light-theme .RegNewFlow .badge-white{
    background-color: var(--blue-op5);
}
.light-theme .RegNewFlow .border-bottom,
.light-theme .RegNewFlow .space-select-box input,
.light-theme .RegNewFlow .border ,.light-theme .RegNewFlow .space-select-box.border.borderfull{
    border-color: var(--lightbase-header-color)!important;
}
.light-theme .RegNewFlow .space-select-box.border{
    border-color: transparent!important;
}
.light-theme .RegNewFlow .select-input-cox .form-check-input:checked, 
.light-theme .RegNewFlow .select-input-cox .position-absolute.form-check-input:checked, 
.light-theme .RegNewFlow .select-input-cox .h_230px.form-check-input:checked, 
.light-theme .RegNewFlow .floor-step-wrapper .floor-step-box-main .floor-step-box.active, 
.light-theme .RegNewFlow .tools-main-box.active{
    border-color: rgb(0  69 / 50%)!important;
}
.light-theme .RegNewFlow input::-ms-input-placeholder {
    color: var(--blue-op40)!important;
    font-weight: 400;
  }
.light-theme .RegNewFlow input::placeholder {
    color: var(--black-op40)!important;
    font-weight: 400;
}
.light-theme .RegNewFlow .select-input-cox .form-check-input:checked ~ .color-white-60,
.light-theme .RegNewFlow .select-input-cox .form-check-input:checked ~ .color-white-60 svg.c-icons[fill]{
    color: var(--dark-blue);
}
.light-theme .RegNewFlow .select-input-cox .check-image{
    width: 26px;
    height: 26px;
}
.RegNewFlow .radius_5,
.RegNewFlow .select-input-cox .check-image .check-image-box,
.RegNewFlow .select-input-cox .check-image{
    border-radius: 3px;
}
.light-theme .RegNewFlow .select-input-cox.active{
    border-color: var(--blue-op50)!important;
    background-color: var(--white-bg);
}
.light-theme .RegNewFlow .select-input-cox .check-image .check-image-box{
    background: var(--dark-blue);
}
.RegNewFlow .select-input-cox.active .check-image{
    display: inline-flex;
    position: absolute;
    top: 8px;
    right: 8px;
}
.RegNewFlow .RegNewDatePicker {
    width: 100%;
}
.RegNewFlow .RegNewDatePicker > div {
    height: 100%;
    width: 100%;
    display: flex;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker {
    height: 100%;
    width: 100%;
    display: flex;
    border-color: var(--lightbase-header-color);
    font-family: var(--title-family);
    border-radius: 10px;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__month-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding:12px 20px 20px 20px;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__week,
.RegNewFlow .RegNewDatePicker .react-datepicker__day-names{
    display: flex;
    justify-content: space-between;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__month{
    flex-grow: 1;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__current-month{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--lightbase-header-color);
    margin-bottom: 15px;
    text-transform: capitalize;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__header{
    border-color: transparent;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__time-container {
    border-color: var(--black-op20);
    width: 160px;
    padding: 12px 20px;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__header,
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__month{
    background-color: transparent;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__navigation{
    top: 21px;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    right: 170px;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__navigation--previous{
    left: 10px;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__current-month,
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day{
    color: var(--color-black)!important;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day-names .react-datepicker__day-name{
    color: var(--datepicker-text-color);
    font-weight: 600;
    text-transform: capitalize;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day--selected:hover,
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day--selected{
    background-color: var(--color-black);
    color: var(--white-bg)!important;
    border-radius: 500px !important;
    font-weight: var(--fw-semibold);
}
.RegNewFlow .RegNewDatePicker .react-datepicker__day-name, .RegNewFlow .RegNewDatePicker .react-datepicker__day, .RegNewFlow .RegNewDatePicker .react-datepicker__time-name{
    line-height: 40px;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
    background-color: var(--black-op5);
    border: 1px solid var(--lightbase-header-color);
    border-radius: 5px;
    color: var(--color-black);
    padding: 8px 10px;
    width: 100%;
    font-size: 14px;
    height: auto;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width: 100%;
}
.RegNewFlow .RegNewDatePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
    display: grid;
    gap: 10px;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker-time__header{
    color: var(--color-black)!important;
    font-weight: 700 !important;
    font-size: .944rem !important;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background-color: var(--color-black);
    color: var(--white-bg)!important;
    font-weight: 400;
}
.RegNewFlow .react-tel-input .form-control{
    padding-left: 55px !important;
    padding-right: 40px !important;
    text-align: center !important;
    border: 0!important;
    box-shadow: none!important;
    width: 100% !important;
    font-family: var(--title-family) !important;
}
.light-theme .RegNewFlow input, .light-theme .RegNewFlow textarea{
    color: var(--color-black);
    font-weight: 600;
}
.bgbluesolar{
    background-color: var(--dark-blue);
}
.light-theme .RegNewFlow .solar-acknowledge-screen-main .calendar-modal-color{
    width: 33.33%;
}
.light-theme .RegNewFlow .solar-acknowledge-screen-main .comman-list svg.c-icons[fill] {
    color: var(--color-black);
}
.light-theme .RegNewFlow .solar-acknowledge-screen-main .comman-list .steps-green-line:after {
    background: var(--color-black);
}
.light-theme .RegNewFlow .spinner-border-static{
    border: 2px solid var(--blue-op10);
}
.light-theme .RegNewFlow .c-top-calendar .calendar-date-wrapper .cal-date-box, .light-theme .RegNewFlow .c-top-calendar .left-box, .light-theme .RegNewFlow .light-theme-comman-box-shadow, .light-theme .RegNewFlow .select-input-cox .comman-input-check-box, .light-theme .RegNewFlow .onboarding-map-input, .light-theme .RegNewFlow .onboarding-content-scroll-wrapper .task-list-card, .light-theme .RegNewFlow .c-box-shadow-box, .light-theme .RegNewFlow .GeneralItemTable, .light-theme .RegNewFlow .chat-main .left-content-part, .light-theme .RegNewFlow .chat-main-box .chat-comment-box, .light-theme .RegNewFlow .accordion-item .accordion-collapse.show .accordion-body, .light-theme .RegNewFlow footer .footer-links:hover, .light-theme .RegNewFlow .bg-pin, .light-theme .RegNewFlow .onboarding-wrapper-main.onboarding-with-bg, .light-theme .RegNewFlow .select-half-separator .select-half-separator-main, .light-theme .RegNewFlow .custom-modal-style .modal-content{
      box-shadow: 4px 4px 20px 0px var(--blue-op10);
}
.light-theme .RegNewFlow .form-check-input{
    box-shadow: none;
}
.light-theme .RegNewFlow .map-input-box{
    background: var(--white-bg);
    box-shadow: 0px 0px 30px 0px var(--dark_navy_blue-op5);
    display: flex;
    width: 648px;
    position: fixed;
    bottom: 85px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 10px 10px 10px 35px;
    border-radius: 100px;
}
.light-theme .RegNewFlow .c-input-box .form-control.regFormInput.f-20{
    font-size: 20px;
}
.light-theme .glow-shadow .horizontaltab{
    gap: 1px;
    
}
.light-theme .glow-shadow .horizontaltab .row-btn-label .row-btn-box {
    border-radius: 1px;
}
.light-theme .glow-shadow .horizontaltab .row-btn-label .row-btn-box:before{
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    width: 1px;
    height: 15px;
    background-color: var(--blue-op10);
}
.light-theme .glow-shadow .horizontaltab .row-btn-label:last-child .row-btn-box:before{
    display: none;
}
.light-theme .glow-shadow .horizontaltab .row-btn-label input:checked ~ .row-btn-box:before{
    display: none;
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day,
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day-name{
    width: 40px;
    height: 40px;
}
.light-theme .RegNewFlow .light-theme-grey-light-bg{
    background-color: var(--alice_blue_op3);
}
.light-theme .RegNewFlow .color-blue , 
.fcolorBlue {
    color: var(--sky-blue);
}
.RegNewFlow .form-control,
.RegNewFlow .comman_action_icon .action_icon.with_bg{
    border-radius: 3px;
}
.solar-acknowledge-screen-main .comman-list .steps-green-line:after{
    width: 1px;
}
.solar-acknowledge-screen-main .comman-list .steps-green-line .absolute-content{
    height: 45px;
    top: 6px;
}
.light-theme .RegNewFlow .proposal-detail-main input::-ms-input-placeholder { /* Edge 12-18 */
    font-weight: 400;
    font-size: 14px;
  }
.light-theme .RegNewFlow .proposal-detail-main input::placeholder {
    font-weight: 400;
    font-size: 14px;
}
.light-theme .RegNewFlow .with_separator_10:before {
    background-color: var(--sky_blue_op10);
    height: 20px;
}
/* RegNewFlow end */
.object-fit-cover{
    object-fit: cover;
}
.min-h-140{
    min-height: 140px;
}
.max-h-150{
    max-height: 160px;
}
.light-theme .loading-bar {
    display: flex;
    height: 10px;
    width: 100%;
    background-color: var(--white91);
    box-shadow: inset 0px 0px 0px 1px var(--white79);
    border-radius: 50px;
    overflow: hidden;
  }
  @-webkit-keyframes progress-animation {
    0% {
      width: 0%;
    }
    20% {
      width: 10%;
    }
    40% {
      width: 30%;
    }
    50% {
      width: 60%;
    }
    75% {
        width: 80%;
      }
    100% {
      width: 100%;
    }
  }
  @keyframes progress-animation {
    0% {
      width: 0%;
    }
    20% {
      width: 10%;
    }
    40% {
      width: 30%;
    }
    50% {
      width: 60%;
    }
    75% {
        width: 80%;
      }
    100% {
      width: 100%;
    }
  }
   .loading-bar .progress-bar {
    display: flex;
    height: 100%;
    width: 100%;
    background: var(--radium_green);
    -webkit-animation: progress-animation 5s ease-in-out;
            animation: progress-animation 5s ease-in-out;
  }
  .solarloader{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    z-index:20;
    background-color: var(--white-bg);
    height: 100%;
    width: 100%;
  }
  .solarloader .w600{
    max-width: 500px;
  }

  .solarloader.small .loading-bar{
height: 10px;
  }

  .solarloader.small .subloadertitle , .solarloader.small .subloadertitle{
    color: #000000;
  }
  .solarloader .sub-header{
    color: var(--black-op50) !important;
  }
.iconabsolute{
    position: absolute;
    left: 0;
}
.absolutebtn{
    position: absolute;
    right: 0;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .lower-part .left-option-box .border-transparent.bg-white-05{
    border-color: var(--base-font-color)!important;
    background-color: transparent!important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .lower-part .left-option-box .hover-span{
    /* color: var(--base-font-color)!important;
    font-weight: 600!important; */
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .lower-part .left-option-box .border-transparent.bg-white-05:hover,
.light-theme .glow-shadow.solar-login .office-list-main .border-transparent.bg-white-05:hover{
    background-color: var(--white-bg)!important;
    color: var(--radium_green)!important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .lower-part .left-option-box .border-transparent.bg-white-05:hover .hover-span,
.light-theme .glow-shadow.solar-login .office-list-main .office-box .border-transparent.bg-white-05:hover .hover-span{
    color: var(--color-black)!important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .on-hover-active-toggle-img:hover svg.c-icons[fill] {
    color: var(--white-bg) !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .lower-part .left-option-box .border-transparent.bg-white-05.active{
    background-color: var(--base-font-color)!important;
    color: var(--radium_green)!important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .lower-part .left-option-box .border-transparent.bg-white-05.active .hover-span,
.light-theme .glow-shadow.solar-login .office-list-main .office-box.active .border-transparent.bg-white-05 .hover-span{
    color: var(--color-black)!important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .lower-part .left-option-box .border-transparent.bg-white-05.active svg.c-icons[fill],
.light-theme .glow-shadow.solar-login .office-list-main .office-box.active .border-transparent.bg-white-05 svg.c-icons[fill] {
    color: var(--color-black)!important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .left-option-box .comman-round-box.active::after{
    /* background: var(--base-font-color)!important; */
    content: none;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .lower-part .left-option-box .light-pill.badge-custom {
    border: 4px solid var(--color-black);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list{
    box-shadow: 2px 0px 50px 0px var(--blue-op20);
    border-right: 1px solid var(--color-border-light);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.provider-list{
    box-shadow: none;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .shortcut-wrapper.with-white-bg, .light-theme .glow-shadow.solar-login .fixed-left-panel .border-bottom{
    border-color: var(--lightbase-header-color)!important;
}
.light-theme .glow-shadow .fixed-left-panel .upper-part .company-logo-box{
    /* background-color:var(--color-white-05); */
    border-bottom: 1px solid transparent;
}
.header-tabs-bg:hover{
    background: var(--color-black);
    color: var(--white);
}
.header-tabs-bg:hover svg.c-icons[fill]{
    color: var(--white);
}
.header-tabs-bg.active{
    background: var(--color-black);
    color: var(--white);
}
.header-tabs-bg.active svg.c-icons[fill]{
    color: var(--white);
}
.light-theme .glow-shadow{
    background-color: var(--white-bg);
    padding-top: 70px;
    padding-right: 80px;
}
.light-theme .glow-shadow.pt-60{
    padding-top: 60px;
}
.light-theme .glow-shadow .glow-shadow-img{
    display: none;
  }
  .light-theme .glow-shadow .glow-shadow-img2{
    display: none;
  }
.light-theme .glow-shadow .onboarding-wrapper-main.with-modified-width,
.light-theme .glow-shadow .after-login-header,
.light-theme .glow-shadow .custom-select-menu,
.light-theme .glow-shadow .c-input-box .form-control.regFormInput{
    box-shadow: none;
}
.light-theme .glow-shadow .onboarding-with-bg{
    background-color: transparent;
}
.light-theme .glow-shadow .stand-alone-top .custom-select-menu .react-select__dropdown-indicator {
    filter: invert(0.5);
}
.light-theme .glow-shadow .c-input-box .inputWrapper {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--lightbase-header-color);
}
.light-theme .glow-shadow .form-label {
    color: var(--color-black);
}
.light-theme .glow-shadow :-ms-input-placeholder {
    color:  var(--sky_blue_op50)!important;
}
.light-theme .glow-shadow ::placeholder {
    color:  var(--sky_blue_op50)!important;
}
/* .light-theme .glow-shadow :-ms-input-placeholder {
    color: var(--queen_blue);
}
.light-theme .glow-shadow ::placeholder {
    color: var(--queen_blue);
} */
input {
    caret-color:  var(--sky_blue_op50);
   }
.light-theme .glow-shadow .slider_btn{
    box-shadow: var(--color-box-shadow);
}
.light-theme .glow-shadow .registrationHeader .after-login-header {
    background-color: var(--white-bg);
    height: auto;
    padding: 20px 90px 20px 80px;
}
.light-theme .glow-shadow .btn-sm-2 {
    padding: 6px 20px;
    font-size: 12px;
}
/* .light-theme .glow-shadow .custom-select-menu .react-select__control{
    border-color: transparent !important;
} */
.light-theme .map-shadow{
background: linear-gradient(180deg, var(--white-op90) 35%, var(--white-op90) 65.06%, var(--black-op15) 100%);
}
.light-theme a.color-blue{
    color: var(--color-blue);
}
.light-theme .color-grey-50opacity{
    color: var(--sky_blue_op50);
}
.light-theme .glow-shadow.solar-login{
    padding-top: 70px;
    padding-right: 0px !important;
    background: var(--body-bg);
    background-image: url('../images/bgpatter.svg');
    background-position: 50%;
    background-attachment: fixed;
    background-repeat: repeat;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel svg.c-icons[fill]{
    color: var(--white);
}
.bgpatternCommanImg{
    background-image: url('../images/bgpatter.svg');
    background-position: 50%;
    background-attachment: fixed;
    background-repeat: repeat;
}
.light-theme .glow-shadow .btn-white-05.bg-solar{
    background: var(--body-bg);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .action_icon.with_bg svg.c-icons[fill]{
    /* color: var(--color-black); */
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list{
    background-color: var(--newleftpanelcolor);
}
.light-theme .white-shadow{
    box-shadow: 0px -20px 20px 30px var(--white);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.provider-list{
    background-color: var(--newleftpanelcolor);
    box-shadow: 5px 0px 20px 0px var(--black-op01);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel-wrapper .bg-base-header-color{
    background: var(--color-black) !important;
}
.light-theme .glow-shadow.solar-login .custom-accordian-main .custom-accordian-Header-sticky .accordion-button:not(.collapsed){
    background-color: var(--newleftpanelcolor);
}
.light-theme .glow-shadow.solar-login .shortcut-wrapper.with-white-bg{
    background-color: var(--color-black);
}
.light-theme .glow-shadow.solar-login .shortcut-wrapper.with-white-bg .comman-round-box.border-transparent{
    border:1px solid var(--black-op15);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .comman-round-box.rounded-circle.bg-white-05 , .light-theme .glow-shadow.solar-login .fixed-left-panel .comman_action_icon .action_icon.with_bg{
    background-color: var(--color-white-05);
}
.light-theme  .glow-shadow.solar-login .fixed-left-panel input.form-control.bg-transparent.bg-transparent , .light-theme  .glow-shadow.solar-login .fixed-left-panel input::placeholder{
    color: var(--white-bg);
}
.light-theme .form-check-input[type=checkbox]{
    border: 1px solid var(--blue-op10)!important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list svg.c-icons[fill]{
    color: var(--white-bg) !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .solarcretdwon svg.c-icons[fill]{
    color: var(--color-black) !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .on-hover-active-toggle-img:hover svg.c-icons[fill]{
    /* color: var(--color-black) !important; */
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .badge-custom{
    background-color: var(--lemon-yellow);
    color: var(--dark-blue);
    /* border: 3px solid transparent; */
}
.light-theme .glow-shadow #headerUserDropdown{
    background-color: var(--white-bg) !important;
}
.light-theme .glow-shadow.solar-login .office-list-main .office-box.active:before{
background: var(--white-bg);
display: none;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .custom-accordian-Header-sticky{
    background-color: var(--newleftpanelcolor);
}
.light-theme .glow-shadow .fixed-left-panel  ::-webkit-input-placeholder {
    color: var(--white-bg)!important;
}
.light-theme .glow-shadow .fixed-left-panel  :-ms-input-placeholder {
    color: var(--white-bg)!important;
}
.light-theme .glow-shadow .fixed-left-panel  ::placeholder {
    color: var(--white-bg)!important;
}
.light-theme .glow-shadow a,
.light-theme .glow-shadow .color-white-80 {
    color: var(--base-color);
}
/* .light-theme .glow-shadow a.btn{
    color: var(--white-color);
} */
/* .light-theme .glow-shadow a.btn:hover{
    color: var(--base-color);
} */
.light-theme .glow-shadow .btn.bluebtn{
    color: var(--white-bg);
}
.light-theme .glow-shadow button .color-white-80 ,
.light-theme .glow-shadow .color-white-80 {
    color: var(--queen_blue);
}
.light-theme .glow-shadow .fixed-left-panel a,
.light-theme .glow-shadow .fixed-left-panel .color-white-80{
    color:var(--white-bg);
}
.light-theme .glow-shadow .custom-modal-style .modal-header .btn-close ,
.light-theme .glow-shadow .toast-main .toast-header .btn-close,
.light-theme .glow-shadow .offcanvas-header .btn-close {
    filter: invert(0);
}
.light-theme .glow-shadow .custom-modal-style .modal-body{
    background-color: var(--white-bg);
    border-radius: 0 0 6px 6px;
}
.light-theme .glow-shadow .custom-modal-style .modal-header.bg-transparent{
    background-color: var(--base-body-color)!important;
}
.light-theme .glow-shadow .create-task-absolute-mobile-liteversion.custom-modal-style .modal-body{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .custom-modal-style .modal-body .task-list-card ,
.light-theme .glow-shadow .comman-data-table table thead tr ,
.light-theme .glow-shadow .custom-modal-style .task-detail-right-main ,
.light-theme .glow-shadow .main-loader-wrapper.full-screen ,
.light-theme .glow-shadow .comman-search-dropdown ,
.light-theme .glow-shadow .dropdown-menu .dropdown-item .bg-white-05 {
    background-color: var(--dropdown-header-bg);
}
.light-theme .glow-shadow .fixed-left-panel .shortcut-wrapper.with-white-bg,
.light-theme .glow-shadow .fixed-left-panel .border-bottom{
    border-color: var(--color-white-old-05)!important;
}
.light-theme .glow-shadow .calendar-box-bg{
    background-color: var(--white-bg);
    box-shadow: 0 4px 15px 0 var(--black-op5);
}
.light-theme .glow-shadow .search-backdrop:before{
    background-color:  var(--black-op90);
}
.light-theme .glow-shadow .custom-full-calendar .fc .fc-daygrid-day.fc-day-today , .light-theme .glow-shadow .fc .fc-daygrid-day-frame:hover{
    background-color: transparent;
}
.light-theme .glow-shadow .custom-full-calendar .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number:before{ 
    content: "";
    position: absolute;
    top: 8px;
    left: 6px;
    height: 32px;
    width: 32px;
    background-color: var(--lemon-yellow);
    border-radius: 3px;
    z-index: -1;
}
.office-list-icon-search-input::placeholder {
    color: red;
    opacity: 1; /* Firefox */
  }
  .office-list-icon-search-input::-ms-input-placeholder { /* Edge 12 -18 */
    color: red;
  }
.office-list-icon-search-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: pink;
  }
  .office-list-icon-search-input::-moz-placeholder { /* Firefox 19+ */
    color: pink;
  }
  .office-list-icon-search-input:-ms-input-placeholder { /* IE 10+ */
    color: pink;
  }
  .office-list-icon-search-input:-moz-placeholder { /* Firefox 18- */
    color: pink;
  }
.office-list-icon-search-input::placeholder{
    color: red;
}
.light-theme .glow-shadow .fixed-left-panel .btn.btn-secondary,
.light-theme .glow-shadow .fixed-left-panel .btn.btn-secondary:hover,
.light-theme .glow-shadow .fixed-left-panel .btn.btn-secondary:focus {
    background: transparent;
    color: var(--btn-secondary-color);
    border: 1px solid var(--color-white-10);
}
.light-theme .glow-shadow .fixed-left-panel .btn.btn-white-05 svg.comman-icons {
    color: var(--base-font-color);
    stroke: var(--base-font-color);
    fill: none;
}
.light-theme .glow-shadow .fixed-left-panel .btn.btn-white-05,
.light-theme .glow-shadow .fixed-left-panel .btn.btn-white-05:hover,
.light-theme .glow-shadow .fixed-left-panel .btn.btn-white-05:focus{
    background: var(--white255);
    border: 1px solid var(--color-white-10)!important;
}
.light-theme .glow-shadow .border-right{
    border-right: 1px solid var(--blue-border) !important;
}
.light-theme .glow-shadow .chat-main-box .chat-wrapper{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .ai-chat-header .chat-main-box .chat-wrapper{
    background-color: transparent;
}
.light-theme .glow-shadow .ai-chat-header .chat-wrapper .chat-box .chat-msg .chat-text-box{
    background-color: var(--white-bg);
    box-shadow: 0 2px 5px 0 var(--black-op5);
}
.light-theme .glow-shadow .chat-main-box .chat-comment-box{
    background-color: var(--base-header-color);
}
.light-theme .glow-shadow .offcanvas.offcanvas-end .tast-detail-tab-wrapper .modal-sticky-spacing{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow body {
    color: var(--base-color);
}
.light-theme .glow-shadow body .fixed-left-panel{
    color: var(--base-color);
}
.light-theme .glow-shadow .fixed-left-panel .text-on-hover-active-green:hover {
    color: var(--white-bg);
    transition: var(--transiton-3s);
    background-color: #272727;
}
.light-theme .glow-shadow .custom-accordian-main.with-left-header-arrow .hovertextgreen:hover .categorytitle{
    color: var(--white-bg);
  }
.light-theme .glow-shadow .shortcut-wrapper.with-white-bg{
    background-color: var(--base-header-color);
    border-top:var(--color-white-05) 1px solid;
}
.light-theme .glow-shadow .shortcut-wrapper.with-white-bg .kpi-box{
    background-color:transparent;
}
.light-theme .glow-shadow .kpi-main-wrapper .kpi-box.active {
    border: 1px solid var(--color-green-lightheme)--;
}
.light-theme .glow-shadow .list-view-toggle .task-list-card.active {
    border:1px solid transparent;
    border-color: transparent;
}
.light-theme .glow-shadow .office-list-main .office-box .office-list-left .office-subtitle {
    font-weight: var(--fw-medium);
}
.light-theme .glow-shadow body .simplebar-scrollbar:before {
    background: transparent;
}
/* Track */
.light-theme .glow-shadow body ::-webkit-scrollbar-track {
    background: transparent;
}
/* Handle */
.light-theme .glow-shadow body ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}
/* Handle on hover */
.light-theme .glow-shadow body ::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
.light-theme .glow-shadow .badge-custom.with-inprogress-border {
    background: var(--calednar-inprogress-background);
    color: var(--color-black);
    border: 1px solid transparent;
}
.light-theme .glow-shadow .badge-custom.with-done-border {
    background-color: var(--color-green);
    border: 1px solid transparent;
}
.light-theme .glow-shadow .fixed-left-panel.provider-list {
    border-right:var(--color-white-05) 1px solid;
}
/* .light-theme .glow-shadow ::-webkit-input-placeholder {
    color: var(--queen_blue);
} */
.light-theme .glow-shadow ::-webkit-input-placeholder {
    color:  var(--sky_blue_op50)!important;
}
.light-theme .glow-shadow .fixed-left-panel ::-webkit-input-placeholder {
    color: var(--white_op3);
}
.light-theme .glow-shadow .fixed-left-panel :-ms-input-placeholder {
    color: var(--white_op3);
}
.light-theme .glow-shadow .fixed-left-panel ::placeholder {
    color: var(--white_op3);
}
.light-theme .glow-shadow .custom-select-menu .react-select__option:active ,
.light-theme .glow-shadow .toast-main .toast .toast-header ,
.light-theme .glow-shadow .space-wrapper .comman_action_icon .action_icon.with_bg ,
.light-theme .glow-shadow .dropdown-menu .dropdown-item .bg-white-05.comman-round-box
{
    background-color: var(--light-body-bg);
}
.light-theme .glow-shadow .badge {
    border: 1px solid transparent;
    color:var(--queen_blue);
    background-color: var(--alice_blue_op241);
}
.light-theme .glow-shadow .badge.borderblack10{
    border:1px solid var(--black-op10) ;
  }
.light-theme .glow-shadow .badge.text-warning{
    color: var(--text-warning) !important;
}
.light-theme .glow-shadow .comman_action_icon .action_icon.with_bg {
    background-color: var(--white-bg);
    border: 1px solid var(--black-op10) !important;
}
.light-theme .glow-shadow .comman_action_icon .action_icon.with_bg.greenborder{
    border-color: var(--color-green) !important;
}
.light-theme .glow-shadow .comman_action_icon .action_icon.with_bg.blueborder{
    border-color: var(--color-blue) !important;
}
.light-theme .glow-shadow .fixed-left-panel .comman_action_icon .action_icon.with_bg {
    background-color: var(--white_op5);
    border: 1px solid var(--black-op10);
    box-shadow: none;
}
.light-theme .glow-shadow .add-topic-search .topic-main-box .comman_action_icon .action_icon.with_bg {
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .task-list-card .comman_action_icon .action_icon {
    background-color: var(--black-001);
    border-radius: 3px;
}
.light-theme .glow-shadow .custom-accordian-main .accordion-header.active:before {
    z-index: 4;
}
.light-theme .glow-shadow .custom_file{
    background-color: var(--base-header-color);
}
.light-theme .glow-shadow .Comment-header{
    background-color: var(--base-header-color);
}
.light-theme .glow-shadow .comment-input-box.with-sticky {
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .c-top-calendar .calendar-date-wrapper .cal-date-box{
    color: var(--base-color);
    z-index: 1;

}
.light-theme .glow-shadow .c-top-calendar .calendar-date-wrapper .cal-date-box.c-active-border{
    border-color: var(--lemon-yellow);
}
.light-theme .glow-shadow .c-top-calendar .w65 {
    box-shadow: var(--color-shadow-light);
}
.light-theme .glow-shadow .c-top-calendar .c-green-border-50{
    border:1px solid var(--calednar-todo-border)
}
.light-theme .glow-shadow .task-mid-part .color-green {
    color: var(--base-font-color);
}
.light-theme .glow-shadow .comman-round-box.bg-gradient-green,
.light-theme .glow-shadow .bg-gradient-green {
    background: var(--color-green-theme-light)!important;
    color: var(--color-black);
}
.light-theme .glow-shadow .progress-slider .progress .progress-bar,
.light-theme .glow-shadow .c-dots.done,
.light-theme .glow-shadow .custom-accordian-main .accordion-header.active.done:before{
    background: var(--radium_green);
}
.light-theme .glow-shadow .staff_user {
    border: 1px solid var(--staf-user-border-color);
}
.light-theme .glow-shadow .btn.btn-secondary:hover, 
.light-theme .glow-shadow .btn.btn-secondary:active,
.light-theme .glow-shadow .on-hover-bg-10:hover{
    background-color: var(--btn-secondary);
}
.light-theme .glow-shadow .btn.btn-secondary:focus-visible,
.light-theme .glow-shadow .btn.btn-secondary:focus {
    box-shadow: none;
    background-color: var(--btn-secondary);
}
.light-theme .glow-shadow .btn.btn-secondary{
    background-color: var(--color-white-05);
    border-color: transparent;
}
.light-theme .glow-shadow .action_icon.with_bg .btn.btn-secondary{
    background-color: transparent;
}
.light-theme .glow-shadow #commanCanvasImageBody .left-content-part .btn.btn-secondary{
    background-color: var(--white-bg);
    border-color: var(--seperator-line);
}
.light-theme .glow-shadow .dropdown-menu .btn.btn-secondary {
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .toast-btn .btn.btn-secondary {
    background-color: var(--white-bg);
    border-color: var(--seperator-line);
}
.light-theme .glow-shadow .modal-content .btn.btn-secondary {
    background-color: var(--white-bg);
    border-color: var(--seperator-line);
}
.light-theme .glow-shadow .custom-select-menu:focus-visible{
    outline: 0;
}
.light-theme .glow-shadow .task-list-card:hover {
    background: var(--color-black);
}
.light-theme .glow-shadow textarea.form-control.bg-transparentimg,
.light-theme .glow-shadow input.form-control.bg-transparentimg {
    background-color: transparent!important;
}
.light-theme .glow-shadow textarea.form-control.bg-transparent,
.light-theme .glow-shadow input.form-control.bg-transparent {
    background-color: var(--base-body-color)!important;
}
.light-theme .glow-shadow input.form-control.bg-transparent.bg-transparent {
    background: transparent !important;
}
.light-theme .glow-shadow .offcanvas.offcanvas-end .task-detail-left-main .task-detail-header .task-name-input .form-control ,
.light-theme .glow-shadow .commn-search .form-control ,
.light-theme .glow-shadow .form-control.file-upload .file-upload-bg-abs {
    background-color: transparent;
}
/* ...calendar... */
.light-theme .glow-shadow .fc-theme-standard td {
    border: 1px solid var(--blue-border) !important;
}
.light-theme .glow-shadow .fc-theme-standard td:first-child{
    border-left: 0 !important;
}
.light-theme .glow-shadow .fc-theme-standard td:last-child{
    border-right: 0 !important;
}
.light-theme .glow-shadow .comman-round-box.active {
    border: 1px solid var(--color-border-light);
}
.light-theme .glow-shadow .comman-contnet-wrapper {
    border: 1px solid var(--blue-border);
}
.light-theme .glow-shadow .custom-full-calendar .fc .fc-button-group .fc-button-primary {
    background-color: var(--color-white-05) !important;
    color: var(--color-white);
}
.light-theme .glow-shadow .custom-full-calendar .fc .fc-button-primary:not(:disabled):active,
.light-theme .glow-shadow .custom-full-calendar .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: var(--header-bg) !important;
    color: var(--color-white);
}
.light-theme .glow-shadow .custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-button-active{
    background-color: var(--header-bg) !important;
}
.light-theme .glow-shadow .btn-white-10 {
    background: var(--action-icon-bg);
    border: 1px solid var(--color-white-20);
    color: var(--base-body-color-new);
}
.light-theme .glow-shadow .file-uploader-name {
    color: var(--color-black);
}
.light-theme .glow-shadow .fixed-left-panel .light-pill.badge-custom {
    /* border: 4px solid var(--color-black); */
}
.light-theme .glow-shadow .with-circle.half .comman-round-box:after {
    border: 1px solid var(--comman-round-after);
    border-left: 0;
}
.light-theme .glow-shadow .FeedchatContain  svg.c-icons.black-l-white[fill]{
    color: var(--white-bg);
}
.light-theme .glow-shadow .fixed-left-panel .with-circle.half .comman-round-box:after {
    border: 1px solid var(--squid_ink);
    border-left: 0;
}
.light-theme .glow-shadow .fixed-left-panel .upper-part .company-logo-box .list-left-part .title-fonts,
.light-theme .glow-shadow .fixed-left-panel .upper-part{
    color: var(--base-font-color);
}
.bg-leftpenal-header-color-95{
    background-color: var(--leftpenal-header-color-95)
}
.light-theme .glow-shadow .fixed-left-panel .upper-part{
    /* background-color: var(--color-white-05); */
    height: 70px;
}
.light-theme .daily-schedule-main.headersearchbox{
    width: auto;
    max-width: 80%;
}
.light-theme .daily-schedule-main.headersearchbox .commn-search  .comment-input-box .form-control{
    padding: 0;
}
.light-theme .glow-shadow .fixed-left-panel .lower-part {
    border-top: var(--color-border) 1px solid;
}
.light-theme .glow-shadow .custom-accordian-main .accordion-button {
    overflow: visible;
}
.light-theme .glow-shadow .list-view-toggle .task-list-card.list-view {
    box-shadow: 2px 3px 5px 0px var(--lightbase-header-color);
}
.light-theme .glow-shadow .footer-add-btn-bg {
    background-image: url("../images/globe-shape-light.svg");
    /* background: var(--body-bg); */
    /* background: transparent; */
}
.light-theme .glow-shadow .footer-add-btn-bg.bgtransparent{
    background: transparent;
}
.light-theme .glow-shadow .footer-add-btn-bg.pdfplus{
    /* width: 8vw;
    height: 35vh; */
    background-image: none;
}
.light-theme .glow-shadow .footer-add-btn-bg.pdfplus .color-white-60{
    color: var(--white) !important;
}
.light-theme .glow-shadow .chat-wrapper .chat-box .chat-msg .chat-text-box {
    color: var(--color-chet-text-box);
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .chat-wrapper .chat-box .chat-msg .chat-text-box.bg-solar {
    background-color: var(--body-bg);
}
.light-theme .glow-shadow .form-check-input {
    background-color: transparent;
    box-shadow: 0px 2px 15px 0px var(--alice_blue_op5);
}
.light-theme .glow-shadow .form-switch .form-check-input{
    background-color: var(--light-body-bg);
}
.light-theme .glow-shadow a.dropdown-item:focus,
.light-theme .glow-shadow a.dropdown-item:hover {
    color: var(--base-font-color) !important;
}
.light-theme .glow-shadow .dropdown-item:hover .comman_action_icon .action_icon.with_bg{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .badge-custom.with-orange-border {
    border: 1px solid var(--color-warning);
    color: var(--base-font-color);
    background: var(--color-warning);
}
.light-theme .glow-shadow .badge-custom.with-to-do-border {
    background: var(--light-body-bg);
    border: 1px solid var(--inprogress-status);
    color: var(--base-font-color);
}
.office-list-main .office-box:after {
    border-bottom: var(--color-white-07) 1px solid;
}
.light-theme .glow-shadow .task-right-static-options .comman_action_icon .c-font.opacity-50 {
    opacity: 1 !important;
}
.light-theme .glow-shadow .fixed-left-panel .badge-custom {
    /* border: 3px solid var(--base-header-color); */
    background-color: var(--color-green);
    color: var(--color-white);
}
/* ...login... */
.light-theme .glow-shadow .logo-light {
    display: block;
}
.light-theme .glow-shadow .logo-dark {
    display: none;
}
.light-theme .glow-shadow .login_page .custom_input_box_main .custom_input_box {
    border-bottom: var(--bs-border-color) 1px solid;
}
.light-theme .glow-shadow .custom-datepicker .react-datepicker__time-container {
    border-left: 1px solid var(--color-white-10);
}
.light-theme .glow-shadow .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-bottom-color: var(--triangle-border-color);
}
.light-theme .glow-shadow .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    border-top-color: var(--triangle-border-color);
}
.light-theme .glow-shadow .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle::after {
    border-right-color: var(--triangle-border-color);
}
.light-theme .glow-shadow .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle::after {
    border-left-color: var(--triangle-border-color);
}
.light-theme .glow-shadow .offcanvas.taskDetailModal {
    box-shadow: 0px 3px 20px 0px var(--lightbase-header-color);
    border-right-color: var(--base-body-color);
}
.light-theme .glow-shadow .with-right-penel .offcanvas.taskDetailModal.comman-comments {
    box-shadow: none;
}
.light-theme.with-right-penel .offcanvas.taskDetailModal{
    border-right-color: transparent;
}
.light-theme .glow-shadow .with-right-penel .offcanvas#commonCanvasBody.taskDetailModal.modal-full{
    box-shadow: none;
}
.light-theme .glow-shadow .on-hover-active-toggle-img:hover .hover-span.color-white-80 , 
.light-theme .glow-shadow .badge.c-text.done ,
.light-theme .glow-shadow .after-login-header .on-hover-active-toggle-img.active .hover-span 
 {
    color: var(--base-font-color);
}
.light-theme .glow-shadow .on-hover-active-toggle-img:hover svg.c-icons[fill], .light-theme .glow-shadow .on-hover-active-toggle-img.active svg.c-icons[fill]{
    color: var(--lemon-yellow);
}
.light-theme .glow-shadow .on-hover-active-toggle-img:hover .hover-span{
    color: var(--base-font-color);
}
.light-theme .glow-shadow .fixed-left-panel .on-hover-active-toggle-img:hover .hover-span,
.light-theme .glow-shadow .fixed-left-panel .on-hover-active-toggle-img:hover svg.c-icons[fill] {
    color: var(--color-black)!important;
    transition: var(--transiton-3s);
}
.light-theme .glow-shadow .mension-box .mension-box__suggestions {
    background-color: var(--white-bg) !important;
    border: 1px solid var(--bs-border-color);
}
.light-theme .glow-shadow .mension-box .mension-box__suggestions .mension-box__suggestions__list li {
    border-bottom: 1px solid var(--bs-border-color);
    background-color: var(--white-bg)!important;
}
.light-theme .glow-shadow .mension-box .mension-box__suggestions .mension-box__suggestions__list li:hover{
    background-color: var(--base-body-color)!important;
}
.light-theme .glow-shadow .input_box_main.with_file_upload .upload_inputbox {
    background: var(--dropdown-header-bg) !important;
}
.light-theme .glow-shadow .input_label {
    color: var(--base-font-color);
}
.light-theme .glow-shadow .custom-select-menu .react-select__indicators,
.light-theme .glow-shadow .custom-select-menu .react-select__dropdown-indicator {
    filter: invert(0.8);
    opacity: 1;
}
.light-theme .glow-shadow .comman-content-scroll-wrapper.comman_vertical_btn_h90 {
    background-color: var(--color-white3);
}
.light-theme .glow-shadow .border-separator {
    border-bottom: 1px solid var(--btn-secondary) !important;
}
.light-theme .glow-shadow .on-hover-active-toggle-img.active svg.comman-icons {
    stroke: var(--color-white);
}
.light-theme .glow-shadow svg.check_span_img.inactive,
.light-theme .glow-shadow svg.check_span_img.inactive path {
    stroke: var(--base-font-color);
    fill: var(--base-font-color);
}
.light-theme .glow-shadow .custom-select-menu.multi-select-with-scroll .react-select__indicators{
    filter: invert(1);
}
.light-theme .glow-shadow .custom-select-menu .css-tj5bde-Svg {
    fill: var(--base-font-color);
}
.light-theme .glow-shadow .sc-iIPllB.jxflYm.rdt_Pagination svg {
    fill: var(--base-font-color);
}
.light-theme .glow-shadow .comman-data-table .rdt_Pagination {
    border-top: var(--color-white-10) 1px solid;
    color: var(--color-black);
}
.light-theme .glow-shadow .TopicModalRightPart .commn-search{
    border-bottom: 1px solid transparent !important;
}
.light-theme .glow-shadow .TopicModalRightPart{
    border-color: var(--seperator-line)!important;
    background-color: transparent;
}
.light-theme .glow-shadow .react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
    border-color: var(--base-font-color);
}
.light-theme .glow-shadow .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: var(--triangle-border-color)
}
.light-theme .glow-shadow .custom-datepicker .react-datepicker__header {
    border-bottom: var(--color-white-10) 1px solid;
}
.light-theme .glow-shadow footer .footer-links .on-hover-active-toggle-img:hover svg.comman-icons,
.light-theme .glow-shadow footer .footer-links .on-hover-active-toggle-img.active svg.comman-icons ,
.light-theme .glow-shadow .after-login-header .on-hover-active-toggle-img.active svg.comman-icons
 {
    /* stroke: var(--color-green-lightheme); */
}
.light-theme .glow-shadow .form-switch .form-check-input:after {
    background-color: var(--color-black);
}
.light-theme .glow-shadow .form-switch .form-check-input:checked:after {
    background-color: var(--base-header-bg);
}
.light-theme .glow-shadow .comman-backdrop-search:before {
    opacity: 0.5;
    bottom: 0px;
}
.light-theme .glow-shadow .comman-backdrop-search.backdroplighttheme:before{
    opacity: 0.;
}
.light-theme .glow-shadow footer.comman-backdrop-search:before {
    height: 80px;
}
.light-theme .glow-shadow .__react_component_tooltip {
    box-shadow: var(--tooltip-after-box-shadow);
}
.light-theme .glow-shadow .fixed-left-panel .place-top::after, .light-theme .glow-shadow .fixed-left-panel .place-left::after, .light-theme .glow-shadow .fixed-left-panel .place-bottom::after, .light-theme .glow-shadow .fixed-left-panel .place-right::after, .light-theme .glow-shadow .fixed-left-panel .__react_component_tooltip, .light-theme .glow-shadow .fixed-left-panel .__react_component_tooltip {
    background-color: var(--icone-hover-color-black-2)!important;
    box-shadow: none!important;
}
.light-theme .glow-shadow .checkbox_accordian .checkbox_absolute .form-check-input {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px transparent;
}
.light-theme .glow-shadow .place-top::after,
.light-theme .glow-shadow .place-left::after,
.light-theme .glow-shadow .place-bottom::after,
.light-theme .glow-shadow .place-right::after {
    background-color: var(--white-bg) !important;
    box-shadow: 0 0 3px 0px var(--light-body-bg);
}
.light-theme .glow-shadow .dropdown-menu .dropdown-item,
.light-theme .glow-shadow .dropdown-menu .dropdown-item .c-list-detail,
.light-theme .glow-shadow .dropdown-menu .row-btn-label .row-btn-box,
.light-theme .glow-shadow .dropdown-menu svg.c-icons[fill] {
    color: var(--base-font-color);
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu{
    color: var(--color-black)!important;
}
.light-theme .glow-shadow .dropdown-menu .HeaderDropdownTitle .c-font.f-10 {
    font-weight: var(--fw-light);
}
.light-theme .glow-shadow .dropdown-menu input:checked~.row-btn-box {
    background-color: var(--light-body-bg);
    color: var(--base-font-color);
}
.light-theme .glow-shadow .dropdown-menu .dropdown-divider {
    border-top: 1px solid var(--dropdown-border-color) !important;
}
.light-theme .glow-shadow .dropdown-menu .hr_1 {
    border-bottom: 1px solid var(--dropdown-border-color);
}
.light-theme .glow-shadow .dropdown-menu .hr_1:last-child {
    border-bottom: 0;
}
.light-theme .glow-shadow .dropdown-menu {
    border: 1px solid var(--dropdown-border-color) !important;
    color: var(--base-font-color) !important;
    box-shadow: var(--box-shadow-dropdown);
}
.light-theme .glow-shadow .dropdown-menu .dropdown-header,
.light-theme .glow-shadow .dropdown-menu .dropdown-head {
    border-radius: 5px 5px 0px 0px;
   }
  .light-theme .glow-shadow .hr_sepretr:before{
    background-color: var(--color-white-10);
  }
  .light-theme .glow-shadow .dropdown-menu .input_box_main.with_file_upload .upload_inputbox{
    background: var(--color-black) !important;
}
.light-theme .glow-shadow .bottom-link-menu-options .color-white-60 {
    color: var(--base-font-color) !important;
}
.light-theme .glow-shadow .form-check-input:checked[type="checkbox"],
.light-theme .glow-shadow .dropdown-item.active.with-tick:after {
    background-image: url(../images/ic-check-light.svg);
}
.light-theme .glow-shadow .custom-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.light-theme .glow-shadow .custom-select-menu .react-select__option {
    color: var(--base-color);
}
.light-theme .glow-shadow .fixed-left-panel svg.comman-icons:hover {
    /* stroke: var(--svg-light-theme-left-panel-color); */
    stroke: var(--white-color);
}
.light-theme .glow-shadow .fixed-left-panel .on-hover-active-toggle-img.active svg.comman-icons{
    stroke: var(--white-color);
}
.light-theme .glow-shadow .list-right-part .hr_sepretr:before , .light-theme .glow-shadow .fixed-left-panel .right-accordian-options .dropdown-menu .hr_sepretr:before {
    background-color: var(--color-white-10);
}
.light-theme .glow-shadow .comman-data-table .jMMJgk,
.light-theme .glow-shadow .comman-data-table .sc-ezWOiH .light-theme .glow-shadow .comman-data-table .sc-eACynP,
.light-theme .glow-shadow .comman-data-table .sc-eACynP {
    filter: invert(0) !important;
}
.light-theme .glow-shadow .bg-img-bottom-right {
    filter: invert(0.1)grayscale(0.1)brightness(0.3);
}
.light-theme .glow-shadow .rs-picker-menu,
.light-theme .glow-shadow .custom-datepicker .react-datepicker{
    box-shadow: var(--box-shadow-dropdown);
}
.light-theme .glow-shadow .react-datepicker__month{
    background-color: var(--white-color);
    margin: 0;
    padding: 0 !important;
}
.light-theme .glow-shadow .rs-picker-daterange-calendar-group{
    background-color: var(--header-bg);
}
.light-theme .glow-shadow .rs-picker-toolbar{
    background-color: var(--base-header-color);
}
.light-theme .glow-shadow .wallet-task-title-sticky{
    background-color: transparent;
}
.light-theme .glow-shadow .task-list-accordian.custom-accordian-main .accordian-header-box.with-accordian-bg{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .task-list-accordian.custom-accordian-main .accordian-header-box{
    background-color: var(--light-body-bg);
}
.light-theme .glow-shadow .premium-sub-main-box .space-wrapper .custom-accordian-Header-sticky{
     background-color: var(--alice_blue80);
}
.light-theme .glow-shadow .stand-alone-top .logo-img img{
    filter: invert(0);
}
/* Tab style  */
.light-theme .glow-shadow .comman-tab-wrapper .tab-wrapper.active .tab-name{
    color: var(--base-font-color);
}
.light-theme .glow-shadow .comman-tab-wrapper .tab-wrapper.active .tab-name:before{
    background-color: var(--base-font-color);
}
.light-theme .glow-shadow .comman-tab-wrapper .tab-wrapper a.tab-name:hover{
    color: var(--base-font-color);
}
.light-theme .glow-shadow body.modal-open .list-view-toggle .task-list-card.active,
.light-theme .glow-shadow .list-view-toggle .task-list-card.active{
    background-color: var(--white-bg);
    box-shadow: 0 4px 15px 0 var(--black-op5);
    border-color: var(--sky_blue_op40)!important;
}
.light-theme .glow-shadow body.modal-open .task-list-accordian.custom-accordian-main .accordian-header-box{
    box-shadow: none !important ;
    background-color: var(--base-body-color);
    z-index: 6;
}
.light-theme .glow-shadow body.modal-open .modal-overlay{
    z-index: 7;
}
.light-theme .glow-shadow .new_schedule_footer.bg-base-header-color , .light-theme .glow-shadow .custom-footer.bg-base-header-color{
    background: var(--base-body-color) !important;
}
.light-theme .glow-shadow .childTopicTaskFilter .badge-custom.badge-green {
  border: 1px solid var(--base-header-color);
}
.light-theme .glow-shadow .badge-custom.bg-danger , .bgred{
    background: var(--bg-danger) !important;
}
.light-theme .glow-shadow .select-input-cox .form-check-input:checked,
.light-theme .glow-shadow .select-input-cox .position-absolute.form-check-input:checked,
.light-theme .glow-shadow .select-input-cox .h_230px.form-check-input:checked,
.light-theme .glow-shadow .floor-step-wrapper .floor-step-box-main .floor-step-box.active,
.light-theme .glow-shadow .tools-main-box.active{
    border-color: var(--sky_blue_op40)!important;
}
.light-theme .glow-shadow .select-input-cox .form-check-input:checked ~ .check-image svg.c-icons.color-green[fill]{
    color:var(--base-header-bg);
}
.light-theme .glow-shadow .onboarding-map-input{
    background-color: var(--dropdown-header-bg) !important;
}
.light-theme .glow-shadow .css-eg0mwd-MuiSlider-thumb, .MuiSlider-thumb{
    border:2px solid var(--base-body-color) !important;
}
.maxw400px{
    max-width: 400px;
}
/*Footer*/
.light-theme .glow-shadow .badge-green{
    border: 1px solid transparent;
    color: var(--color-black);
}
.light-theme .glow-shadow .office-list-main  .badge{
    border: 1px solid transparent;
    color: var(--base-font-color);
    background-color: var(--color-white-05);
}
.light-theme .glow-shadow .footer-mid-btn-animation .footer-mid-circle{
    background-color: var(--light-theme-footer-mid-btn-animation-bg);
}
.light-theme .glow-shadow .task-sub-list:hover a{
    color:var(--base-font-color);
}
.light-theme .glow-shadow .task-sub-list:hover .list-icon svg.c-icons[fill]{
    color:var(--base-font-color);
}
.light-theme .glow-shadow .comman-image-box{
    background-color: var(--black-op5);
}
.light-theme .glow-shadow .fixed-left-panel .multi_inner_wrapper .comman-image-box{
    background-color:var(--base-color-1);
    outline: 3px solid var(--base-color-2);
}
.light-theme .glow-shadow .fixed-left-panel .lower-part .bg-white-05 , .light-theme .glow-shadow .shortcut-wrapper.with-white-bg .kpi-box{
    background-color: var(--color-white-05) !important;
}
.light-theme .glow-shadow body.modal-open .task-list-accordian.custom-accordian-main .accordion-header{
    background: transparent !important;
}
.light-theme .glow-shadow body.modal-open .pill-wrappe-modal-overlay{
    box-shadow: none;
    background: transparent;
}
.light-theme .glow-shadow .custom-modal-style .task-detail-left-main{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .bottom-link-menu-options .action_icon svg.comman-icons{
    stroke: var(--base-modal-bg);
}
.light-theme .glow-shadow #commonCanvasBody{
    box-shadow: var(--black-boxshadow-2);
}
.light-theme .glow-shadow .right-penel-no-padding .offcanvas.taskDetailModal.modal-full{
    border-right:var(--color-black) 3px solid;
}
.light-theme .glow-shadow .right-penel-no-padding .offcanvas.taskDetailModal.modal-full-left-panel{
    border-right:var(--color-black) 3px solid;
}
.light-theme .glow-shadow .custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-unread-button:after{
    filter:invert(1);
}
.light-theme .glow-shadow .fc-h-event .fc-event-main , .light-theme .glow-shadow .custom-full-calendar .fc-h-event .fc-event-title{
    color: var(--base-header-bg) !important;
}
.light-theme .glow-shadow .custom-full-calendar .fc-h-event .fc-event-title.text-white{
    color: #ffffff !important;
}
.light-theme .glow-shadow .task-setting-right .action_icon.btn.btn-primary {
    border: 1px solid var(--color-white-10);
    background-color: transparent;
}
.light-theme .glow-shadow .custom-select-menu .react-select__control{
    background-color: var(--white-bg);
    border-color:var(--blue-border) !important;
}
.light-theme .glow-shadow .custom-select-menu.w70px .react-select__control{
    width: 70px;
}
.light-theme .glow-shadow .select-half-separator .select-half-separator-main .custom-select-menu .react-select__control,
.light-theme .glow-shadow .select-half-separator .select-half-separator-main .form-control{
    border-color: transparent!important;
}
.light-theme .glow-shadow .select-half-separator .custom-select-menu,
.light-theme .glow-shadow .select-half-separator .form-control{
    box-shadow: none;
}
.light-theme .glow-shadow .chat-main-box .chat-comment-box .border-bottom{
    border-color: var(--color-white-05) !important;
}
.light-theme .glow-shadow .chat-main .chat-page-header{
background-color: var(--base-header-color);
}
.light-theme .glow-shadow .custom-modal-style .task-detail-left-main .bg-white-05.leadProfileTitle{
    background-color: var(--base-header-color);
}
.light-theme .glow-shadow .task-request-Progress.bg-white-03{
    background-color: var(--header-bg);
}
.light-theme .glow-shadow .task-request-Progress .bg-warning{
   background-color: var(--calendar-bg-warning)  !important;
}
.light-theme .glow-shadow .task-request-Progress .bg-todo-calendar{
    background-color:var(--calendar-bg-todo) !important;
}
.light-theme .glow-shadow .task-request-Progress .bg-blue{
    background-color:var(--calendar-bg-blue) !important;
}
.light-theme .glow-shadow .task-request-Progress .bg-gradient-green{
    background-color: var(--calendar-bg-green) !important;
}
.light-theme .glow-shadow .progress-slider .progress{
    background: var(--header-bg);
 }
 .light-theme .glow-shadow .fixed-left-panel .progress-slider .progress{
    background-color: var(--color-white-05);
 }
 .light-theme .glow-shadow .chat-main .left-content-part .base-body-color.form-control ,  .light-theme .glow-shadow .chat-main .left-content-part .three-row-btn-bg {
    background-color: var(--header-bg);
 }
 .light-theme .glow-shadow .form-wrapper-main  .left-content-part.bg-transparent{
    background-color: var(--header-bg) !important;
 }
 .light-theme .glow-shadow .form-wrapper-main  .left-content-part .comman-list-dots {
    border: 4px solid var(--btn-secondary);
 }
 .light-theme .glow-shadow .form-wrapper-main  .left-content-part .comman-list-line:after{
    background-color: var(--light-theme-border-color);
 }
 .light-theme .glow-shadow .chat-main .left-content-part .comman-verticle-tab .comman-list .tab_link.active ,  .light-theme .glow-shadow .chat-main .left-content-part .comman-verticle-tab .comman-list .tab_link:hover{
    background-color: var(--color-top-calendar);
 }
 .light-theme .glow-shadow .custom-select-menu.bg-white-05,
 .light-theme .glow-shadow .custom-accordian-Header-sticky.bg-premium-transparent,
 .light-theme .glow-shadow .schedule-section,
 .light-theme .glow-shadow .comman-data-table table.dataTable tfoot,
 .light-theme .glow-shadow .timesheet-head .time-sheet-box,
 .light-theme .glow-shadow .timesheet-foot .time-sheet-box,
 .light-theme .glow-shadow .time-sheet-box,
 .light-theme .glow-shadow .react-datepicker__day--keyboard-selected{
    background-color: var(--white-bg);
 }
 .light-theme .glow-shadow .form-control.file-upload.file-upload-main{
    background-color: var(--action-icon-bg);
    box-shadow: none;
 }
 .light-theme .glow-shadow .custom-modal-style .modal-body .custom-modal-section-sticky{
    background-color: var(--base-body-color);
 }
.light-theme .glow-shadow .premium-sub-main-box .bg-white-05{
    background-color: var(--color-white-05);
    border: 1px solid var(--color-white-10);
}
.light-theme .glow-shadow .premium-sub-main-box .space-wrapper .space-box-wrapper{
    border: 1px solid var(--color-white-10);
}
.light-theme .glow-shadow .custom-select-menu .react-select__multi-value{
    background-color: var(--header-bg);
}
.light-theme .glow-shadow .custom-select-menu.multi-select-with-scroll .react-select__dropdown-indicator:last-child{
    opacity: 1;
}
.light-theme .glow-shadow .tab-contain-wrapper .comment-input-box .base-body-color{
    background-color: var(--header-bg);
}
.light-theme .glow-shadow .comman-verticle-tab .comman-list .tab_link.active{
     background-color: var(--base-body-color);
}
.light-theme .glow-shadow .comman-data-table .sc-cxabCf, .comman-data-table .sc-cyRfQX, .comman-data-table .sc-ilEZps{
    background-color: var(--header-bg);
}
.light-theme .glow-shadow .comman_action_icon .action_icon{
    border-color: transparent;
}
.light-theme .glow-shadow .comman-round-box .remove-img{
    background-color: var(--base-body-color);
    border-color: var(--paynes-gray);
}
.light-theme .glow-shadow .bottom-link-menu-options .bg-left-panel{
    background-color: var(--white-bg);
    box-shadow: var(--color-shadow-light);
}
.light-theme .glow-shadow .bottom-link-menu-options .bg-left-panel svg.comman-icons {
    stroke: var(--base-font-color)
}
.light-theme .glow-shadow .premium-sub-main-box .premium-sub-right-part,
.light-theme .glow-shadow .premium-sub-main-box .premium-sub-right-part .comman-white-box{
    background-color: var(--color-white-05);
}
.light-theme .glow-shadow .wrapper-main .TopicModalLeftPart{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .TopicModalLeftPart .color-white-80.position-sticky{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .btn.btn-white-03:hover,
.light-theme .glow-shadow .btn.btn-white-03:focus,
.light-theme .glow-shadow .btn.btn-white-03:active{
    background-color: var(--color-white-05);
}
.light-theme .glow-shadow .TopicModalRightPart{
    background-color: var(--body-bg);
    background-image: url('../images/bgpatter.svg');
    background-position: 50%;
    background-attachment: fixed;
    background-repeat: repeat;
}

.light-theme .glow-shadow .custom-modal-style .TopicModalRightPart .modal-body{
    background-color: var(--color-white-05);
}
.light-theme .glow-shadow .TopicModalRightPart .calendar-box-bg{
    background-color: var(--body-bg);
}
.light-theme .glow-shadow .custom-modal-style .modal-content{
    background-color: var(--header-bg);
}
.light-theme .glow-shadow .comman-placeholder-wrapper.bg-white-03{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .fixed-left-panel .hr_sepretr:before {
    background-color: var(--white_op70);
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-item.active, .light-theme .glow-shadow .fixed-left-panel .dropdown-item:active:hover,
.light-theme .glow-shadow .custom-accordian-Header-sticky{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu .dropdown-item:hover{
    background-color: var(--action-icon-bg);
}
.light-theme .glow-shadow .fixed-left-panel .custom-accordian-Header-sticky{
    background-color: var(--squid_ink69);
}
.light-theme .glow-shadow .fixed-left-panel .multi_inner_wrapper.with-min-width{
    color: var(--white-bg);
}
.light-theme .glow-shadow .dropdown-item:active:hover,
.light-theme .glow-shadow .dropdown-menu .dropdown-item:hover,
.light-theme .glow-shadow .btn-white-03.view-more-btn:hover,
.light-theme .glow-shadow .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content,
.light-theme .glow-shadow .rs-calendar-table-cell-content:hover,
.light-theme .glow-shadow .rs-btn-subtle:focus, .light-theme .glow-shadow .rs-btn-subtle:hover,
.light-theme .glow-shadow .custom-select-menu .react-select__option:hover, 
.light-theme .glow-shadow .custom-datepicker .react-datepicker__day:hover, .light-theme .glow-shadow .react-datepicker__month-text:hover, .light-theme .glow-shadow .react-datepicker__quarter-text:hover, .light-theme .glow-shadow .react-datepicker__year-text:hover,
.light-theme .glow-shadow .custom-datepicker .react-datepicker__day--selected, .light-theme .glow-shadow .react-datepicker__day--in-selecting-range,
.light-theme .glow-shadow .comman-data-table table.dataTable tbody tr,
.light-theme .glow-shadow .light-theme-white-bg,
.light-theme .glow-shadow .form-control[readonly],
.light-theme .glow-shadow .aicommentbox
{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow input.form-control.bg-transparent.bg-transparent.light-theme-white-bg
{
    background-color: var(--white-bg)!important;
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu .btn-secondary{
    background-color: transparent;
}
.light-theme .glow-shadow .fixed-left-panel .comman-image-box{
    background-color: var(--white_op5);
}
.light-theme .glow-shadow .dropdown-menu .custom-btn-dropdown-items.btn-secondary{
    background-color: transparent;
    border: 0;
}
.light-theme .glow-shadow .dropdown-menu .dropdown.show .dropdown-item{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .dropdown-menu .btn-secondary:hover,
.light-theme .glow-shadow .dropdown-menu .btn-secondary:focus{
    background-color: transparent;
    border: 0;
}
.light-theme .glow-shadow .border-bottom{
    border-bottom: 1px solid var(--lightbase-header-color)!important;
}
.light-theme .glow-shadow .after-login-header .h-user-part:after,
.light-theme .glow-shadow .border-bottom-color-border,
.light-theme .glow-shadow .task-list-accordian.custom-accordian-main .taskCountToggle.border-top-color-border
.light-theme .glow-shadow .border-top,
.light-theme .glow-shadow .border-right,
.light-theme .glow-shadow .border-left,
.light-theme .glow-shadow .border,
.light-theme .glow-shadow .task-checklist,
.light-theme .glow-shadow .dropdown-menu .dropdown-divider,
.light-theme .glow-shadow .form-check-input,
.light-theme .glow-shadow .comment-input-box .send-arrow-btn,
.light-theme .glow-shadow .rs-picker-daterange-header,
.light-theme .glow-shadow .rs-picker-daterange-menu .rs-calendar:first-child,
.light-theme .glow-shadow .rs-picker-toolbar,
.light-theme .glow-shadow .dropdown-menu,
.light-theme .glow-shadow .dropdown-menu .form-check-input,
.light-theme .glow-shadow .comman-search-dropdown,
.light-theme .glow-shadow .shortcut-wrapper.with-white-bg,
.light-theme .glow-shadow .dropdown-menu,
.light-theme .glow-shadow .badge-custom.with-to-do-border,
.light-theme .glow-shadow .border-left-10,
.light-theme .glow-shadow .border-bottom-10,
.light-theme .glow-shadow .border-dashed-2px,
.light-theme .glow-shadow .form-control,
.light-theme .glow-shadow .time-sheet-box,
.light-theme .glow-shadow .hr_1 ,
.light-theme .glow-shadow .mension-box .mension-box__suggestions .mension-box__suggestions__list li,
.light-theme .glow-shadow .commn-search,
.light-theme .glow-shadow .light-theme-border,
.light-theme .glow-shadow .border-top,
.light-theme .glow-shadow .border-left,
.light-theme .glow-shadow .border-end,
.light-theme .glow-shadow .border-start,
.light-theme .glow-shadow .select-half-separator .select-half-separator-main,
.light-theme .glow-shadow .white-border-box,
.light-theme .glow-shadow hr.w-7,
.light-theme .glow-shadow .light-theme-border.form-check-input[type=radio],
.light-theme .glow-shadow .light-theme-border.form-check-input[type=checkbox]
{
    border-color: var(--black-op10)!important;
}
.light-theme .glow-shadow hr.w-7{
    border-top: 1px solid var(--base-body-color);
    opacity: 1;
}
.light-theme .glow-shadow .with_separator_10:before,
.light-theme .glow-shadow .with_separator2_10:before,
.light-theme .glow-shadow .hr_sepretr:before,
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu .with_separator_10:before,
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu .hr_sepretr:before
{
    background-color: var(--lightbase-header-color);
}
.light-theme .glow-shadow .comman-data-table thead:after{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .fixed-left-panel .with_separator_10:before,
.light-theme .glow-shadow .fixed-left-panel .hr_sepretr:before {
    background-color:hsla(0,0%,100%,.08);
}
.light-theme .glow-shadow .rs-calendar-table-cell-in-range:before{
    background-color: var(--calendar-table-cell-bg);
}
.light-theme .glow-shadow .comman_action_icon.show .text-on-hover-active-green,
.light-theme .glow-shadow .comman_action_icon.show svg.c-icons[fill],
.light-theme .glow-shadow .dropdown-menu .dropdown-item.active svg.c-icons,
.light-theme .glow-shadow .select-input-cox .form-check-input:checked ~ .active svg.c-icons[fill]{
    color: var(--base-font-color)!important;
}
.light-theme .glow-shadow .wrapper-main .ModalLeftPart-BgWithShadow.TopicModalLeftPart{
    box-shadow: 0 0px 20px 0 var(--light-theme-border-color);
}
.light-theme .glow-shadow .select-input-cox .check-image svg,
.light-theme .glow-shadow .select-input-cox .check-image path{
    fill: var(--base-font-color);
}
.light-theme .glow-shadow .select-input-cox .check-image g path{
    stroke: var(--white-bg);
}
.light-theme .glow-shadow .btn-white-05,
.light-theme .glow-shadow .btn.btn-white-05:hover,
.light-theme .glow-shadow .btn.btn-white-05:focus,
.light-theme .glow-shadow .btn.btn-white-05:active{
    background: var(--black-001);
    color: var(--color-black);
}
.light-theme .glow-shadow .btn-white-05{
    border: 1px solid var(--black-op10) !important;
}
.light-theme .glow-shadow .custom-accordian-Header-sticky.base-modal-bg{
    background: var(--alice_blue80)!important;
}
.light-theme .glow-shadow .onboarding-footer .onboarding-footer-tab .footer-tab{
    background-color: var(--onboarding-footer-tab);
}
.light-theme .glow-shadow input:-webkit-autofill,
.light-theme .glow-shadow input:-webkit-autofill:hover, 
.light-theme .glow-shadow input:-webkit-autofill:focus, 
.light-theme .glow-shadow input:-webkit-autofill:active
{
    /* box-shadow: inset 0 0 20px 20px var(--base-body-color); */
    box-shadow: inset 0 0 20px 20px #ffffff;
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .form-check-input[type=radio]
{
    background-color: var(--base-body-color);
    border: 1px solid transparent!important;
}
.light-theme .glow-shadow .select-input-cox .form-check-input[type=radio],
.light-theme .glow-shadow .select-input-cox .form-check-input[type=checkbox]{
    background-color: transparent;
}
.light-theme .glow-shadow .form-check-input:checked[type="radio"] {
    background-image: url(../images/dark-circle-fill.svg);
    background-size: 10px;
    opacity: 1;
  }
  .light-theme .glow-shadow .select-input-cox .form-check-input:checked[type="radio"] {
    background-size: 0px;
    opacity: 1;
  }
.light-theme .glow-shadow .custom-accordian-main.with-left-header-arrow .accordion-button::after {
    filter: invert(1);
}
.light-theme .glow-shadow .fixed-left-panel .custom-accordian-main.with-left-header-arrow .accordion-button::after {
    filter: invert(0);
}
.light-theme .glow-shadow .on-hover-bg-white-05:hover{
    background-color: var(--alice_blue0);
}
.light-theme .glow-shadow .onboarding-floor-bg{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .comman-verticle-tab .comman-list .tab_link.active:after{
    background: var(--color-green);   
}
.light-theme .glow-shadow .popover{
    background-color: var(--white-bg);
    color: var(--base-body-color-new);
}
.light-theme .glow-shadow .popover.w400px{
    width: 400px;
    max-width: 400px;
}
.light-theme .glow-shadow .bs-popover-end > .popover-arrow::after {
    border-right-color: var(--triangle-border-color)
}
.light-theme .glow-shadow .bs-popover-start > .popover-arrow::after {
    border-left-color: var(--triangle-border-color)
}
.light-theme .glow-shadow .bs-popover-top > .popover-arrow::after{
    border-top-color: var(--triangle-border-color)
}
.light-theme .glow-shadow .bs-popover-bottom > .popover-arrow::after {
    border-bottom-color: var(--triangle-border-color)
}
.light-theme .glow-shadow .bs-popover-end > .popover-arrow::before{
    border-right-color: var(--light-body-bg);
}
.light-theme .glow-shadow .bs-popover-start > .popover-arrow::before{
    border-left-color: var(--light-body-bg);
}
.light-theme .glow-shadow .bs-popover-top > .popover-arrow::before{
    border-top-color: var(--light-body-bg);
}
.light-theme .glow-shadow .bs-popover-bottom > .popover-arrow::before{
    border-bottom-color: var(--light-body-bg);
}
.light-theme .glow-shadow .operator_user {
    border: 1px solid var(--guppie_green);
}
.light-theme .glow-shadow .time-sheet-box{
    border-bottom: 1px solid;
}
.light-theme .glow-shadow .c-input-box .input-label:before {
    filter: brightness(0.1);
}
.light-theme .glow-shadow .css-1gv0vcd-MuiSlider-track, .light-theme .glow-shadow .MuiSlider-track,
.light-theme .glow-shadow .css-eg0mwd-MuiSlider-thumb, .light-theme .glow-shadow .MuiSlider-thumb{
    background: var(--base-font-color)!important;
}
.light-theme .glow-shadow .css-eg0mwd-MuiSlider-thumb.Mui-active{
    box-shadow: 0px 0px 0px 14px var(--alice_blue243)!important;
}
.light-theme .glow-shadow .aicommentbox:after{
    border-top-color: var(--color-black);
}
.light-theme .glow-shadow .form-control:focus{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .comman-round-box-border-color {
    border:1px solid var(--black-op10);
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu .dropdown-item,
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu .dropdown-item .c-list-detail{
    color: var(--squid_ink69)!important;
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu svg.c-icons[fill]{
    color: var(--queen_blue);
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu svg.comman-icons {
    stroke: var(--queen_blue);
}
.light-theme .glow-shadow .fixed-left-panel .dropdown-menu svg.comman-icons.with-fill{
    fill: var(--queen_blue);
}
.light-theme .glow-shadow svg.comman-icons.with-fill{
    fill: var(--queen_blue);
 }
.light-theme .glow-shadow .comman_action_icon.iconactive svg.c-icons[fill]{
    color: var(--squid_ink69);
}
.light-theme .glow-shadow .task-sub-list:hover svg.c-icons[fill],
.light-theme .glow-shadow .task-sub-list.active svg.active-svg{
    color: var(--squid_ink69);
}
.light-theme .glow-shadow .fixed-left-panel .task-sub-list:hover svg.c-icons[fill],
.light-theme .glow-shadow .fixed-left-panel .task-sub-list.active svg.active-svg,
.light-theme .glow-shadow .fixed-left-panel .task-sub-list.active .left_panel_toggle 
{
    color: var(--lemon-yellow);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .office-list-main .office-box.spaceicon:hover svg.c-icons[fill]{
    color: var(--lemon-yellow) !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .office-list-main .office-box.spaceicon:hover .hover-span{
    color: var(--lemon-yellow);
}
.light-theme .glow-shadow .fixed-left-panel .task-sub-list:hover svg.comman-icons.active-svg{
    stroke: var(--lemon-yellow);
}
.light-theme .glow-shadow .c-top-calendar .calendar-date-wrapper .cal-calendar{
    box-shadow: 0px 4px 15px var(--black-op08);
    color: var(--calendar-date-color);
}
.light-theme .glow-shadow .white-border-box , 
.light-theme .glow-shadow .custom-modal-style .modal-header{
    background-color: var(--black-001);
}
.light-theme .glow-shadow .comment-input-box .send-arrow-btn{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow svg.c-icons.black-l-white[fill] {
    color: var(--color-black);
}
.light-theme .glow-shadow .footer-mid-btn-animation .footer-mid-circle{
    background-color: var(--footer-mid-circle-bg);
}
.light-theme .glow-shadow .after-login-header .h-user-part:after {
    border-right: var(--color-white-05) 1px solid;
}
.light-theme .glow-shadow .footer-mid-btn-animation .hello_box.active svg path {
    fill: var(--base-font-color);
}
.light-theme .glow-shadow .aicommentbox{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .chat-wrapper .chat-box .chat-msg .chat-text-box{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow input, 
.light-theme .glow-shadow textarea{
    color: var(--base-color);
}
.light-theme .glow-shadow .comman_action_icon .action_icon.with_bg.commn-search-bg,
.light-theme .glow-shadow textarea .comman_action_icon .action_icon.with_bg,
.light-theme .glow-shadow input .comman_action_icon .action_icon.with_bg{
    border-color: transparent!important;
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .badge{
    background-color: var(--action-icon-bg);
}
.light-theme .glow-shadow .chat-date-seperator .seperator-line {
    border-top: 1px solid var(--black-op5);
}
.light-theme .glow-shadow .cal-date-box .badge-custom.with-orange-border{
    background-color: var(--color-warning);
    border-color: transparent;
    color: var(--color-black);
}
.light-theme .glow-shadow .cal-date-box .badge-custom.with-to-do-border{
    background-color: var(--calednar-todo-border);
    border-color: transparent;
}
.light-theme .glow-shadow .blackbadge.badge-custom{
    color: var(--white-bg) !important;
    background-color: var(--color-black) !important;
  }
.light-theme .glow-shadow .cal-date-box .badge-custom.with-inprogress-border{
    background-color: var(--color-blue);
    border-color: transparent;
    color: var(--base-header-color);
}
.light-theme .glow-shadow .cal-date-box .badge-custom.with-done-border{
    background-color: var(--color-gradient);
      border-color: transparent;
}
.light-theme .glow-shadow .c-top-calendar .date-details .badge-custom.with-done-border {
    background-color: var(--color-green);
}
.light-theme .glow-shadow .fixed-left-panel .Push-Pin-fill {
    color: rgba(0, 255, 106, 1)!important;
}
.light-theme .glow-shadow .Push-Pin-fill {
    color: var(--lemon-yellow)!important;
}
.base-body-border-color{
    border-color: var(--base-body-color);
}
.light-theme .glow-shadow .comman-image-box.bg-white-03.rounded-circle,
.light-theme .glow-shadow .comman-image-box.bg-white-05.rounded-circle,
.light-theme .glow-shadow #headerUserDropdown,
.light-theme .glow-shadow .comman-image-box,
.light-theme .glow-shadow .comman-round-box.bg-white-05.rounded-circle,
.light-theme .glow-shadow .comman-round-box.rounded-circle.bg-white-03
{
    background-color: var(--black-op5);
}
.light-theme .glow-shadow .fixed-left-panel .comman-round-box.rounded-circle.bg-white-05{
    background-color: var(--white_op5);
}
.light-theme .glow-shadow svg.check_span_img.inactive, .light-theme .glow-shadow .check_span_img.inactive path{
    fill: var(--paynes-gray);
    stroke: var(--paynes-gray);
}
.light-theme .glow-shadow .chat-wrapper .chat-box .chat-msg .chat-text-box{
    color: var(--color-black);
}
.light-theme .glow-shadow .light-theme-color-black{
    color: var(--color-black);
}
.light-theme .glow-shadow .aichatTaskListing{
    color: var(--base-color);
}
.light-theme .glow-shadow .commn-search.comman-search-on-focus {
    background-color: var(--base-body-color);
    border-bottom: 1px solid transparent;
}
.light-theme .glow-shadow .footer-mid-circle-logo-bg{
    background: var(--white-bg);
}
.light-theme .glow-shadow .footer-nav-linksnew:hover .footer-mid-circle-logo-bg{
    background: transparent;
}
.light-theme .glow-shadow .badge-custom{
    color: var(--color-white);
    /* border-color: var(--white-bg); */
}
.light-theme .glow-shadow .place-bottom:after, .light-theme .glow-shadow .place-left:after, .light-theme .glow-shadow .place-right:after, .light-theme .glow-shadow .place-top:after {
    background-color: var(--white-bg)!important;
    box-shadow: var(--tooltip-after-box-shadow)
}
.light-theme .glow-shadow .c-top-calendar .left-box,
.light-theme .glow-shadow .light-theme-comman-box-shadow,
.light-theme .glow-shadow .select-input-cox .comman-input-check-box,
.light-theme .glow-shadow .onboarding-map-input,
.light-theme .glow-shadow .onboarding-content-scroll-wrapper .task-list-card,
.light-theme .glow-shadow .c-box-shadow-box,
.light-theme .glow-shadow .GeneralItemTable,
.light-theme .glow-shadow .chat-main .left-content-part,
/* .light-theme .glow-shadow .chat-main-box .chat-comment-box, */
/* .light-theme .glow-shadow .comman-white-box-shadow, */
.light-theme .glow-shadow .accordion-item .accordion-collapse.show .accordion-body,
.light-theme .glow-shadow footer .footer-links:hover,
.light-theme .glow-shadow .bg-pin,
.light-theme .glow-shadow .onboarding-wrapper-main.onboarding-with-bg,
.light-theme .glow-shadow .select-half-separator .select-half-separator-main,
.light-theme .glow-shadow .form-check-input,
.light-theme .glow-shadow .custom-modal-style .modal-content
{
    box-shadow: var(--comman-box-boxshadow);
}
.light-theme .glow-shadow .task-list-wrapper .task-creator-img .form-check-input{
    box-shadow: none;
}
.light-theme .glow-shadow .liteversion-input-checlist-abs-position  , .light-theme .glow-shadow .task-list-car {
    box-shadow: var(--comman-box-boxshadow);
}
.light-theme .glow-shadow .custom-accordian-main .accordion-item.with-pb-15{
    box-shadow: none;
}
.light-theme .glow-shadow .c-top-calendar .w65.Cleftbox{
    box-shadow: var(--top-calendar-shadow);
}
.light-theme .glow-shadow .custom-select-menu,
.light-theme .glow-shadow .form-control{
    box-shadow: var(--comman-input-with-selectmenu-shadow);
}
.light-theme .glow-shadow .badge-green.with-bg , .light-theme .glow-shadow .badge-custom{
    background-color: var(--lemon-yellow);
}
.light-theme .glow-shadow .badge-custom{
    color: var(--color-black) !important;
}
.light-theme .glow-shadow .space-select-box.select-input-cox{
    box-shadow: none;
}
.light-theme .glow-shadow .after-login-header .modal-overlay2 {
    display: none;
    /* box-shadow: var(--top-after-login-header-shadow); */
}
.light-theme .glow-shadow .task-list-accordian.custom-accordian-main .accordian-header-box{
    background-color: var(--body-bg);
}
.light-theme .glow-shadow .task-list-accordian .accordion-button .left-task-header {
    color: var(--squid_ink70);
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .task-list-card:hover,
.light-theme .glow-shadow .view-more-btn.btn-white-03:hover,
.light-theme .glow-shadow .c-top-calendar .calendar-date-wrapper .cal-date-box:hover,
.light-theme .glow-shadow .c-top-calendar .left-box:hover{
    background-color: var(--white-bg)!important;
}
.light-theme .glow-shadow .fixed-left-panel .view-more-btn.btn-white-03:hover{
    background-color: transparent!important;
}
.light-theme .glow-shadow .three-row-btn-bg {
    background-color: var(--base-body-color);
    border-color: transparent;
}
.light-theme .glow-shadow input:checked ~ .row-btn-box {
    background: var(--white-color);
    color: var(--color-black);
}
.light-theme .glow-shadow input:checked ~ .row-btn-box:before{
    display: none;
}
.light-theme .glow-shadow .dropdown-menu .dropdown-item:hover,
.light-theme .glow-shadow .dropdown-item.active{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .form-check-input[type=checkbox], .light-theme .glow-shadow .form-check-input[type=radio] {
    background-color: var(--alice_blue250);
}
.light-theme .glow-shadow .form-switch .form-check-input:after {
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .dropdown-menu .dropdown-item:hover .form-check-input[type=checkbox], .light-theme .glow-shadow .form-check-input[type=radio] {
    background-color: var(--alice_blue250);
}
.light-theme .glow-shadow .dropdown-menu .dropdown-item:hover .form-check-input[type=radio]{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .dropdown-menu .dropdown-item:hover .form-switch .form-check-input:after {
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .dropdown-menu .dropdown-item:hover .form-switch .form-check-input:checked:after {
    background-color: var(--color-green);
}
.light-theme .glow-shadow .form-switch .form-check-input:checked:after {
    background: var(--lemon-yellow);
}
.light-theme .glow-shadow .dropdown-divider {
    border-top: 1px solid var(--color-white-05);
}
.light-theme .glow-shadow .dropdown-menu .badge,
.light-theme .glow-shadow .dropdown-menu .comman-image-box {
    background-color: var(--base-body-color)!important;
}
.light-theme .glow-shadow .fixed-left-panel .badge {
    background-color: var(--white_op5);
}
.light-theme .glow-shadow .fixed-left-panel .badge.badge-opacity-15 {
    background-color: var(--black-op15) !important;
}
.light-theme .glow-shadow .main-loader-wrapper.full-screen {
    background-color: var(--white_op80);
}
.light-theme .glow-shadow .file-parent-box{
    background-color: var(--white-bg);
  }
.light-theme .glow-shadow .comman-vertical-tabs a.task-sub-list.active,
.light-theme .glow-shadow .comman-vertical-tabs a.task-sub-list:hover{
    background-color: #272727;
}
.light-theme .glow-shadow .TopicModalLeftPart .comman-vertical-tabs a.task-sub-list.active,
.light-theme .glow-shadow .TopicModalLeftPart .comman-vertical-tabs a.task-sub-list:hover{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .task-sub-list:hover {
    background-color: #272727;
}
.light-theme .glow-shadow .c-top-calendar{
    z-index: 6;
}
.light-theme .glow-shadow .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .custom-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.light-theme .glow-shadow .custom-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .upload-box{
    background-color: var(--black-002);
}
.light-theme .glow-shadow .comman-round-box:hover svg.comman-icons{
    stroke: var(--action-icon-color);
 }
 .light-theme .glow-shadow .task-sub-list.active{
    color: var(--base-body-color-new);
 }
 .light-theme .glow-shadow .fixed-left-panel .task-sub-list.active{
    color: var(--color-green);
 }
 .light-theme .glow-shadow .custom-modal-section-sticky{
    background-color: var(--base-body-color);
 }
 .light-theme .glow-shadow #commanCanvasImageBody .c-list-icon.with-overlay:before{
    background-image: linear-gradient(var(--alice-blue-opa245), var(--color-opacity-black));
 }
 .light-theme .glow-shadow #commanCanvasImageBody .file-uploader-name{
    background-image: linear-gradient(var(--color-opacity-black), var(--alice-blue-opa245))
 }
 .light-theme .glow-shadow .modal-sticky-spacing{
    background-color:var(--white-bg);
 }
 .light-theme .glow-shadow input.autoComplete-off-box-shadow:-webkit-autofill, .light-theme .glow-shadow input.autoComplete-off-box-shadow:-webkit-autofill:hover, .light-theme .glow-shadow input.autoComplete-off-box-shadow:-webkit-autofill:focus, .light-theme .glow-shadow input.autoComplete-off-box-shadow:-webkit-autofill:active{
    -webkit-text-fill-color: var(--squid_ink58);
    color: var(--squid_ink58);
    caret-color: var(--squid_ink58);
 }
 .light-theme .glow-shadow .login_page .custom_input_box_main .custom_input_box .input_box{
    color: var(--squid_ink58);
 }
 .light-theme .glow-shadow .login_page .forger_link {
    color: var(--squid_ink58);
}
.light-theme .glow-shadow .fixed-left-panel .color-white-60 {
    color: var(--white-bg);
}
.light-theme .glow-shadow .custom-full-calendar .fc .fc-toolbar.fc-header-toolbar {
    background-color: var(--white-bg);
    border: 1px solid var(--blue-border);
    border-bottom: 0;
}
.light-theme .glow-shadow .custom-full-calendar .fc-theme-standard .fc-scrollgrid thead {
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .bg-pin {
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .modal-open .after-login-header .badge-custom{
    border: 0;
}
.light-theme .glow-shadow .bg-white-03-solid {
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .select-input-cox.min-h-230px .form-check-input[type=radio]{
    border: 1px solid transparent!important;
}
.light-theme .glow-shadow .color-green{
    color: var(--color-black);
}
.light-theme .glow-shadow .fixed-left-panel .color-green{
    color: var(--guppie_green);
}
.light-theme .glow-shadow .Litecardwrapper .modal-overlay-box-shadow{
    box-shadow: none!important;
}
.light-theme .glow-shadow .light-theme-base-body-color{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .bg-silder-btn{
    background-color:var(--white-bg);
}
.light-theme .glow-shadow .fixed-left-panel .comman-round-box:hover svg.comman-icons{
    stroke: var(--guppie_green);
 }
.light-theme .glow-shadow .light-full-bg-modal .hr_sepretr:before{
    background-color: var(--seperator-line)!important;
}
.light-theme .glow-shadow .light-full-bg-modal .border-bottom{
    border-color: var(--seperator-line)!important;
}
.light-theme .glow-shadow .light-full-bg-modal .comman-image-box.bg-white-03.rounded-circle,
.light-theme .glow-shadow .light-full-bg-modal .comman-image-box.bg-white-05.rounded-circle, 
.light-theme .glow-shadow .light-full-bg-modal #headerUserDropdown, 
.light-theme .glow-shadow .light-full-bg-modal .comman-image-box, 
.light-theme .glow-shadow .light-full-bg-modal .comman-round-box.bg-white-05.rounded-circle, 
.light-theme .glow-shadow .light-full-bg-modal .comman-round-box.rounded-circle.bg-white-03{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .c-list-icon.with-overlay:before{
    background-image: linear-gradient(var(--alice-blue-opa53), var(--color-opacity-black))
}
.light-theme .glow-shadow .file-uploader-name {
    background-image: linear-gradient(var(--color-opacity-black), var(--alice-blue-opa245));
}
.light-theme .glow-shadow .light-theme-white-bg{
    background-color: var(--white-bg)!important;
}
.light-theme .glow-shadow .introjs-bullets ul li a{
    background: var(--onboarding-footer-tab);
}
.light-theme .glow-shadow .introjs-bullets ul li a.active{
    background: var(--btn-primary-bg);
}
.light-theme .glow-shadow .btn-primary.btn:active{
    color: var(--color-black);
}
.light-theme .glow-shadow .with-abs-position-bg-taskcard:before,
.light-theme .glow-shadow .task-list-card:hover .with-abs-position-bg-taskcard:before{
    background: linear-gradient(270deg, var(--white-color) 0%, var(--alice-blue-opa98) 89.06%, var(--alice-blue-opa00) 100%);
}
.light-theme .glow-shadow .with-abs-position-bg-taskcard{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .c-gradinet-active-border{
    background: linear-gradient(var(--white-bg), var(--white-bg)) padding-box , linear-gradient(45deg, var(--color-green), var(--color-green-blue)) border-box;
}
.light-theme .glow-shadow .task-list-card:hover .with-abs-position-bg-taskcard{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .comman_action_icon .action_icon.with_bg:hover{
    /* border: 1px solid var(--base-body-color); */
    background-color: var(--white-bg);
}
/* .light-theme .glow-shadow svg.c-icons[fill]{
    color: var(--mainly_blue);
} */
.light-theme .glow-shadow svg.c-icons[fill] {
    color: var(--color-black);
}
.light-theme .glow-shadow .c-top-calendar svg.c-icons[fill],
.light-theme .glow-shadow .send-arrow-btn svg.c-icons[fill]{
    color: var(--base-color);
}
.light-theme .glow-shadow .c-top-calendar svg.comman-icons{
    stroke: var(--base-color);
}
.light-theme .glow-shadow svg.comman-icons{
    stroke: var(--queen_blue);
}
.light-theme .glow-shadow svg.comman-icons.strokeblack{
    stroke: var(--color-black);
}
.light-theme .glow-shadow .fixed-left-panel svg.comman-icons{
    stroke: var(--white);
}
.light-theme .glow-shadow .fixed-left-panel svg.c-icons[fill] {
    color:  var(--white);
}
.light-theme .glow-shadow .task-list-card .color-white-60{
    color: var(--black-op50);
}
.light-theme .glow-shadow .view-more-btn{
    color: var(--color-black);
    background-color: var(--white);
    opacity: 0.7;
}
.light-theme .glow-shadow .view-more-btn:hover{
    opacity: 1;
}
.light-theme .glow-shadow .view-more-btn svg.c-icons[fill]{
    color: var(--color-black);
}
.light-theme .glow-shadow .liteversion-input-checlist-main .form-control.comman-search-dropdown{
    box-shadow: none!important;
    border-color: transparent!important;
}
.light-theme .glow-shadow .liteversion-input-checlist-main .form-control{
    border: 1px solid var(--base-body-color)!important;
    box-shadow: none!important;
}
.light-theme .glow-shadow .text-on-hover-active-green:hover {
    color: inherit;
}
.light-theme .glow-shadow .liteversion-input-checlist-main {
    box-shadow: var(--liteversion-input-checlist-shadow)!important;
}
.light-theme .glow-shadow .list-view-toggle .task-list-card.active .with-abs-position-bg-taskcard:before{
    background: linear-gradient(270deg, var(--white-color) 0%, var(--alice-blue-opa89), var(--alice-blue-opa60) 100%);
}
.light-theme .glow-shadow .list-view-toggle .task-list-card.active .with-abs-position-bg-taskcard{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .toast-main .toast-container .toast{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .task-list-card{
    background-color:var(--base-body-color);
    box-shadow: var(--color-card-shadow);
}
.light-theme .glow-shadow .task-list-card.list-view{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .Type-space-verticle-tab.comman-verticle-tab .comman-list .tab_link.active{
    color: var(--base-color);
}
.light-theme .glow-shadow .Type-space-verticle-tab.comman-verticle-tab .comman-list .tab_link .badge{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .star-rating-items svg.c-icons[fill]  {
    color: var(--paynes-gray);
}
.light-theme .glow-shadow .star-rating-items.active svg.c-icons[fill]{
    color: var(--radium_green);
}
.light-theme .glow-shadow footer .on-hover-active-toggle-img.active .absolute_star svg.c-icons[fill],
.light-theme .glow-shadow footer .on-hover-active-toggle-img .absolute_star svg.c-icons[fill]{
    color: var(--queen_blue);
}
.light-theme .glow-shadow footer .on-hover-active-toggle-img.active .hover-span{
    color: var(--base-font-color);
}
.light-theme .glow-shadow .badge-white{
    background-color: var(--action-icon-bg);
}
.light-theme .glow-shadow .footer-links .footernav.active a{
    border-radius: 30px;
    background: var(--btn-primary-bg);
}
.light-theme .glow-shadow .comman-backdrop-search:before{
    background-color: var(--body-bg);
}
.light-theme .glow-shadow .fixed-left-panel .comman-backdrop-search:before{
    background-color: var(--base-modal-backdrop-color);
}
.light-theme .glow-shadow .select-input-cox .light-theme-border-transparent.form-check-input:checked {
    border-color: transparent!important;
}
.light-theme .glow-shadow .onboarding-header .sub-header{
    color: var(--queen_blue);
}
.light-theme .glow-shadow .form-label{
    color: var(--queen_blue);
}
.light-theme .glow-shadow .onboarding-wrapper-main .color-white-60,
.light-theme .glow-shadow .onboarding-header .color-white-60{
    color: var(--queen_blue);
}
.light-theme .glow-shadow .select-input-cox .check-image .check-image-box {
    background: var(--base-color);
}
.light-theme .glow-shadow .select-input-cox .check-image .check-image-box svg.c-icons[fill] {
    color: var(--white-bg);
}
.light-theme .glow-shadow .onboarding-wrapper-main .form-check-input[type=checkbox]
{
    background-color: var(--white-bg);
    border-color: var(--base-body-color)!important;
}
.light-theme .glow-shadow .onboarding-wrapper-main .form-switch .form-check-input[type=checkbox]{
    background-color: var(--alice_blue250);
}
.light-theme .glow-shadow .light-theme-grey-light-bg{
    background-color: var(--base-body-color);
}
.light-theme .glow-shadow .light-theme-grey-light-bg-imp{
    background-color: var(--base-body-color)!important;
}
.light-theme .glow-shadow .CustomFullCalendar .light-theme-grey-light-bg-imp{
    background-color: var(--white-color) !important;
}
.light-theme .glow-shadow .CustomFullCalendar.Monthview .MonthHeaderbox{
    color: var(--color-black);
}
.light-theme .glow-shadow .kpi-main-box-shadow{
    box-shadow: var(--kpi-main-box-shadow);
}
.light-theme .glow-shadow .btn.btn-secondary.light-themebgtransparent{
    background-color: transparent;
}
.light-theme .glow-shadow .CardImage .mapoverlaybottom:before{
    background-image: linear-gradient(transparent,var(--black-op50));
}
.light-theme .glow-shadow .CustomFullCalendar.Monthview .Monthbox.disabled{
    background-color: var(--color-black-03);
}
.light-theme .glow-shadow .CustomFullCalendar.Monthview .Monthbox,
.light-theme .glow-shadow .CustomFullCalendar.Monthview .MonthHeader{
    border-color: var(--black-op10)!important;
}
.light-theme .glow-shadow .multi_inner_wrapper .comman-image-box:after{
    border: 1px solid var(--black-op10);
}
.light-theme .glow-shadow .light-theme-overall-scroll-gery-shadow{
    box-shadow: var(--overall-scroll-gery-shadow);
}
.light-theme .glow-shadow .light-theme-overall-scroll-white-shadow{
    box-shadow: var(--overall-scroll-white-shadow);
}
.light-theme .glow-shadow .custom-modal-style .modal-footer{
    background-color: var(--white-bg);
}
.light-theme .glow-shadow .task-setting-section.withbox-shadow:before,
.light-theme .glow-shadow .task-checklist .right-checklist-action .with-abs-position{
    background: linear-gradient(270deg,var(--alice-blue),var(--alice-blue) 93.06%,var(--alice-blue-opa60));
}
.h220px{
    height: 220px;
}
.light-theme .glow-shadow .TopicModalRightPart  .comman-verticle-tab .comman-list .tab_link.active:after{
    background-color: var(--lemon-yellow);
}
.light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day.react-datepicker__day--today{
    background-color: var(--color-black) !important;
    color: var(--white-bg) !important;
    border-radius: 500px !important;
}
.react-datepicker__day:hover{
    border-radius: 500px !important;
}
.solarbodybg{
    background-color: var(--white95);
}
.blackpill{
    background-color: var(--color-black) !important;
    color: var(--white-bg) !important;
}
.btnyellow{
    border-radius: 3px;
    background: var(--lemon-yellow);
    color: var(--color-black);
}
.color-yellowtext{
    color: var(--lemon-yellow) !important;
}
.color-redtext{
    color: var(--bg-danger) !important;
}
.color-greentext{
    color: var(--sea-green) !important;
}
.bgyellow{
    background: var(--lemon-yellow); 
}
.btn.btn-primary:hover{
    background-color: var(--white-bg);
    color: var(--color-black);
    border: 1px solid var(--color-black) !important;
}
.btn.btn-primary.onhoverimginv:hover svg{
    filter: invert(1);
}
.btnyellow:hover{
    background-color:var(--white-bg);
    color: var(--color-black);
    border: 1px solid var(--lemon-yellow);
}
.Bigswitch .with-no-label-space .form-check-input{
    width: 70px;
    height: 34px;
}
.Bigswitch .with-no-label-space.form-switch .form-check-input:after{
    height: 28px;
    width: 28px;
}
.Bigswitch .with-no-label-space.form-switch .form-check-input:checked:after{
    left: 3px;
    background:var(--white-bg);
}
.Bigswitch .with-no-label-space.form-switch .form-check-input{
    background-color: var(--color-black);
}
.Bigswitch  .switch-label{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--white-bg);
}
.Bigswitch .switch-label.left{
    left: 10px;
}
.Bigswitch  .switch-label.right{
    right: 10px;
}
.light-theme .border {
    border-color: var(--lightbase-header-color) !important;
  }
  .bg-solar,
  .light-theme .glow-shadow.bg-solar,
  .glow-shadow.bg-solar{
    background: var(--body-bg);
    background-image: url('../images/bgpatter.svg');
    background-position: 50%;
    background-attachment: fixed;
    background-repeat: repeat;
  }

  .custom-select-menu.w200px .react-select__control , .w200px{
    width: 200px;
  }
.light-theme .toast-main .toast-container .toast{
    background: var(--lemon-yellow) !important;
}
.light-theme .glow-shadow .toast-main .toast .toast-header , .light-theme .toast-main .toast .toast-body{
    background-color: var(--lemon-yellow);
}
.toast-main .toast-container .toast.unsucess{
    border: 1px solid transparent;
}
.light-theme .glow-shadow .toast .toast-header .color-white-80{
    color: var(--color-black);
} 
.light-theme .glow-shadow .toast .toast-body,
.light-theme .color-blacl-70{
    color: var(--black-op70)!important;
}
.light-theme .toast-header{
    border-bottom-color: var(--black-op5);
}.light-theme .glow-shadow .toast .toast-header svg.c-icons[fill]{
    color: var(--color-black);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel input::placeholder{
    color: var(--alice-blue-opa06) !important;
}
.react-tel-input .selected-flag .arrow{
    border-top:5px solid var(--black-op50) !important;
}
  .mapareabox.right_panel {
    width: 100%;
    bottom: 0;
    right: 0;
    position: relative;
    top: auto;
    order: 2;
    margin-top: 10px;
    border: 1px solid var(--base-body-color);
}
.registration-footer-content.padding-bottom-80px{
    padding-bottom: 80px;
}
.registration-footer-content{
    padding: 0 20px;
}
  /* Responsive */
  @media screen and (min-width: 1680px) {
    .registration-content{
        padding: 0 20px;
    }
    .registration-footer-content{
        padding: 0 20px;
        min-height: 122px;
    }
  }
  @media screen and (max-width: 767px) {
    .mapdirection {
        bottom: auto;
    right: 20px;
    top: 77px;
    }
    .Maploader{
        width: 80%;
    }
    .light-theme .glow-shadow .horizontaltab{
        overflow: auto;
    }
    .react-tel-input .flag-dropdown, .react-tel-input .form-control {
        font-size: 18px!important;
    }
    .h50w50 {
        height: var(--space-40);
        width: var(--space-40);
    }
    .w_400px {
        width: 250px;
    }
    .registration-header .center-part {
        /* width: 100%; */
    }
    .registration-header{
        position: relative;
        height: 60px;
        margin-top: -15px;
    }
    .comman_action_icon .action_icon.h55w55{
        height: 35px;
        width: 35px;
    }
    a.btn.btn-primary.position-relative.light-theme-blue-bg.radius_5 {
        padding: 5px;
    }
    .with_separator_10 {
        padding: 0px 0px
    }
    .registration-content div div.py-30px{
        padding-top: 15px;
        padding-bottom: 40px;
    }
    .color-white-60 .resmx0{
        margin: 0;
    }
    .h_150px{
        height: 50px !important;
    }
    .light-theme .glow-shadow .btn{
        font-size: 11px;
        padding: 5px;
    }
    .c-font.f-11{
        font-size: 10px;
    }
    input.h32wauto.fw-semibold.color-white.form-control{
        width: 100%;
    }
    .add_rate{
        width: 100%;
    }
    .progress.w_400px{
        width: 170px;
    }
    .light-theme .RegNewFlow .with_separator_10:before {
        display: none;
    }
    .row-btn-box.c-font.f-12.p-1.p-md-2.fw-semibold.title-fonts.cblue{
        font-size: 10px;
    }
    .with_separator_10.ps-20pximp{
        padding-left: 10px !important;
    }
    .with_separator_10.pe-20pximp{
        padding-right: 10px !important;
    }
    .align-items-center.btn-white-05.d-flex.d-inline-block.mb-20.overflow-auto.p-1.radius_5.space-.text-uppercase {
        white-space: nowrap;
    }
    .row-btn-label{
        white-space: nowrap;
        min-width: 170px;
    }
    .p10pxresponsive{
        padding: 10px !important;
    }
    .pyment_btn{
        min-width: 120px;
    }
    .light-theme .glow-shadow .registration-header{
        padding: 0px;
    }
    .light-theme .glow-shadow .registration-header .c-font.f-20{
        font-size: 14px;
    }
    .light-theme .glow-shadow .comman_action_icon .action_icon.h40w40{
        height: 30px;
        width: 30px;
    }
    .light-theme .glow-shadow .registration-header .c-font.f-20 span{
        padding-left: 0;
    }
    .registration-header .center-part{
        position: relative;
        transform: none;
        left: inherit;
        top: inherit;
    }
    .glow-shadow .comman-body-wrapper.modified-height{
        height: 100%;
    }
    .c-input-box .form-control.regFormInput, .PhoneInputInput{
        font-size: 15px;    
    }
    .info_card,
    .info_card_left_position{
        position: relative;
        right: inherit;
        bottom: inherit;
        max-width: 100%;
        width: 100%;
        margin-bottom: 10px;
        order: 1;
    }
    .suxess-page{
        width: 100%;
      }
    .info_intro{
        width: 100%;
        max-width: 100%;
    }
    .info_intro.h220px{
        height: 100%;
    }
    .registration-footer-content.solarfooter{
        display: none;
    }
    .registration-footer-content.mb-20{
        margin-bottom: 0;
    }
    .info_intro .c-font.f-10.pb-20px{
        padding-bottom: 0;
    }
    .RegNewFlow .registration-content.registration-step-header{
        margin-bottom: 40px;
    }
    .mapareabox.w270px  {
        width: 75%;
    }
    /* .mapareabox.right_panel{
        width: 100%;
        bottom: 0;
        right: 0;
        position: relative;
        top: auto;
        order: 2;
        margin-top: 10px;
        border: 1px solid var(--base-body-color);
    } */

    .minw378px {
        min-width: auto;
    }
    .maxw378{
        max-width: auto;
      }
    .text-truncate {
        white-space: normal;
    }
    .mapareabox.right_panel .c-font.f-38{
        font-size: 30px;;
    }
    .light-theme .RegNewFlow .map-input-box{
        width: 85%;
        /* bottom: 355px; */
        /* top: 355px; */
        left: 20px;
        transform: translateX(4%);
        padding: 10px;
        /* margin-left: 2px; */
    }
    .light-theme .RegNewFlow .map-input-box .regFormInput.w100minus50{
        width: calc(100% - 40px);
    }
    .light-theme body{
        padding: 15px;
        padding-top: 0;
    }
    .light-theme body.px-0{
        padding: 15px !important;
    }
    .occupants_select .space-select-box .h_150px svg{
        height: auto;
        width: auto;
    }
    .space-select-box.select-input-cox.w_200px{
        width: 100%;
    }
    .light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day.react-datepicker__day--today{
        line-height: 35px;
    }
    .onboarding-map.MapMob{
        height: calc(100vh - 130px) !important;
    }
    .registration-footer-content div.ps-20pximp{
        padding-left: 0 !important;
    }
    /* <!-- glow shadow start  --> */
    .RegNewFlow .registration-content.registration-step-header {
        margin-bottom: 0px;
    }
    /* <!-- occupants  --> */
    .occupants_select{
        margin-bottom: 40px;
    }
    /* <!-- calendar  --> */
    .RegNewFlow .RegNewDatePicker .react-datepicker__month-container {
        padding: 10px 0px 10px 0px;
    }
    .light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day, .light-theme .RegNewFlow .RegNewDatePicker .react-datepicker__day-name {
        width: 33px;
        height: 34px;
    }
    /* <!--   --> */
    .c-input-box .form-control.regFormInput.px4pxpy55px {
        padding: 4px 40px 4px 40px;
        font-size: 18px;
    }
    .back_space_btn
    {
        margin-left: 0px;
    }
    .with_separator_10.me-10px{
        margin-right: 0px;
    }
    .with-left-penel .registration-header .center-part {
        width: 44.33%;  
        left: 38%;
    }
    .registration-header .center-part > div > div > span:first-child{
        display: none;
    }
    .registration-header .center-part > div > div > span:last-child{
        display: block;
    }
    .registration-footer-content div.ps-20pximp.resps10{
        padding-left: 10px !important;
    }
    .device-space.mt-30px{
        margin-top: 0px;
    }
    .solarloader .w600{
        max-width: 85%;
    }
    .propostion_btn
    {
        width: 100%;
    }
    .custom-select-menu.with-filter-select.w-180px
    {
        width: 100%;
    }
    .custom-select-menu.with-filter-select {
        margin-right: 0px;
    }
    .comment-input-box {
       width: 100%;
    }
    .cantrol_panel.border-left.ps-30 
    {
        padding-left: 0px;
    }
    .cantrol_panel.border-left
    {
        border-left: 0px solid var(--color-white-05) !important;
    }
    .light-theme .glow-shadow .btn-white-05 {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    a.btn.btn-primary.position-relative.light-theme-blue-bg.radius_5 {
        width: 100%;
    }
    .registration-footer-content a.radius_5 .h100px{
        height: 60px;
    }
    .registration-footer-content .c-font.f-14{
        font-size: 12px;
    }
    .panel_img{
        width: 41%;
      }
    .h100px.img_height{
        height: 60px;
    }  
    .space-select-box.select-input-cox.w150px {
        width: 50%;
    }
    .resmargin15px.mt-30px.mb-30px {
        margin:10px 0;
    }
    .messaging-footer-main{
        position: fixed;
        width: calc(100% - 20px);
    }
    .w_350 {
        width: 100%;
    }
  }

  /* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .mapareabox.right_panel {
        position: absolute;
        top: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 90px;
        border-radius: 10px;
        z-index: 1;
        border: 1px solid transparent;
        background: transparent;
        grid-gap: 10px;
        gap: 10px;
        overflow-y: auto;
        justify-content: center;
    }
 }

@media screen and (min-width: 768px) and (max-width: 1450px) {
    /* --- google step --- */
    .add_rate{
        width: 100%;
    }
    .light-theme .RegNewFlow .map-input-box {
        /* transform: translate(-51%, 50%); */
    }
    .mapdirection {
        bottom: auto;
        right: 20px;
        top: 77px;
        position: fixed;
    }
    .space-select-box.select-input-cox.w150px {
        width: 50%;
    }
    .c-input-box .form-control.regFormInput, .PhoneInputInput {
        font-size: 19px;
    }
    .registration-header .center-part {
        width: 100%;
    }
    .info_intro {
        max-width: 100%;
        background-color: transparent;
    }
    /* <!-- glow shadow start  --> */
    .c-font.f-20 {
        font-size: 18px;
    }
        /* <!-- occupants  --> */
        .occupants_select{
            margin-bottom: 40px;
        }
        .light-theme body.px-0 {
            padding-top: 70px;
            padding-left: 15px !important;
            padding-right: 15px !important;
            padding-bottom: 15px !important;
        }

        /* .mapareabox.right_panel {
            width: 100%;
            bottom: 0;
            right: 0;
            position: relative;
            top: auto;
            order: 2;
            margin-top: 10px;
            border: 1px solid var(--base-body-color);
        } */
}
.solarrelativecommentbox{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding-top: 10px;
}
.custom-datepicker.react-datepicker{
    padding-top: 0 !important;
}
.stepper-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto 5px auto;
    width: 400px;
    font-size: 10px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom:1px solid var(--black-op10);
    width: 100%;
    top: 9px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid var(--black-op10);
    width: 100%;
    top: 9px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--white-color);
    border: 1px solid transparent;
    line-height: 1;
  }
  
  .stepper-item.active .step-counter {
    font-weight: bold;
    border: 1px solid var(--dark-yellow); 
  }
  .stepper-item.completed .step-counter {
    background-color: var(--lemon-yellow);
  }
  .stepper-item.completed{
    font-weight: var(--fw-semibold);
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 1px solid var(--lemon-yellow);;
    width: 100%;
    top: 9px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  .w130pxh100px{
    height: 100px;
    width: 130px;
  }
  .Absadditemicons{
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .light-theme .multi_inner_wrapper .comman-image-box.afternone:after{
   border: 1px solid transparent;
  }
  .light-theme .glow-shadow .registration-content .regFormInput.form-control:focus{
    background-color: transparent;
  }
  .tox-statusbar__branding{
    display: none;
  }
  .custom-datepicker.calendarheadernone .react-datepicker-wrapper{
    width: 100%;
  }
  .custom-datepicker.calendarheadernone .react-datepicker{
    padding-top: 0 !important;
  }
  .dropdown-menu.commentDropdownclose .dropdown-item.active svg.comman-icons {
fill: var(--color-black);
  }

.light-theme .skeleton-animation {
    position: relative;
    overflow: hidden;
    background-color: var(--color-white-07);
}
.SkeletonMain{
  cursor: none;
  user-select: none;
}
@keyframes loading {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
.light-theme .skeleton-animation::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  animation: loading 2s cubic-bezier(0.57, -0.01, 0.21, 0.89) infinite;
}
.c-dots.radius500imp{
    border-radius: 500px !important;
}
.light-theme .glow-shadow .fixed-left-panel .on-hover-active-toggle-img:hover .hover-span{
    color: var(--lemon-yellow) !important;
}