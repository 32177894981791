footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
  left: 0;
  padding-left: 90px;
  padding-right: 10px;
  transition: var(--transiton-3s);
  z-index: 16;
}
footer:before{
  position: fixed;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--footer-brop-bg);
  /* backdrop-filter: blur(0.5px); */
  width: 100%;
  height: 100px;
}
/* footer.aichatfoooter:before{
  height: 100px;
    background: #0f2a3c;
} */
footer.pl-20 {
  padding-left: 20px;
}
.footer-add-btn {
  position: relative;
}
.footer-add-btn .btn.btnyellow:active , .footer-add-btn .btn.btnyellow:first-child:active{
  background-color: var(--white);
  border-color: var(--lemon-yellow);
}
.with-right-penel .bottom-link-menu-options.footer-right-10{
  right: -10px;
}
.bottom-link-menu-options {
  position: absolute;
  height: auto;
  max-width: 250px;
  bottom: 50px;
  right: -10px;
  transform: scale(0);
  z-index: 1;
}
.bottom-link-menu-options .bottom-menu-links{
  padding: 10px 8px;
  width: 100%;
}
.bottom-link-menu-options .bottom-menu-links:hover .c-font{
 color: var(--base-font-color);
}
.bottom-link-menu-options.show-menu-link {
  transform: scale(1);
}

footer>div:has(.first) {
  margin-left: 47%;
}

/* .bottom-link-menu-options .bottom-menu-link-one {
  position: absolute;
  top: 25px;
  left: 0px;
}

.bottom-link-menu-options .bottom-menu-link-two {
  position: absolute;
  top: -35px;
  left: -13px;
}

.bottom-link-menu-options .bottom-menu-link-three {
  position: absolute;
  top: -30px;
  left: -10px;
}

.bottom-link-menu-options .bottom-menu-link-four {
  position: absolute;
  top: -80px;
  left: 40px;
}

.bottom-link-menu-options .bottom-menu-link-five {
  position: absolute;
  top: -110px;
  left: 100px;
} */
.footer-nav-links{
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 380px;
  border-radius: 50px;
  /* background-color: var(--base-header-color); */
  height: 60px;
}
.footer-nav-links li a{
  padding: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:82px;
}
.footer-nav-links {
  background-color: var(--base-header-color);
  height: 60px;
  width: 60px;
  border-radius: 500px;
}
.footer-nav-links .footernav{
  opacity: 0;
}
.footer-nav-links .footernvaanimation:hover ~ .footernav{
  opacity: 1;
}

.footer-nav-linksnew{
  /* padding: 5px; */
  /* padding: 0px 15px; */
  /* background-color: var(--base-header-color); */
  background-color: #1a1a1a;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 500px;
  height: 50px;
  width: 60px;
  transition: all ease-in-out 0.3s;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer-nav-linksnew.with-bottom-position {
  bottom: 55px;
}
.footer-nav-linksnew .footernav a{
  padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:auto;
    gap: 10px;
    min-width: 125px;
}
.footer-nav-linksnew .footernav:first-child{
  padding-left: 5px; 
}
.footer-nav-linksnew .footernav:last-child{
  padding-right: 5px;
}
.footernvaanimation .footerglowmain{
  /* padding: 0px 10px; */
}
.footer-nav-linksnew.with-custom-width:hover{
  width: 290px;
}

.logobgfooter {
  /* background-color: red; */
  transition: all ease-in-out 0.3s;
  height: 60px;
  width: 60px;
  border-radius: 500px;
  padding: 0;
  /* background-color: var(--color-white-05); */
  position: absolute;
  top: 50%;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.footer-nav-linksnew .hello_box{
  z-index: 9;
}
.footer-nav-linksnew .footer-mid-btn-animation{
  z-index: 2;
  position: relative;
  height: 70px;
  width: 70px;
}


.footeranimation{}
.light-theme .glow-shadow  .footernav svg.c-icons[fill]{
  color: var(--white-bg);
}
.footeranimation .footernav:nth-child(1) a{
  /* background-color: blue; */
  transform: translateX(20px);
  /* transition: all ease-in-out 0.5s; */
  /* transition-delay: 0.15s; */
  opacity: 1;

}
.light-theme .glow-shadow .footeranimation .footernav a{
  color: var(--white);
  font-weight: var(--fw-semibold);
}
.footeranimation .footernav:nth-child(2) a{
  /* background-color: aquamarine; */
  transform: translateX(25px);
  /* transition: all ease-in-out 0.5s; */
  transition-delay: 0.05s;
  opacity: 0;
}
.footeranimation .footernav:nth-child(4) a{
  /* background-color: black; */
  transform: translateX(-25px);
  /* transition: all ease-in-out 0.5s; */
  transition-delay: 0.05s;
  opacity: 0;

}
.footeranimation .footernav:nth-child(5) a{
  /* background-color: blueviolet; */
  transform: translateX(-20px);
  /* transition: all ease-in-out 0.5s; */
  transition-delay: 0.15s;
  opacity: 0;

}


.footer-nav-linksnew:hover{
  width: 428px;
  /* background-color: var(--base-header-color); */

}
.footer-nav-linksnew:hover .footer-mid-circle-logo-bg{
  opacity: 0;
}

.footer-nav-linksnew.with-custom-width:hover{
  width: 290px;
}
.footeranimation:hover .footernav:nth-child(1) a{
  /* background-color: blue; */
  transform: translateX(0px);
  opacity: 1;
}
.footeranimation:hover .footernav:nth-child(2) a{
  /* background-color: aquamarine; */
  transform: translateX(0px);
  opacity: 1;
}
.footeranimation:hover .footernav:nth-child(4) a{
  /* background-color: black; */
  transform: translateX(0px);
  opacity: 1;
}
.footeranimation:hover .footernav:nth-child(5) a{
  /* background-color: blueviolet; */
  transform: translateX(0px);
  opacity: 1;
}
footer .footeranimation li .absolute_star {
  position: absolute;
  top: -4px;
  right: 8px;
  display: none;
  height: 22px;
  width: 22px;
  border-radius: 500px;
}
footer .footeranimation li:last-child .absolute_star{
  right: 25px;
}
.extranlthemebtn{
  position: fixed;
  bottom: 25px;
  right: 20px;
}
.footeranimation.Footerfix{
  display: block;
  /* width: 410px; */
  width: auto;
  /* background-color: var(--base-header-color); */
}
.footeranimation.Footerfix .footernav:nth-child(1) a , .footeranimation.Footerfix .footernav:nth-child(2) a , .footeranimation.Footerfix .footernav:nth-child(4) a , .footeranimation.Footerfix .footernav:nth-child(5) a{
  opacity: 1;
  transform: translateX(0px);
}
.footeranimation.Footerfix.with-custom-width {
  /* width: 280px; */
  width: auto;
}
.RangeInputAbsolute .react-datepicker-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-left: 42px;
  z-index: 5;
}
.RangeInputAbsolute.custom-datepicker .react-datepicker{
  padding-top: 0 !important;
}
.RangeInputAbsolute.custom-datepicker .react-datepicker__navigation{
  top: 2px;;
}
.light-theme .glow-shadow .RegNewFlow .footernav svg.c-icons[fill]{
  color: rgba(255, 255, 255, 0.7);
} 
.light-theme .glow-shadow .footeranimation .footernav a.active , .light-theme .glow-shadow .footeranimation .footernav a:hover {
  color: #000;
  background-color: var(--lemon-yellow);

}
 .light-theme .glow-shadow .on-hover-active-toggle-img:hover svg.c-icons[fill]{
  color: var(--lemon-yellow);
}
.light-theme .glow-shadow .footeranimation .footernav a.active svg.c-icons[fill] , .light-theme .glow-shadow .footeranimation .footernav a:hover svg.c-icons[fill]  ,.light-theme .glow-shadow .on-hover-active-toggle-img:hover svg.c-icons[fill]{
  color: #000;
}