.comman-data-table.small-data-table thead{
  background: var(--comman-table-thead-bg);
  border-radius: 3px;
  border-bottom: 1px solid transparent;
  transform: translateY(-1px);
}

.comman-data-table.small-data-table thead:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: var(--comman-table-dorder);
  bottom: -1px;
  left: 0;
}
.comman-data-table.vertical-top table.dataTable tbody{
vertical-align: top;
}
.comman-data-table.small-data-table table thead th{
  font-family: var(--base-family);
  font-weight: 400;
  font-size: 13px;
  border: 0px;
}
.comman-data-table.small-data-table table.dataTable tbody tr{
  border-bottom: 1px solid var(--comman-table-dorder);
 
}
.employee-table.comman-data-table table.dataTable tbody tr td{
  position: relative;
 
}
.employee-table.comman-data-table td .employee-table-items{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.comman-data-table.small-data-table table.dataTable tbody tr:last-child {
  border-bottom: 1px solid transparent; 
 
}
.comman-data-table.small-data-table table.dataTable {
  border-collapse: collapse;
}
table.dataTable tbody, table.dataTable tbody td, table.dataTable tbody tr{
  vertical-align: inherit;
}
.comman-data-table.small-data-table.with-horizontal-scroll{
  overflow: auto hidden;
}
.p-10{
  padding: 10px
}
.p-35px{
  padding: 35px;
}
.mb-32px{
  margin-bottom: 32px;
}
.mb-2px{
  margin-bottom: 2px;
}
.p-50px{
  padding: 50px;
}
.p-20px{
  padding: 20px;
}
.pb-20px{
  padding-bottom: 20px;
}
.p-20pximp{
  padding: 20px!important;
}
.p-20pximp.pt-0{
  padding-top: 0 !important;
}
.pt-20pximp{
  padding-top: 20px!important;
}
.pb-20pximp{
  padding-bottom: 20px!important;
}
.ps-20pximp{
  padding-left: 20px!important;
}
.pe-20pximp{
  padding-right: 20px!important;
}
.pe-10px{
  padding-right: 10px;
}
.pt-10px{
  padding-top: 10px;
}
.pb-10px{
  padding-bottom: 10px;
}
.ps-10px{
  padding-left: 10px;
}
.pe-15px{
  padding-right: 15px;
}
.pt-15px{
  padding-top: 15px;
}
.pb-15px{
  padding-bottom: 15px;
}
.pe-5px{
  padding-right: 5px;
}
.pt-5px{
  padding-top: 5px;
}
.pb-5px{
  padding-bottom: 5px;
}
.ps-5px{
  padding-left: 5px;
}
.me-10px{
  margin-right: 10px;
}
.mt-10px{
  margin-top: 10px;
}
.mb-10px{
  margin-bottom: 10px;
}
.ms-10px{
  margin-left: 10px;
}
.ms-20px{
  margin-left: 20px;
}
.me-20px{
  margin-right: 20px;
}
.comman-data-table table.dataTable tfoot td {
  padding: 8px;
  border-top: 0;
}
.comman-data-table table.dataTable tfoot{
  background-color: var(--comman-table-tfoot-bg);
}
.comman-data-table .tfoot-rounded {
  border-radius: 3px!important;
}
.comman-data-table .tfoot-rounded-start {
  border-bottom-left-radius: 3px!important;
  border-top-left-radius: 3px !important;
}
.comman-data-table .tfoot-rounded-end {
  border-top-right-radius: 3px!important;
  border-bottom-right-radius: 3px!important;
}