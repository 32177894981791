header {}
.after-login-header {
  background-color: var(--base-header-color);
  height: 70px;
  padding-left: 80px;
  padding-right: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 17;
  transition: var(--transiton-3s);
}
.after-login-header.p20px{
  padding-left: 20px;
}
.after-login-header.onboarding-header {
  /* padding-right: 10px; */
  padding-left: 70px;
}
.after-login-header .h-right-part-link {
  /* padding: 13px;
  padding-bottom: 11px;
  height: 60px; */
}
.after-login-header .header-logo {}
.after-login-header .header-logo .header-logo-img {
  width: 100px;
}
.after-login-header .left-part {
  /* width: 45%; */
  /* width: 35%; */
  width: 33.33%;
}
.after-login-header .center-part {
  /* width: 33.33%; */
  width: 0;
  flex-grow: 1;
}
.daily-schedule-main.daily-schedule-transform{
  position: absolute;
  top: 50%;
  left: 52.3%;
  transform: translate(-50%, -50%);
  z-index: 20;
}
.task-modal-offcanvas-open .daily-schedule-main.daily-schedule-transform{
  width: auto!important;
  position: static;
  transform: translateY(0);
  /* transform: translateX(-10%); */
}
.after-login-header .right-part {
  width: 33.33%;
}
body.task-modal-offcanvas-open .after-login-header .right-part {
  /* width: auto; */
  /* width: 30%; */
  width: 0;
  flex-grow: 1;
}
.task-modal-offcanvas-open .after-login-header .left-part {
  width: 30%;
}
.after-login-header .h-user-part {
  padding-left: 10px;
  margin-left: 10px;
  height:45px;
}
.after-login-header .h-user-part:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-right: var(--color-border) 1px solid;
  height: 40%;
}
.after-login-header .after-none.h-user-part:after {
  content: none;
}
.after-login-header .h-user-part.no-after:after {
  display: none;
}
.after-login-header .hamburger-menu-link {
  display: none;
  position: relative;
}
.after-login-header .hamburger-menu-link:before {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 500px;
  background: var(--color-green);
  position: absolute;
  right: 0px;
  top: 2px;
  border: 2px solid var(--base-body-color);
}
.after-login-header .hamburger-menu-link.active:before {
  height: 10px;
  width: 10px;
  top: 0px;
}
.after-login-header .login-crown{
  position: absolute;
  top: -1px;
  right: -6px;
    background: var(--base-header-color);
    /* outline: 2px solid #2c4250; */
    border-radius: 500px;
    height: 14px;
    width: 14px;

}
@media screen and (max-width: 767px) {
  .after-login-header {
    padding: 5px 15px;
    height: auto;
  }
  .fixed-left-panel {}
  .office-detail .office-detail-name {
    display: none;
  }
  .after-login-header .link-part {
    display: none;
  }
  .after-login-header .header-logo .header-logo-img {
    width: 80px;
  }
  .after-login-header .hamburger-menu-link {
    display: block;
  }
}
