/*..................css....................*/
.w_350 {
  width: 350px;
}

.w_280 {
  max-width: 280px;
}

.w18_p {
  width: 18px;
  padding: 5px;
}

.list-group .list-group-item {
  background-color: transparent;
  color: var(--base-font-color);
  border: 0;
  padding: 15px;
}

.list-group .list-group-item .inner-border {
  border-bottom: 1px solid var(--color-white-05);
  padding-bottom: 10px;
  padding-top: 5px;
}

.list-group .list-group-item:last-child .inner-border {
  border: 0;
  padding: 0;
}

.list-group .list-group-item.bg-white-03 {
  background-color: var(--color-white-03);
}

.hr_1 {
  border-bottom: 1px solid var(--color-white-10);
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: var(--color-white-10);
  border: 0;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: none;
}

.btn-outline-primary {
  color: var(--base-font-color);
  border: 1px solid var(--color-white-05);
}

.btn-outline-primary:hover {
  background-color: var(--color-white-10);
  border: 1px solid transparent;
  transition: var(--transiton-3s);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show{
  background-color: transparent;
}
.btn-check:focus+.btn,
.btn.complete:focus {
  background-color: var(--color-green);
  border-color: var(--color-white-05);
  color: var(--color-black);
  box-shadow: none;
}

.btn-check:focus+.btn,
.btn.in-progress:focus {
  background-color: var(--color-blue-2);
  border-color: var(--color-white-05);
  color: var(--color-black);
  box-shadow: none;
}

.btn-check:focus+.btn,
.btn.pending:focus {
  background-color: var(--color-warning);
  border-color: var(--color-white-05);
  color: var(--color-black);
  box-shadow: none;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn.complete:active {
  background-color: var(--color-green);
  border-color: var(--color-white-05);
  color: var(--color-black);
  box-shadow: none;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn.in-progress:active {
  background-color: var(--color-blue-2);
  border-color: var(--color-white-05);
  color: var(--color-black);
  box-shadow: none;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn.pending:active {
  background-color: var(--color-white-03);
  border-color: var(--color-white-05);
  color: var(--color-black);
  box-shadow: none;
}
.border-separator {
  border-bottom: 1px solid var(--color-border) !important;
}

.border-separator.list-group-item:last-child {
  border-bottom: none !important;
}

.w100minus150 {
  width: calc(100% - 150px);
}

.bg-black-05 {
  background-color: var(--color-black2);
}

.pdding15px {
  padding: 15px;
}

.badge {
  background: var(--color-white-05);
  font-size: 10px;
  display: inline-block;
  font-weight: var(--fw-regular);
  padding: 6px 10px;
  color: var(--color-white-60);
  border: 1px solid transparent;
}
.badge.h32wauto{
  padding: 10px 10px;
}

.badge.with-min-max-width {
  min-width: 58px;
  /* min-width: 52px; */
  max-width: 64px;
}

.badge.f-12 {
  font-size: 12px;
}

.schedule_btm_box .b_date_box.box_2 {
  width: 18%;
}

.week_box {
  width: 14.28%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule_btm_box .top_title {
  color: var(--color-white-60);
  padding-bottom: 10px;
}

.schedule_btm_box .b_date_box .date_wrapper .date_box {
  padding-bottom: 5px;
}

/* comman-toggle-img-btn */
.comman-toggle-img-btn img {
  width: 20px;
  display: none;
}

.comman-toggle-img-btn a {
  padding: 6px 8px;
  background-color: var(--color-white-05);
  border-radius: 3px;
  margin-right: 10px;
}

.comman-toggle-img-btn a:last-child {
  margin-right: 0px;
}

.comman-toggle-img-btn .default-image {
  display: block;
}

.comman-toggle-img-btn.active .active-image {
  display: block;
}

.comman-toggle-img-btn.active .active-image img {
  display: block;
}

.comman-toggle-img-btn.active .default-image {
  display: none;
}

.b_order {
  border: 1px solid var(--color-white-05) !important;
}

.comman-toggle-img-btn.img_w14 img {
  width: 14px;
}

.w_calc350 {
  width: calc(100% - 350px);
}

.comman-contnet-wrapper {
  border: 1px solid var(--color-border);
  border-radius: 3px;
}

.max-height100 {
  max-height: 100%;
}

.dropdown-toggle.dropdown-img-end:after {
  position: absolute;
  right: 0;
  top: 50%;
}

.required {
  position: absolute;
  top: -9px;
  left: 24px;
  font-size: 12px;
  color: var(--color-white-30);
}

.required:before {
  content: "";
  position: absolute;
  background-image: url("../images/asterisk_star.svg");
  background-position: right;
  background-size: 12px;
  background-repeat: no-repeat;
  top: 1px;
  right: -15px;
  height: 8px;
  width: 10px;
}

.h_50 {
  height: calc(100% - 50px);
}

.h_calc77 {
  height: calc(100% - 77px);
}
.h_calc30 {
  height: calc(100% - 30px);
}

.h_100 {
  height: calc(100% - 100px);
}

.h_calc182 {
  height: calc(100% - 182px);
}

.h_16vh{
  height: 16vh;
}
.comman_vertical_scroll {
  height: calc(100% - 50px);
  overflow: hidden auto;
}

.comman_vertical_btn_h.vertical_scroll35 {
  height: calc(100% - 35px);
}
.comman_vertical_btn_h.vertical_scroll110 {
  height: calc(100% - 110px);
}
.comman_vertical_btn_h.vertical_scroll120 {
  height: calc(100% - 120px);
}

.comman_vertical_btn_h.vertical_scroll180 {
  height: calc(100% - 180px);
}

.comman_vertical_btn_h.vertical_scroll167 {
  height: calc(100% - 167px);
}

.comman_vertical_btn_h.vertical_scroll135 {
  height: calc(100% - 135px);
}
.vertical_scroll230 {
  height: calc(100% - 230px);
}
.comman_vertical_btn_h {
  height: calc(100% - 50px);
}

.comman_vertical_btn_h.scroll_60 {
  height: calc(100% - 60px);
}
.comman_vertical_btn_h.scroll_110px{
  height: calc(100% - 110px);
}
.input_label {
  margin-bottom: 5px;
  color: #ffffff99;
  font-size: 15px;
  font-family: var(--title-family);
  font-weight: 400;
}

.comman_action_icon .action_icon.h35w35 {
  height: var(--space-35);
  width: var(--space-35);
}
.comman_action_icon .action_icon.h28w28 {
  height: 28px;
  width: 28px;
}
.comman_top_header {
  padding-left: 10px;
  min-height: 50px;
  padding-right: 10px;
  background: var(--base-header-color);
}
.light-theme .comman_top_header .tab-name {
  color: var(--base-color);
}
.comman-verticle-tab .tab_link {
  padding: 10px;
  transition: all 0.3s;
  cursor: pointer;
  /* Added by DR temprory from client suggsetion Designers Please take care of this */
  /* width: 300px; */
  width: 100%;
}
.comman-verticle-tab .tab_link.p13px{
  padding: 13px;
}
.comman-verticle-tab .comman-list .tab_link.active:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  height: 100%;
  width: 4px;
  background: var(--color-gradient);
  transform: translateY(-50%);
  border-radius: 0px 500px 500px 0px;
}
.Type-space-verticle-tab.comman-verticle-tab .comman-list .tab_link{
  width: calc(100% - 15px);
}
.Type-space-verticle-tab.comman-verticle-tab .comman-list .tab_link.active{
  width: calc(100% - 0px);
  color: var(--color-green);
}

.comman-verticle-tab .comman-list .tab_link.active {
  background-color: var(--color-white-05);
}

.comman-verticle-tab .comman-list .tab_link:hover {
  background-color: var(--color-white-03);
  transition: var(--transiton-3s);
}

.benchmark_list .list-group-item {
  padding-top: 0;
}

tbody tr:first-child td .close_icon {
  display: none;
}

.max_h50 {
  max-height: 50%;
}

.new_space_middle_height {
  height: calc(100% - 55px);
}

.comman-toggle-img-btn.img-width-18 img {
  width: 18px;
}

.comman-content-bottom-btn {
  margin-top: 15px;
}

.comman-content-bottom-btn.margin-15 {
  margin: 15px;
}

.comman-content-scroll-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.comman-content-scroll-wrapper.comman_vertical_btn_h {
  height: calc(100% - 50px);
}

.comman-content-scroll-wrapper.comman_vertical_btn_h90 {
  height: calc(100% - 90px);
}

.comman-content-scroll {
  overflow: hidden auto;
  height: 100%;
}

.comman-content-scroll.h10030 {
  height: calc(100% - 30px);
}

.custom-select-menu.select-modal .react-select__control {
  min-height: 43px;
  height: auto;
}
.custom-select-menu.margin_remove_child .react-select__input-container{
  margin: 0px;
}
.custom-select-menu.margin_remove_child .react-select__indicators{
  height: 27px;
}
.custom-select-menu .react-select__multi-value {
  background-color: var(--color-white-03);
}

.custom-select-menu .css-6j8wv5-Input,
.custom-select-menu .css-6j8wv5-Input input {
  color: var(--base-font-color);
}
.custom-select-menu .react-select__input{
  color: var(--base-font-color) !important;
}

.custom-select-menu.select-modal .react-select__control {
  min-height: 43px;
  height: auto;
}

.custom-select-menu.select-modal .css-12jo7m5 {
  max-width: 100% !important;
}

.custom-select-menu.with-filter-select {
  min-width: 100px;
  margin-right: 20px;
  border: 0;
  height: 35px;
  min-height: 35px;
  z-index: 9;
}
.custom-select-menu.with-filter-select.min-w-auto{
  min-width: auto;
}
.stand-alone-top .custom-select-menu.with-filter-select.min-w-auto .react-select__input{
  caret-color: transparent !important; 
}
.custom-select-menu .react-select__menu-list,
.react-select__menu-portal .css-4ljt47-MenuList{
  background-color: var(--dropdown-bg-color);
}
.custom-select-menu.with-filter-select.min-w-70 {
  min-width: 70px;
  width: 70px;
}

.custom-select-menu.with-filter-select.w-180px {
  width: 180px;
}

.custom-select-menu.with-filter-select.scroll-height-150 .react-select__menu-list {
  max-height: 150px;
}

.custom-select-menu.scroll-height-30vh .react-select__menu-list {
  max-height: 25vh;
}

.custom-select-menu.scroll-height-20vh .react-select__menu-list {
  max-height: 20vh;
}

.custom-select-menu .react-select__single-value {
  font-size: 12px;
  color: var(--color-white);
}
.custom-select-menu.f14 .react-select__single-value{
  font-size: 14px;
  text-align: center;
}
.custom-select-menu .react-select__single-value .react_select_d_none{
  display: none;
} 
.custom-select-menu.min-w-15 {
  min-width: 160px;
}

.custom-select-menu.w-135 {
  width: 135px;
}
.custom-select-menu.max-w-250 {
  max-width: 250px;
}

.custom-select-menu.height-38px .css-1pahdxg-control,
.custom-select-menu.height-38px .react-select__control {
  height: 38px;
}

.custom-select-menu.react-select--is-disabled {
  opacity: 0.6;
  background-color: var(--color-white-03);
}
.custom-select-menu.onboarding-map-input.react-select--is-disabled {
  opacity: 1;
  background-color: var(--color-white-03);
}
.custom-select-menu .react-select__control {
  font-size: 14px !important;
}
.custom-select-menu.border-0 .react-select__control {
  border: 0;
}
.custom-select-menu.wirh-border-none .react-select__control{
  border: 0;
  background: transparent;
  padding-left: 5px;
}
.custom-select-menu.wirh-border-none .react-select__control.react-select__control--is-focused{
  background-color: transparent !important;
}
.custom-select-menu.with-filter-select.with-mr-10 {
  margin-right: 10px;
}

.custom-select-menu.with-filter-select.min-h-28 {
  height: 28px;
  min-height: 28px;
  z-index: 9;
}
.custom-select-menu.with-filter-select.min-h-33 {
  height: 33px;
  min-height: 33px;
  z-index: 9;
}
.custom-select-menu.with-filter-select.min-h-33 .react-select__control{
  height: 30px !important;
  min-height: 30px!important;
}
.css-qbdosj-Input{
  padding: 0!important;
}
.custom-select-menu.min-h-28 .css-6j8wv5-Input {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.custom-select-menu.min-h-28 .css-319lph-ValueContainer {
  height: 100%;
}

.custom-select-menu.min-h-28 .react-select__placeholder {
  height: 25px;
}

.custom-select-menu.icon-select-menu .react-select__placeholder,
.custom-select-menu.icon-select-menu .react-select__input-container,
.custom-select-menu.icon-select-menu .react-select__single-value{
  display: none;
}
.custom-select-menu.icon-select-menu{
  min-width: 50px;
}
.custom-select-menu.icon-select-menu .react-select__menu {
  min-width: 200px;
}
.custom-select-menu.icon-select-menu .react-select__indicators{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.custom-select-menu.icon-select-menu .react-select__indicators .react-select__indicator{
  width: 100%;
  height: 100%;
}
.custom-select-menu.with-filter-select.css-b62m3t-container .react-select__control {
  /* background-color: transparent; */
  height: 35px;
  min-height: 35px;
  overflow: hidden;
}

.custom-select-menu.with-filter-select.min-h-28 .react-select__control {
  height: 28px;
  min-height: 28px;
}
.custom-select-menu .react-select__control.react-select__control--menu-is-open,
.custom-select-menu .react-select__control{
  /* border-color: var(--color-white-10)!important; */
}
.custom-select-menu .react-select__control.react-select__control--menu-is-open:hover,
.custom-select-menu .react-select__control:hover{
  /* border-color: var(--color-white-10)!important; */
}
.custom-select-menu.with-filter-select .react-select__indicator {
  padding: 3px;
}

.custom-select-menu.with-filter-select.css-b62m3t-container .css-1pahdxg-control {
  height: 35px;
  background-color: transparent !important;
  min-height: 35px;
  overflow: hidden;
}
.custom-select-menu.h53 , .custom-select-menu.h53 .react-select__control{
  height: 53px;
}
.custom-select-menu.with-filter-select.min-h-28.css-b62m3t-container .css-1pahdxg-control {
  height: 28px;
  min-height: 28px;
}

.custom-select-menu.min-h-28 .react-select__control{
  min-height: 28px;
}
.custom-select-menu.bg-transparent .react-select__control{
  background-color: transparent;
}
.custom-select-menu .react-select__control {
  background-color: var(--color-white-03);
  border-radius: 3px;
  border: var(--color-white-10) 1px solid;
  font-size: 14px !important;
  font-family: var(--base-family);
  color: var(--base-font-color);
  height: auto;
  overflow: hidden auto;
  cursor: pointer;
}
.custom-select-menu.border-03 .react-select__control{
  border: 1px solid var(--color-white-03) !important;
}
.custom-select-menu .react-select__control:hover,
.css-1pahdxg-control:hover {
  /* border-color: var(--color-white-10) !important; */
}

.custom-select-menu .react-select__indicator{
  background-image: url("../images/ic-close-white.svg");
  background-position: center right 8px;
  background-size: 14px;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  opacity: 0.5;
  
}
.custom-select-menu .react-select__dropdown-indicator {
  background-image: url("../images/ic-caret-down-fill-white.svg");
  background-position: center right 8px;
  background-size: 14px;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  opacity: 0.5;
}


.custom-select-menu .css-t3ipsp-control {
  box-shadow: none;
}

.custom-select-menu .react-select__dropdown-indicator svg,
.custom-select-menu.indicator-none .react-select__dropdown-indicator {
  display: none;
}

.custom-select-menu .css-1u9des2-indicatorSeparator {
  display: none;
}

.custom-select-menu .react-select__indicator svg {
  display: none;
}

.custom-select-menu .css-1okebmr-indicatorSeparator {
  background-color: var(--color-white-03);
  display: none;
}

.custom-select-menu .css-6j8wv5-Input input {
  background-color: var(--color-white-03);
}

.custom-select-menu.select-modal .css-1pahdxg-control {
  min-height: 43px;
  height: auto;
}

.custom-select-menu .css-1pahdxg-control {
  background-color: var(--color-white-03) !important;
  border: var(--color-white-10) 1px solid;
  box-shadow: 0 0 0 0px var(--color-white-10);
  height: 43px;
  overflow: hidden auto;
}

.image-border-none .custom-select-menu .css-1pahdxg-control {
  border: transparent 1px solid;
}

.custom-select-menu .react-select__control {
  height: 53px;
  border-color: transparent!important;
}
.custom-select-menu.h_41 .react-select__control {
  height: 41px;
}
.custom-select-menu.h_auto .react-select__control {
  height:auto;
}
.custom-select-menu.max-h95 .react-select__control {
  max-height: 95px;
  height: auto;
  /* min-width: 200px; */
}
.custom-select-menu.max-h95 .react-select__menu {
  /* min-width: 200px; */
}

.custom-select-menu.max-h95 .css-1pahdxg-control {
  /* min-width: 200px; */
  height: auto;
}

.image-border-none .custom-select-menu .react-select__control {
  border: transparent 1px solid;
  border-radius: 0px 3px 3px 0px;
}

.select-half-separator .custom-select-menu .react-select__dropdown-indicator{
  border-right: 1px solid var(--color-white-10);
  padding-right: 15px!important;
}
.select-half-separator .custom-select-menu .react-select__control{
  border-radius: 3px 0px 0px 3px;
}
.select-half-separator .form-control{
  border-radius: 0px 3px 3px 0px;
}
.select-half-separator .select-half-separator-main{
  border-radius: 3px;
  border: 1px solid transparent;
  background-color: var(--color-white-03);
  height: 53px;
}
.select-half-separator .select-half-separator-main .custom-select-menu .react-select__control,
.select-half-separator .select-half-separator-main .form-control{
  background-color: transparent;
  border-color: transparent;
}
.select-half-separator .select-half-separator-main .css-1fdsijx-ValueContainer{
  padding-left: 0;
}
.custom-select-menu .css-tj5bde-Svg {
  color: var(--color-white-30);
}

.custom-select-menu .css-2613qy-menu {
  background-color: var(--color-white-03) !important;
  display: inline-block;
}

.custom-select-menu .react-select__menu,
.react-select__menu-portal .react-select__menu {
  background-color: var(--base-header-color);
  border-radius: 3px;
  z-index: 6;
  margin: 5px 0px 0px 0px;
  /* position: relative; */
}

.custom-select-menu.w-200 .react-select__menu{
  width: 200px;
}

.custom-select-menu.drop-up .react-select__menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 5px;
}
.custom-select-menu.drop-right .react-select__menu {
  right: 0;
}

.custom-select-menu .react-select__menu-list {
  font-size: 13px;
  color: var(--color-white-60);
  transition: var(--transiton-3s);
}
.react-select__menu-portal .css-4ljt47-MenuList {
  max-height: 20vh;
}

.custom-select-menu .react-select__option,
.react-select__menu-portal .react-select__option {
  background-color: transparent;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid var(--color-white-10);
  cursor: pointer;
}

.custom-select-menu .react-select__option:hover,
.react-select__menu-portal .react-select__option:hover {
  background-color: var(--color-white-05);
}

.custom-select-menu .react-select__option:last-child,
.react-select__menu-portal .react-select__option:last-child {
  border-bottom: 0;
}

.custom-select-menu .css-9gakcf-option,
.react-select__menu-portal .css-9gakcf-option  {}
.react-select__menu-portal .css-9gakcf-option {
  background-color: var(--color-white-05);
  border-bottom: 1px solid var(--color-white-10);
}

.custom-select-menu .css-9gakcf-option:last-child {
  border-bottom: 0;
}

.custom-select-menu .css-d7l1ni-option {
  background-color: var(--color-white-03);
}

.custom-select-menu .react-select__multi-value .react-select__multi-value__label {
  color: var(--base-font-color);
  max-width: 75px;
}

.custom-select-menu.react-select-wauto .react-select__multi-value .react-select__multi-value__label {
  max-width: 100%;
}

.custom-select-menu .css-d7l1ni-option:hover {
  background-color: var(--color-white-03);
  transition: var(--transiton-3s);
}

.custom-select-menu .react-select__option {
  background-color: transparent;
  /* background-color: var(--color-white-03); */

  text-align: left;
  border-bottom: 1px solid var(--color-white-10);
  cursor: pointer;
  transition: var(--transiton-3s);
}

.custom-select-menu .react-select__multi-value__remove:hover {
  background-color: var(--color-white-05);
  color: var(--base-font-color);
}

.custom-select-menu .react-select__value-container--is-multi {
  /* max-width: 240px; */
}

.custom-select-menu .react-select__indicators {}
.custom-select-menu.selece-arror-sticky .react-select__indicators {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  height: 0;
}
.comman-table-scroll .gelpCx,
.connan-table-scroll .sc-dmRaPn,
.comman-table-scroll .sc-dnwKUv ,
.comman-table-scroll .sc-dnwKUv,
.comman-table-scroll .eguvnT,
.comman-table-scroll .sc-feUZmu,
.comman-table-scroll .sc-hzhJZQ,
.comman-table-scroll .fDglDD {
  height: 100%;
}
.comman-table-scroll .sc-dmRaPn>div , .comman-table-scroll .sc-dnwKU>div , .comman-table-scroll .sc-dnwKUv>div, .comman-table-scroll .eguvnT>div{
  height: 100%;
}
.comman-table-scroll .rdt_Table {
  overflow: hidden auto;
}

.custom-select-menu .react-select__option:last-child {
  border-bottom: 0;
}

.react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.custom-select-menu .react-select__placeholder {
  text-align: left;
  color: var(--color-white-60);
  display: flex;
  align-items: center;
}
.custom-select-menu.f14 .react-select__placeholder{
  text-align: center;
  justify-content: center;
  color: hsl(0, 0%, 50%);
}
.custom-select-menu.color-white .react-select__placeholder{
  color: white;
}

.custom-select-menu.multi-select-with-scroll {
  height: auto !important;
}

.custom-select-menu.multi-select-with-scroll.css-b62m3t-container .css-d7l1ni-option {
  /* margin: 1px 2px; */
}

.custom-select-menu.multi-select-with-scroll .react-select__indicator:last-child {
  background-image: url("../images/ic-caret-down-fill-white.svg");
  background-position: center right 6px;
  background-size: 14px;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  opacity: 0.5;
}

.custom-select-menu.multi-select-with-scroll .react-select__indicator:first-child {
  background-image: url("../images/ic-close-white.svg");
  background-position: center right 6px;
  background-size: 14px;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  opacity: 0.5;
}

.custom-select-menu.multi-select-with-scroll .react-select__dropdown-indicator:last-child {
  background-image: url("../images/ic-caret-down-fill-white.svg");
  background-position: center right 6px;
  background-size: 14px;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  opacity: 0.5;
}

.custom-select-menu.multi-select-with-scroll .react-select__dropdown-indicator:first-child {
  background-image: url("../images/ic-close-white.svg");
  background-position: center right 6px;
  background-size: 14px;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  opacity: 0.5;
}

.custom-select-menu.w-220 {
  width: 220px;
}

.custom-select-menu.w-220.with-inner-width.css-b62m3t-container .react-select__placeholder {
  width: 220px;
}

.css-1insrsq-control {
  background-color: transparent !important;
  border: 1px solid var(--color-white-10) !important;
  opacity: 50 !important;
}

.css-109onse-indicatorSeparator {
  background-color: transparent !important;
}

.custom-accordian-main .accordion-button.with-arrow-left::after {
  position: absolute;
  top: 18px;
  right: 0px;
  width: 18px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s all ease;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--color-white-10);
  border-radius: 500px;
}

.file-document .file-doc {
  display: block;
}

.file-document .image-doc {
  display: none;
}

.file-document .pdf-doc {
  display: none;
}

.image-document .file-doc {
  display: none;
}

.image-document .image-doc {
  display: block;
}

.image-document .pdf-doc {
  display: none;
}

.pdf-document .file-doc {
  display: none;
}

.pdf-document .image-doc {
  display: none;
}

.pdf-document .pdf-doc {
  display: block;
}

.modal-body {
  height: calc(100% - 1.75rem * 2);
}

.comman-round-box {
  transition: var(--transiton-3s);
}

.comman-round-box.active {
  border: 1px solid var(--color-green-50);
}

.comman-round-box.active .active-img {
  display: block;
}

.comman-round-box.active .default-img {
  display: none;
}

.comman-round-box .active-img {
  display: none;
}

.w100minus100per {
  width: 0!important;
  flex-grow: 1;
}

/*...*/
.form-switch .form-check-input {
  background-position-x: 3px;
}

.form-switch .form-check-input:checked {
  background-position-x: 20px;
}

.dropdown-menu.custom-dropdown-popover {
  transform: translate(230px, 41px) !important;
}

/*..............................*/
.custom-accordian-main-scroll59 {
  overflow: hidden auto;
  max-height: calc(100% - 59px);
}

.custom-accordian-Header-sticky {
  position: sticky !important;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgb(29 53 69);
  z-index: 3;
}

.custom-accordian-Header-sticky.bg-transparent {
  background-color: transparent;
}

.custom-accordian-Header-sticky.with-top-46px {
  top: 46px;
}

.custom-accordian-Header-sticky.with-bg-comman-transparent {
  background-color: var(--bg-white-hex-03);
}

.custom-accordian-Header-sticky.bg-premium-transparent {
  background-color: var(--premium-bg-transparent);
}

.custom-accordian-Header-sticky.with-bg-color-white {
  background-color: rgb(45 70 86);
}

.custom-modal-section-sticky {
  position: sticky !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--base-modal-bg);
  z-index: 5;
}

.custom-accordian-main .custom-accordian-Header-sticky.bg-white-03 .accordion-button:not(.collapsed) {
  background-color: var(--color-white-03);
  transition: var(--transiton-3s) !important;
}

.custom-accordian-main .custom-accordian-Header-sticky .accordion-button:not(.collapsed) {
  /* background-color: rgb(40 63 78); */
  background-color: rgb(29 53 69);
  transition: var(--transiton-3s) !important;
}

.custom-accordian-main .custom-accordian-Header-sticky.bg-base-header-color .accordion-button,
.custom-accordian-main .custom-accordian-Header-sticky.bg-base-header-color .accordion-button:not(.collapsed) {
  background-color: var(--base-header-color) !important
}

.custom-accordian-main .custom-accordian-Header-sticky.btn-bg .accordion-button {
  background: #293B4A;
  border-radius: 3px !important;
  transition: var(--transiton-3s) !important;
}

.custom-accordian-main .custom-accordian-Header-sticky.btn-bg .accordion-button:not(.collapsed) {
  border-radius: 3px 3px 0px 0px !important;
  background: #293B4A;
}

.footer-add-btn-bg {
  position: absolute;
  background-image: url("../images/globe-shape.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  width: 28vw;
  /* width: auto; */
  height: 74vh;
  bottom: 0;
  right: 0;
}

.with-right-penel .bottom-link-menu-options {
  right: -10px;
}
.task-modal-offcanvas-open .bottom-link-menu-options {
  right: -6px;
}
.with-right-penel .bottom-link-menu-options.with-right-position {
  right: 0px;
}

.footer-add-btn .rotate-image.plus-rotate-image {
  transform: rotate(45deg);
}

.offcanvas.offcanvas-end.transform-right .modal-close-button.modal-close {
  display: block !important;
}

.offcanvas.taskDetailModal .modal-close-button.modal-close {}

.max-width75 {
  max-width: 75px;
}

.min-width55 {
  min-width: 55px;
}

.max-width30 {
  max-width: 30px;
}

.max-width200 {
  max-width: 200px;
}

.rs-picker-toggle-active[aria-expanded="true"] {
  border: 1px solid green;
  border-radius: 3px;
  padding: 8px 31px;
}

.rs-picker-toggle-active[aria-expanded="false"] {
  border: 1px solid transparent;
}

.comman_vertical_btn_h .task-list-card {
  overflow: auto hidden;
}

.comment-input-box .paper-clip-file {
  position: relative;
  padding-right: 55px;
  height: 25px;
  width: 25px;
  display: flex;
}

.comment-input-box .upload-image-preview {}

.custom-select-menu.custom-select-menu-height .react-select__control {
  height: 35px;
  border: 1px solid var(--color-white-05);
  background-color: transparent;
}

.custom-select-menu.custom-select-menu-height .css-1pahdxg-control {
  height: 35px;
  border: 1px solid var(--color-white-05);
}

.custom-select-menu.select-modal.border-0 .react-select__control {
  border: 0;
}

.custom-select-menu.select-modal.border-0 .css-1pahdxg-control {
  border: 0;
}

.min-w-auto {
  min-width: auto !important;
}

.task-sub-list .right-pill-addtask.checklist-accordian-task {
  transform: translate(60px, 0px);
  transition: var(--transiton-3s);
}

.task-sub-list .right-pill-addtask .task-sub-list-add {
  border-left: 1px solid transparent !important;
}

.task-sub-list:hover .right-pill-addtask .task-sub-list-add {
  border-left: 1px solid var(--color-white-05) !important;
}

.task-sub-list:hover .right-pill-addtask .task-sub-list-add.border-left-0 {
  border-left: 0 !important;
}

.mension-box-chet-box {
  position: absolute;
  max-height: 30vh;
  padding: 0 15px;
  width: 200px;
  left: 15%;
  bottom: 100px;
  overflow: auto;
  background-color: var(--left-panel-bg);
  border-radius: 3px;
}

.dropdown-menu .dropdown-item:hover {
  background-color: var(--color-white-05);
  cursor: pointer;
}
.dropdown-menu .dropdown.show .dropdown-item{
  background-color: var(--color-white-05);
}

.dropdown-menu .hr_1:last-child {
  border: 0;
  ;
}

.sub-custom .comment-input-box input:not([value=""]) {
  width: 100%;
  padding-right: 40px !important;
}

.taskDetailModal.for-disabled {
  position: fixed;
  opacity: 1;
}

.for-disabled .for-accessible {
  position: relative;
  z-index: 12;
}

.for-disabled {
  position: relative;
  user-select: none;
  /* opacity: 0.8; */
  cursor: auto;
}

.disabledElenent {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0.8; 
  cursor: auto;
  z-index: -1;
}

.for-disabled:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: auto;
}

.for-disabled.z-index-3:after {
  z-index: 3;
}

.for-disabled .on-hover-active-toggle-img {
  user-select: none;
}

.for-disabled .on-hover-active-toggle-img .active-img {
  display: none;
}

.for-disabled .on-hover-active-toggle-img .default-img {
  display: block;
}

.for-disabled .dropdown-menu.show {
  display: none !important;
}

.offcanvas.taskDetailModal.modal-calendar {
  top: 70px;
  height: calc(100vh - 161px);
  right: 11px;
}

.offcanvas.taskDetailModal.modal-full {
  top: 0px;
  /* height: 100vh; */
  height: 100%;
  z-index: 20;
  /* z-index: 11; */
  right: 0;
  /* width: calc(50% - 160px); */
  width: calc(100% - 358px);
  box-shadow: 0px 0px 15px 5px var(--full-modal-box-shadow);
}

.offcanvas.taskDetailModal.modal-full.without-comment-section {
  width: calc(100% - 0px);
}
.offcanvas.taskDetailModal.stand-alone {
  top: 0px;
  height: 100%;
  z-index: 1050;
  right: 300px;
  /* width: 50%; */
  position: fixed;
  border-right: rgb(21 41 58) 3px solid;
}

.offcanvas.taskDetailModal.stand-alone .comman_vertical_btn_h {
  z-index: 11;
}

.offcanvas.taskDetailModal.stand-alone .overflow-hiiden-auto {
  z-index: 11;
  position: relative;
}

/* .offcanvas.taskDetailModal.stand-alone .task-setting-section {
  opacity: 0.5;
} */

.offcanvas.taskDetailModal.stand-alone-comments {
  top: 0px;
  /* height: 100vh; */
  height: 100%;
  z-index: 20;
  right: 0;
  width: 300px;
  /* border-right: rgb(21 41 58) 3px solid; */
  border-right: 0px;
}
.offcanvas.taskDetailModal.stand-alone-comments.image-view-comment{
  z-index: 21;
}

.full-comment-width400.offcanvas.taskDetailModal.stand-alone-comments,
.full-comment-width400.custom-modal-style .task-detail-right-main,
.with-right-penel .offcanvas.taskDetailModal.stand-alone-comments.full-comment-width400 {
  width: 400px;
}
.right-penel-no-padding .full-comment-width400.offcanvas.taskDetailModal.stand-alone-comments,
.right-penel-no-padding .full-comment-width400.custom-modal-style .task-detail-right-main,
.right-penel-no-padding .with-right-penel .offcanvas.taskDetailModal.stand-alone-comments.full-comment-width400 {
  width: 300px;
}

.offcanvas.taskDetailModal.internaltaskmodal {
  top: 145px;
  right: 15px;
  width: calc(50% - 180px);
  height: calc(100vh - 235px);
}

.taskDetailModal #modal_zoom_out {
  display: block;
}

.taskDetailModal.modal-calendar #modal_zoom_out {
  display: none;
}

.taskDetailModal.modal-full #modal_zoom_out {
  display: none;
}

.taskDetailModal.modal-calendar #modal_zoom_in {
  display: block;
}

.taskDetailModal.modal-full #modal_zoom_in {
  display: block;
}

.taskDetailModal #modal_zoom_in {
  display: none;
}

.taskDetailModal .modal-title-left .form-control {
  padding: 5px;
  color: var(--base-color);
  border: 0;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
}

.taskDetailModal .custom-modal-title {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 5px;
}

.custom-accordian-main .accordian-with-card {
  border: 1px solid var(--color-white-10);
  border-radius: 3px;
  background-color: var(--color-white-03);
  margin-bottom: 10px;
}

.custom-accordian-main .accordian-with-card .accordian-card-header {
  min-height: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.custom-accordian-main .accordian-with-card .accordian-card-header .accordion-button {
  margin-top: auto;
  position: relative;
}

.custom-accordian-main .accordian-with-card .accordian-card-header .accordion-button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90px;
  width: 100%;
  background: rgb(15, 42, 60);
  background: linear-gradient(180deg, rgba(15, 42, 60, 0) 0%, rgba(23, 49, 66, 1) 100%);
}

.custom-accordian-main .accordian-with-card .accordian-card-header .accordian-card-main {
  width: 100%;
  position: relative;
}

.custom-accordian-main .accordian-with-card .accordian-card-header .accordian-card-main .accordian-card-bottom {
  padding-top: 20px;
}

.custom-accordian-main .accordian-with-card .accordian-card-header .accordion-button:hover {
  background-color: inherit;
  border-bottom: 1px solid transparent;
}

.custom-accordian-main .accordian-with-card .accordian-card-list-wrapper {
  padding: 0px 10px;
  padding-top: 10px;
  margin-top: 0;
}

.custom-accordian-main .accordian-with-card .task-sub-list.hover-disable:hover {
  background-color: inherit;
}

/* .multi_inner_wrapper .comman-image-box.multi-wrapper-border:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -2px;
  transform: translateY(-50%);
  background-color: var(--color-white-05);
  width: 1px;
  height: 15px;
} */

.multi_inner_wrapper .comman-image-box.multi-wrapper-border.after-dnone:after {
  display: none;
}

.crownOrange-white {
  display: block;
}

.crownOrange-green {
  display: none;
}

/* 12-12-22 */
.border-red {
  border: 1px solid var(--color-red);
}

svg.comman-icons.lt-red {
  stroke: var(--color-red);
}

.schedule-section {
  background-color: #1D3648;
  display: block;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border: transparent 1px solid;
  transition: var(--transiton-3s);
}
.schedule-section:hover {
  background-color: var(--color-white-05);
  transition: var(--transiton-3s);
}

.schedule-listing-items {
  display: block;
  padding: 20px;
  /* border-bottom: 1px solid var(--color-white-05); */
}

svg.comman-icons.lt-green {
  stroke: var(--color-green);
}

.schedule-section.schedule-tabs {
  padding: 10px 15px;
  position: relative;
}

.schedule-section.schedule-tabs-main {
  margin: 0;
  z-index: 20;
  position: relative;
}

.schedule-section.schedule-tabs:nth-child(1) {
  margin: 0px 15px;
  margin-top: -10px;
  z-index: 19;
}

.schedule-section.schedule-tabs:nth-child(2) {
  margin: 0px 20px;
  margin-top: -5px;
  z-index: 18;
}

.schedule-section.schedule-tabs:nth-child(3) {
  margin: 0px 40px;
  margin-top: -5px;
  z-index: 17;
}

.schedule-section.schedule-tabs:nth-child(4) {
  margin: 0px 60px;
  margin-top: -5px;
  z-index: 16;
}

.schedule-section.schedule-tabs:nth-child(5) {
  margin: 0px 80px;
  margin-top: -5px;
  z-index: 15;
}

.schedule-section.schedule-tabs:nth-child(6) {
  margin: 0px 100px;
  margin-top: -5px;
  z-index: 14;
}

.schedule-section.schedule-tabs:nth-child(7) {
  margin: 0px 110px;
  margin-top: -5px;
  z-index: 13;
}

.schedule-section.schedule-tabs:nth-child(8) {
  margin: 0px 12px;
  margin-top: -5px;
  z-index: 12;
}

.schedule-listing {
  overflow: hidden auto;
}

.schedule-listing-items .active-icon {
  display: none;
}

.schedule-listing-items.active .active-icon {
  display: block;
}

.schedule-listing-items .inctive-icon {
  display: block;
}

.schedule-listing-items.active .inctive-icon {
  display: none;
}

.schedule-section.active {
  border: var(--color-green-50) 1px solid;
}

.schedule-content-wrapper .schedule-listing-items:last-child {
  /* border-bottom: none; */
}

.ScheduleBookMeeting-btn .w-33.schedule-section {
  min-width: 33.33% !important;
}

.schedule-listing-items.active svg.comman-icons {
  /* stroke: var(--color-green); */
}

.schedule-listing-items.active {
  /* color: var(--color-green); */
}

.time-slots-content-wrapper .time-slots-left {
  position: relative;
  width: 60px;
  padding: 10px 0;
}

.time-slots-content-wrapper .time-slots-left:after {
  position: absolute;
  content: '';
  width: 1px;
  height: 70%;
  background-color: var(--color-white-05);
  bottom: 8px;
  left: 50%;
}

.schedule-info {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px;
}


.note-comment-wrapper .note-edit-wrapper {
  padding-left: 50px;
}

.custom-footer .position-sticky-bottom {
  flex-grow: 1;
}
.aicommentbox .custom-width{
  width: calc(100% - 145px);
}
.fileuploadabsolute{
  right: 80px;
}
.absoluteiconbadge{
  position: absolute;
  top: -7px;
  right: -7px;
  height: 16px;
  width: 16px;
}
.absolutecrownbadge{
  top: -10px;
  right: -10px;
  
}
.absolutecrownbadge.top7px{
  top: -7px;
}
.mb20px{
  margin-bottom: 20px;
}
.file-upload-main{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
}
.calednarPlacehoder{
  width: 33.33% !important;
  height: 50px;
  position: sticky; 
  top: 0;
}
.calednarPlacehoder.w7days{
  width: 14.28% !important;
}
.absoluteLoader{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  height: 100%;
    width: 100%;
    z-index: 20;
}
.pl70px{
  padding-left: 70px;
}
.w25per{
  width: 25%;
}
.modalmax50vh{
  max-height: 40vh;
  overflow: hidden auto;
}
.AddButtonInput{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.task-list-accordian.custom-accordian-main .accordion-header.py_0 .accordion-button{
  padding: 0px 0px !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before , .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
  border-top-color: var(--color-white-05-solid) !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  border-bottom-color: var(--black-op20) !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: var(--color-white-07) !important;
  border-radius: 500px !important;

}
.mapfull{
  margin-top: 40px;
}
.mapfull iframe{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.max-w50px{
  max-width: 50px;
}
.RegNewFlow .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
  opacity: 0.4;
}

.btn-with-default-cursor {
  cursor: default;
}
.CircularBar .CircularProgressbar {
  height: 60px;
  width: 60px;
  stroke-width: 4px;
}
.mapareabox.w270px{
  width: 270px;
}
.CircularBar .CircularProgressbar .CircularProgressbar-path{
    stroke: #000645;
}
.fblue{
  color: #000645;
}
.CircularProgressbar-text{
  display: none;
}
.circularInner{
  position: absolute;
  height: 40px;
  width: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  background-color: #ffffff;
  display: flex;
  border-radius: 500px;
}
.absolutetimebtn{
  position: absolute;
  top:0px;
  right: -50px;
}
.ms100px{
  margin-left: 100px;
}
.ms75px{
  margin-left: 75px;
}
.Proposalmappill{
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 110px;
  background-color: #1EBF61;
  color: #ffffff;
  font-size: 12px;
  padding: 6px 8px;
  z-index: 1;
  border-radius: 10px;
  font-weight: var(--fw-semibold);
  font-family: var(--title-family);
  text-align: center;

}
.solarbodybg{
  background-color: #EEF7F6;
}

.light-theme .glow-shadow .batteryswitch .form-check-input[type=checkbox]{
  background-color: #000000;
  /* border-color: #000000 !important; */
  box-shadow: none;
}
.light-theme .glow-shadow  .batteryswitch .form-switch .form-check-input:after{
  background-color: #ffffff;
}
.light-theme .glow-shadow .batteryswitch .form-switch .form-check-input:checked:after{
  background-color: var(--lemon-yellow);
}
.Maploader{
 position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height: 100%; */
  width: 500px;
  /* background: red; */
  z-index: 25;
  text-align: center;

}

/* messaging-dropdown-main start */
.messaging-footer-main{
  position: absolute;
  width: auto;
  bottom: 0;
  z-index: 999;
  left: auto;
  right: 15px;
}
.messaging-dropdown-main .accordion-collapse.collapse.show .accordion-body,
.messaging-dropdown-main .accordion-body{
  height: 45vh;
  transition: all 0.3s;
  overflow: auto;
}
.messaging-footer-main .messaging-dropdown-main{
  background-color: var(--color-white-03); 
}
.messaging-footer-main .messaging-dropdown-header{
  padding-right: 2px;
}
.messaging-footer-main .custom-accordian-main.with-right-arrow .messaging-dropdown-main .accordion-header{
  width: auto!important;
}
.messaging-footer-main .custom-accordian-main.with-right-arrow .messaging-dropdown-main .accordian-header-box{
  padding: 10px;
}
.gap10px{
  gap: 10px;
}
.light-theme .glow-shadow .messaging-dropdown-list-items:last-child{
  border-color: transparent!important;
  padding-bottom: 0 !important;
}
.messaging-dropdown-main .accordion-body.hide {
  transition: all 0.3s;
  height: 0!important;
}
/* messaging-dropdown-main end */
.gm-iv-container{
  display: none !important;
  
}
.space-select-box.bgbodyhover:hover{
  background-color: var(--body-bg);
}
.opacity-60.ohhoveropacity1:hover{
  opacity: 1;
}