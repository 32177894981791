.btn-sm-2{
    padding: 6px 8px;
    font-size: 12px;
}
.h32wauto , .comman_action_icon .action_icon.h32wauto{
    height: 32px;
    width: auto;
}
.comman_action_icon .action_icon.h32w32 , .h32w32 , .comment-input-box .send-arrow-btn{
    height: 32px;
    width: 32px;
    min-width: 32px;
}
.H32{
    height: 32px;
}
.multi_inner_wrapper.afternone .comman-image-box::after{
    display: none;
}
.multi_inner_wrapper .comman-image-box.bgwhite05{
    background-color: var(--color-white-05);
}
.uploadboxhw{
    height: 85px;
    width: 110px;
}
.opacity70{
    opacity: 0.6;
}
.remove-img.bg-blue{
    background-color: var(--base-body-color);
}
.mr10minus{
    margin-right: -10px;
}
.h64px{
    height: 64px;
}
.h54px{
    height: 54px;
}
.h60px{
    height: 60px;
}
.h46px{
    height: 46px;
}
.h32w100px{
    height: 32px;
    width: 100px;
}