/* Comman Variable  */
:root ,
:root.light-theme .fixed-left-panel,
:root.light-theme .popover
/* :root.light-theme .dropdown-menu */
{
  /*base variable*/
  --base-font: 14px;
  --base-color: var(--base-font-color);
  /*font family*/
  --base-family: "Poppins", sans-serif;
  --title-family: "Poppins", sans-serif;
  /*font weight*/
  --fw-thin: 100;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-extrabold: 800;
  --fw-black: 900;
  /*comman backgrounds color*/
  --left-panel-bg: #223444;
  /* --base-body-color: #16293a; */
  --base-body-color: #0f2a3c;
  /* --base-header-color: #1d3040; */
  --base-header-color: #1c3545;
  --base-modal-bg: #1c3545;
  --base-modal-header-color: #293b4a;
  --base-modal-backdrop-color: rgba(22, 41, 58, 90%);
  --bg-white-hex-03: #173141;
  --premium-bg-transparent: #1c3544;
  --global-search-bg-color:#1B3546;
  /* --tootltip-bg: #131c23; */
  /*green Tone*/
  --color-green: rgba(0, 255, 106, 1);
  --color-green-50: rgba(0, 255, 106, 0.5);
  --color-green-blue: #00fdd5;
  --color-blue: #11abf1;
  --color-green-blue-20: rgba(0, 253, 213, 0.2);
  --color-blue-2: rgba(3, 169, 244, 1);
  --color-red: #fb3b4d;
  --redbtn:#D42F0F;
  --color-warning: rgba(252, 161, 32, 1);
  /*White Tone*/
  /* --color-white: rgba(255, 255, 255, 1); */
  --base-font-color: rgba(255, 255, 255, 1);
  --white:#ffffff;
  --color-white-1: #263848;
  /* Opacity Tone */
  --color-white-03: rgba(255, 255, 255, 0.03);
  --color-white-03-solid: #193343;
  --color-white-05-solid: #2c4250;
  --color-white-05: rgba(255, 255, 255, 0.05);
  --black-001:rgba(0,0,0,0.01);
  --black-002:rgba(0,0,0,0.02);
  --color-white-10: rgba(255, 255, 255, 0.1);
  --color-white-40: rgba(255, 255, 255, 0.4);
  --color-white-10-solid: #394f5c;
  --color-white-20: rgba(255, 255, 255, 0.2);
  --color-white-50: rgba(255, 255, 255, 0.5);
  --color-white-60: rgba(255, 255, 255, 0.7);
  --color-white-80: rgba(255, 255, 255, 0.8);
  --color-white-30: rgba(255, 255, 255, 0.3);
  --color-white-07: rgba(255, 255, 255, 0.07);
  /* --color-white-001: rgba(255, 255, 255, 0.01); */
  --calednar-todo-border: #b3b3b3;
  --calendar-modal-color: #0c2434;
  --lock-slider-bg:#071F2F;
  --bg-white-03-solid: #173142;
  /* Solid Tone */
  /* --color-white-03: #1d3040;
  --color-white-001: #182b3c;
  --color-white-05: c;
  --color-white-07: #263848;
  --color-white-10: #2d3e4d;
  --color-white-20: #455461;
  --colot-white-40: #737f89;
  --color-white-80: #d0d4d8;
  --color-white-60: #a2a9b0;
  --color-white-30: #5b6975; */
  /*black Tone*/
  --color-black: rgba(0, 0, 0, 1);
  --color-black2: #0000004f;
  /*comman border tone */
  /* --comman-border: rgba(255, 255, 255, 0.1); */
  /*--color-border:rgba(255,255,255,0.10);  */
  --color-border: rgba(255, 255, 255, 0.1);
  --color-box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.1);
  --comman-box-shadow: 0px 0px 8px 3px rgb(0 0 0 / 10%);
  --color-box-shadow-dropdown: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  --loader-filter:none;
  --light-black:#1B1C20;
  --black70:#383A47;

  /*gradient*/
  --color-gradient: linear-gradient(
    135deg,
    var(--color-green) 0%,
    var(--color-green-blue) 100%
  );
  /*spacing Class*/
  --space-40: 40px;
  --space-20: 20px;
  --space-30: 30px;
  --space-35: 35px;
  --space-50: 50px;
  --space-5: 5px;
  --space-10: 10px;
  --space-12: 12px;
  --space-13: 13px;
  --space-5: 5px;
  --space-15: 15px;
  --space-24: 24px;
  --space-25: 25px;
  --space-60: 60px;
  --space-300: 300px;
  --scroll-bottom-padding: 70px;
  /*transition*/
  /* --transiton-3s: all ease-in-out 0.0s;
  --transiton-1s: all ease-in-out 0.0s; */
  /*Status Colors*/
  --inprogress-status: #d1d6da;
  /* ---------------------------------Component CSS variable---------------------- */
  /* popover */
  --comman-popover-bg: #263848;
  --popover-text-color: rgba(255, 255, 255, 1);
  --popover-border: var(--color-border);
  /*Tooptip */
  --tooltip-color: var(--base-font-color);
  --tooltip-bg: #222;
  --tooltip-shadow: none;
  --tooltip-fw: var(--fw-regular);
  --tooltip-after-arrow: #222;
  /* btn-primary */
  --btn-primary-bg: var(--color-gradient);
  --btn-primary-color: var(--color-black);
  /* --btn-secondary */
  --btn-secondary-color: var(--base-font-color);
  --btn-secondary-bg: var(--color-white-1);
  --btn-secondary-border: var(--color-white-05);
  --btn-secondary-on-hover: var(--color-white-10);
  /* comman-three-row */
  --three-row-border-color: transparent;
  --three-row-bg-color: var(--color-white-05);
  --three-row-text-color: var(--color-white-80);
  --three-row-bg-hover-color: var(--color-white-03);
  /* comman-dropdown */
  --dropdown-item-color: var(--base-font-color);
  --dropdown-active-bg-color: var(--color-white-05);
  --dropdown-bg-color: var(--calendar-modal-color);
  --dropdown-dorder-color: var(--color-white-10);
  --dropdown-text-color: var(--white-color-60);
  --dropdown-box-shadow-color: var(--color-box-shadow-dropdown);
  --dropdown-header-bg: var(--color-white-03);
  /* forms */
  --form-bg: #233b4b;
  /* comman-input-label */
  --comman-input-label-color: var(--color-white-60);
  /*  full modal box-shadow */
  --full-modal-box-shadow: var(--base-body-color);

  --comman-table-thead-bg: #163042;
  --comman-table-dorder: var(--color-white-07);
  --comman-table-tfoot-bg: #1d3648;
  --modal-open-focus-visibility: 0.6;
  --modal-overlay: rgba(22, 41, 58, 82%) 0px 0px 0px 5000px;
  /* #0000001c 0px 0px 1px 2px, rgb(0 0 0 / 40%) 0px 0px 0px 5000px */
  /*Custom Modal */
  --custom-modal-header-color:var(--color-white-03);
  /* --custom-modal-footer-color:; */
  --footer-action-icon-box-shadow: 0px 0px 5px 0px rgb(255 255 255 / 20%);
  --accordion-body-drop-shadow : 0px 0px 10px 0px rgba(28 53 68 / 10%);
  --task-list-card-border: rgb(15 42 60);
  --footer-brop-bg: linear-gradient(0deg, rgb(15 42 60 ) 28%, rgb(15 42 60 / 60%) 61%, rgb(15 42 60 / 55%) 68%, transparent);
  /* linear-gradient(0deg, rgb(15 42 60 ) 15%, rgb(15 42 60 / 50%) 61%, rgb(15 42 60 / 40%) 41%, transparent); */
  --badge-inner-bg:#1d3545;
  --calendar-box-bg: #0C2434;
  --autocomplete-bg: #0f2a3c;
  /* --comman-bg-overlay :linear-gradient(270deg, #1d3545 0%, rgb(29 53 69 / 80%) 89.06%, rgba(23, 49, 66, 0.00) 100%); */
  --comman-bg-overlay:linear-gradient(279deg, #1d3545 0%, rgb(29 53 69) 89.06%, rgb(23 49 66 / 43%) 100%);
}
/*EmojiPickerReact*/
.EmojiPickerReact{
  --epr-bg-color :var(--dropdown-bg-color);
  --epr-picker-border-color : var(--dropdown-dorder-color);
  --epr-search-input-bg-color :var(--color-white-05);
  --epr-search-border-color : var(--color-white-05);
  --epr-category-label-bg-color : var(--color-white-03-solid);
  --epr-category-label-text-color : var(--base-font-color);
  --epr-emoji-hover-color : var(--color-white-20);
  --epr-search-input-text-color: var(--color-white);
  /* --epr-category-icon-active-color : var(--base-font-color); */
}
.input-label {
  color: var(--comman-input-label-color);
  font-family: var(--title-family);
  /* margin-bottom: 0.5rem; */
  font-size: 12px;
  margin-bottom: 5px;
}

body {
  font-size: var(--base-font);
  color: var(--base-color);
  background-color: var(--base-body-color);
  font-family: var(--base-family);
  /* padding-top: 70px; */
  padding-top: 60px;
  padding-left: 80px;
  /* padding-right: 10px; */
  padding-right: 10px;
  padding-bottom: 90px;
  /* transition: var(--transiton-3s); */
  overflow: hidden;
}

/* .body-toggle {
  padding-left: 360px !important;
} */
body a {
  /* transition: var(--transiton-3s); */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Transform Rotate comman class */
.rotateminus180 {
  transform: rotate(-180deg);
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a,
label {
  display: inline-block;
}
.p30px{
  padding: 30px;
}
.h250px{
  height: 250px;
}
a {
  transition: all 0.2s ease-in-out;
  color: var(--base-font-color);
  text-decoration: none;
  cursor: pointer;
}
.badgeinnerbg{
  background-color: var(--badge-inner-bg);
}
a:hover {
  color: var(--base-font-color);
  transition: var(--transiton-3s);
}

a:focus {
  color: var(--base-font-color);
  transition: var(--transiton-3s);
}

.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
    color: var(--color-white-30);
    /* font-size: 14px; */
    font-family: var(--title-family);
}
::-webkit-input-placeholder { 
  color: var(--color-white-30);
}
::-moz-placeholder {
 color: var(--color-white-30);
}
:-ms-input-placeholder {
 color: var(--color-white-30);
}
:-moz-placeholder {
 color: var(--color-white-30);
}
input,
textarea {
  width: 100%;
  color: var(--base-font-color);
}
.fwmedium{
  font-weight: var(--fw-medium);
}
.borderblack10{
  border:1px solid (0,0,0,0.10) !important;
}
/* autoComplete-off-box-shadow */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
    -webkit-background-clip: text;
    caret-color: var(--base-font-color);
    -webkit-text-fill-color: var(--base-font-color);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--bg-white-03-solid);
}
input.autoComplete-off-box-shadow:-webkit-autofill,
input.autoComplete-off-box-shadow:-webkit-autofill:hover, 
input.autoComplete-off-box-shadow:-webkit-autofill:focus, 
input.autoComplete-off-box-shadow:-webkit-autofill:active{
    -webkit-background-clip: text;
    caret-color: var(--color-green);
    -webkit-text-fill-color: var(--color-green);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--autocomplete-bg);
}
a img {
  border: 0;
  vertical-align: middle;
}
.width25{
  width: calc(25% - 10px);
}
.w250px{
  width: 250px;
}
.w110px{
  width: 110px;
}
.h50px{
  height: 50px;
}
:focus {
  outline: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}
.z-index-15{
  z-index: 15;
}
.z-index-9{
  z-index: 9;
}
.z-index-25{
  z-index: 25;
}
#commanCanvasImageBody{
  /* z-index: 1056; */
  z-index: 23;
}
ul {
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ol li.with-listing-count {
  list-style: inherit;
}

/* Tag display none */
.br-d-none br {
  display: none;
}
.f-black{
  color: var(--color-black);
}
.f-white{
  color: var(--white-color);
}
.f-white-imp{
  color: var(--white-color)!important;
}
.p-10-15 , .task-sub-list.p-10-15 {
  padding: 10px 15px;
}
.max-w35per{
  max-width: 35%;
}
.ps-30{
  padding-left: 30px;
}
.ps-45{
  padding-left: 45px;
}
.pt-60{
  padding-top: 60px;
}
.ps15px{
  padding-left: 15px;
}
.p-5px{
  padding: 5px;
}
.ps10px{
  padding-left: 10px;
}
.pb15px{
  padding-bottom: 15px;
}
.p-20 {
  padding: 20px;
}
.with_margin_minus_10px  {
  margin-right: -10px;
}
.multi_inner_wrapper .comman-image-box.with_ml_minus_10px:first-child{
  margin-left: -10px;
}
/* login */
.logo-light {
  display: none;
}

.logo-dark {
  display: block;
}

/* blur */
.blur-10 {
  filter: blur(1px);
  opacity: 0.5;
}

.blur-50 {
}

/* z-index class */
.z-index-0imp {
  z-index: 0!important;
}
.z-index-1imp {
  z-index: 1 !important;
}

.z-index-2imp {
  z-index: 2 !important;
}

.z-index-3imp {
  z-index: 3 !important;
}
.z-index-4imp {
  z-index: 4 !important;
}
.z-index-5imp {
  z-index: 5 !important;
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

.z-index-3 {
  position: relative;
  z-index: 3;
}

.z-index-4 {
  position: relative;
  z-index: 4;
}

.z-index-5 {
  position: relative;
  z-index: 5;
}

.z-index-6 {
  position: relative;
  z-index: 6;
}

.z-index-7 {
  position: relative;
  z-index: 7;
}

.z-index-8 {
  position: relative;
  z-index: 8;
}

.z-index-9 {
  position: relative;
  z-index: 9;
}

.z-index-minus-1 {
  position: relative;
  z-index: -1;
}

body.comman-oerlay-class {
}

.blocker-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
}

li {
  list-style-type: none;
}

.comman-body-wrapper {
  height: calc(100vh - 150px);
  overflow: hidden;
}

.comman-body-wrapper.modified-height {
  height: calc(100vh - 80px);
  /* position: relative; */
}
.comman-body-wrapper.modified-height.h135px {
  height: calc(100vh - 135px);
  /* position: relative; */
}
.comman-body-wrapper.modified-height .comman-main-box{
  padding-bottom: var(--scroll-bottom-padding);
}
.comman-body-wrapper.modified-height .comman-main-box.padding-bottom-100px{
  padding-bottom: 100px;
}
.padding-bottom-60px{
  padding-bottom: 60px;
}
.padding-bottom-60pximp{
  padding-bottom: 60px !important ;
}
.padding-bottom-80px{
  padding-bottom: 80px;
}
.comman-oerlay-class:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--base-body-color);
  opacity: 1;
  backdrop-filter: blur(100px);
  z-index: 10;
}

.form-control {
  background-color: var(--color-white-03);
  border-radius: 3px;
  border: var(--color-white-03) 1px solid;
  font-size: var(--base-font);
  font-family: var(--base-family);
  color: var(--base-font-color);
  height: auto;
  /* padding: 5px 10px; */
  padding: 15px;
  /* box-shadow: 0px 2px 10px 0px rgba(27, 53, 70, 0.05); */

}
.form-control.form-control-h{
  padding: 18px;
}
.select-half-separator .select-half-separator-main.h-59px,
.custom-select-menu.h-59px .react-select__control{
  height: 59px!important;
}

.c-input-box .invalid-feedback {
  position: absolute;
  bottom: -1px;
  width: auto;
}

.c-input-box .valid-feedback {
  position: absolute;
  bottom: -1px;
  width: auto;
}

.form-control.h-35 {
  height: 35px;
}

select.form-control {
  background-image: url("../images/ic-caret-down-fill-white-50.svg");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 14px;
}
.form-control.with-success{
 color: var(--color-green);
 border:var(--color-green) 1px solid;
}
.form-control.with-success:hover,
.form-control.with-success:focus{
  color: var(--color-green);
  border:var(--color-green) 1px solid;
 }
.form-control.with_bg {
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 20px;
}

.form-control.file-upload {
  padding: 25px;
  border: 2px dashed var(--color-white-10);
  background-color: transparent;
  z-index: 1;
}

.form-control.file-upload.with-bg {
  background-color: var(--color-white-03);
}

.form-control.file-upload .file-upload-bg-abs {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--form-bg);
  z-index: -1;
  cursor: pointer;
}
.form-control.file-upload:hover .file-upload-bg-abs{
  background-color: var(--color-white-05);
}
.form-control.file-upload input:disabled ~ .file-upload-bg-abs {
  opacity: 0.6;
  z-index: 1;
}

.form-control.file-upload.p-10 {
  padding: 10px;
}

select.form-control option {
  background-color: var(--color-black);
  color: var(--base-font-color);
}

.form-control.height-45 {
  height: 45px;
}

.form-control.transparent {
  background-color: transparent;
  border: 0;
}

.form-control.transparent:disabled {
  background-color: transparent;
}

.form-control.transparent:focus {
  background-color: transparent;
}

.form-control:focus {
  color: var(--base-font-color);
  background-color: var(--color-white-05);
  /* border-color: var(--color-white-10); */
  border-color: transparent;
  box-shadow: none;
}

.form-control.bg-left-panel:focus {
  background-color: var(--left-panel-bg);
}

.checklist-textarea {
  max-height: 100px;
  overflow: hidden auto;
  transition: var(--transiton-3s);
}

.checklist-textarea {
  max-height: 100px;
  overflow: hidden auto;
  transition: var(--transiton-3s);
}

.priority-checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priority-checkbox.span-13 .check_box .check_span_img {
  height: 13px;
  width: 13px;
}
.priority-checkbox.span-13 .check_box .check_span_img.HW18 {
  height: 18px;
  width: 18px;
}

.priority-checkbox .check_box .check_span_img {
  height: 15px;
  width: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.priority-checkbox input:disabled ~ .check_box {
  opacity: 0.5;
}

.priority-checkbox .check_box .check_span_img.active {
  display: none;
}

.priority-checkbox.fire-icon.g-drive .check_box .check_span_img.active {
  display: block !important;
}

.priority-checkbox input:checked ~ .check_box .check_span_img.active {
  display: block!important;
}

.priority-checkbox input:checked ~ .check_box .check_span_img {
  display: none!important;
}

.active_border {
  border: var(--color-green) 1px solid;
}

.multi_image_wrapper.with-z-index {
  position: relative;
  z-index: 6;
}

/*box-shadow*/
.box-shadow-1 {
  box-shadow: var(--color-box-shadow);
}

.box-shadow-2 {
  box-shadow: var(--color-box-shadow-dropdown);
}

.box-shadow-3 {
  box-shadow: var(--comman-box-shadow);
}

.modal-overlay-box-shadow{
  box-shadow: var(--modal-overlay) !important;
}

/*Overflow*/
.overflow-hiiden-auto {
  overflow: hidden auto;
}
.overflow-auto-hiiden {
  overflow: auto hidden;
}

.overflow-hiiden-web {
  overflow: hidden;
}

/* Comman font-family  */
.body-fonts {
  font-family: var(--base-family);
}

.title-fonts {
  font-family: var(--title-family);
}

.on-hover-color-green {
  transition: var(--transiton-3s);
}

.on-hover-color-green:hover {
  color: var(--color-green);
  transition: var(--transiton-3s);
}

.text_wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.main-container-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.comman-main-box {
  flex-grow: 1;
  overflow: hidden auto;
  padding: 0px;
  margin-top: 10px;
  /* padding-right: 10px; */
}

.letter_spacing05 {
  letter-spacing: 0.5px;
}
.letter_spacing2 {
  letter-spacing: 2px;
}
/* .letter_spacing6px{
  letter-spacing: 6px;
} */
/* Comman utilities  */
.comman-round-box {
  position: relative;
}

.comman-round-box.with-top-overlay::before{
  content: "";
  position: absolute;
  top: 5px;
  right: 4px;
  height: 25px;
  display: none;
  width: 25px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  z-index: 1;
  border-radius: 3px;
}
.comman-round-box.with-top-overlay:hover::before{
  display: block;
}
.comman-round-box .absolute-download-icon{
  position: absolute;
  top: 5px;
  right: 10px;
  display:none;
  z-index: 5;
}
.comman-round-box.with-top-overlay:hover .absolute-download-icon{
  display:block;
}
.comman-round-box .comman-bg-img:hover .absolute-download-icon{
  display: block;
}
.comman-round-box.with-overflow-inherit {
  overflow: inherit;
}

.comman-round-box .comman-bg-img.with-padding-top {
  padding-top: 56.25%;
}

.bg-style-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-size-contain{
  background-size: contain;
}
.background-position-y-center{
  background-position-y: center;
}
.bg-style-cover-50 {
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}
.bg-style-cover-60 {
  background-size: 60%;
}

.bg-style-cover.cover-40 {
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
}

.bg-style-contain{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.comman-round-box.on-active-badge:before {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: var(--color-green);
  position: absolute;
  right: 0px;
  top: 2px;
  border: 4px solid var(--base-body-color);
}

.comman-round-box .remove-img {
  position: absolute;
  top: -5px;
  right: -8px;
  border-radius: 300px;
  border: 1px solid var(--color-white-07);
  height: 15px;
  width: 15px;
  background-color: var(--color-white-1);
  display: flex;
  cursor: pointer;
}
.comman-round-box .remove-img.h25w25{
  width: 25px;
  height: 25px;
}
.comman-round-box .remove-img.large.abspotion{
  right: 10%;
}
.comman-round-box .remove-img.large.abspotion.square-logo{
  right: -7% !important;
  top: -7% !important;
}
.comman-round-box .remove-img.large {
  top: 0%;
  right: -10%;
  height: 30px;
  width: 30px;
}
.comman-round-box .remove-img.large.chatgrp{
    top:-10px;
    right:-10px;
}
.comman-round-box.bg-border-transparet {
  border: 1px solid transparent;
}

/* Comman borders  */
.border-bottom {
  border-bottom: 1px solid var(--color-white-05) !important;
}
.border-bottom-10 {
  border-bottom: 1px solid var(--color-white-10) !important;
}

.border-top {
  border-top: 1px solid var(--color-white-05) !important;
}

.border-left {
  border-left: 1px solid var(--color-white-05) !important;
}
.border-left-10 {
  border-left: 1px solid var(--color-white-10) !important;
}

.border-left-0 {
  border-left: 0;
}
.border-base-header-color{
  border-color: var(--base-header-color) !important;
}
.border-right {
  border-right: 1px solid var(--color-white-05) !important;
}

.border-bottom-color-border {
  border-bottom: 1px solid var(--color-border) !important;
}

.border-transparent {
  border: 1px solid transparent;
}

.border {
  /* border: 1px solid var(--color-white-10) !important; */
  border:1px solid  rgb(0 0 0 / 10%) !important;
}
.border-bottom-dashed {
  border-bottom: 1px dashed var(--color-white-10);
}
.border-start {
  border-left: 1px solid var(--color-white-10) !important;
}
.border-end{
  border-right: 1px solid var(--color-white-10) !important;
}
.white-border-box {
  border: 1px solid var(--color-white-10);
  border-radius: 3px;
  padding: 2px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transiton-3s);
}

.white-border-box:hover {
  background-color: var(--color-white-03);
}

.white-border-box.w-70px , .w-70px {
  width: 70px;
}

.white-border-box.w-150px,
.w-150px {
  width: 150px;
}

.white-border-box.min-w-150px {
  min-width: 150px;
}

.min-width-80 {
  min-width: 80px;
}

.min-width-125 {
  min-width: 125px;
}

.min-width-60 {
  min-width: 60px;
}

.white-border-box.h30-w30 {
  height: var(--space-30);
  width: var(--space-30);
  padding: 0px;
}

.white-border-box.datepicker-width-75px
  .react-datepicker__input-container
  input {
  width: 75px;
}

.react-datepicker-full-height .react-datepicker__input-container , .react-datepicker__input-container{
  height: 100%;
}
.border-dashed-2px {
  border: 2px var(--color-white-10) dashed;
}

.border-dashed {
  border: 1px var(--color-white-10) dashed;
}

a.border-dashed:hover {
  background-color: var(--color-white-10);
  transition: var(--transiton-3s);
}

.color-white {
  color: var(--base-font-color);
}

.color-orange {
  color: var(--color-warning);
}

.color-orangeimp {
  color: var(--color-warning)!important;
}

.color-white-80 {
  color: var(--color-white-80);
}

.color-red {
  color: var(--color-red);
}

.base-body-color {
  background-color: var(--base-body-color);
}

.color-white-60 {
  color: var(--color-white-60);
}
.color-white-70 {
  color: var(--color-white-70);
}
.color-blue {
  color: var(--color-blue);
}

.color-green {
  color: var(--color-green);
}

/* Comman Classes  */
.h40w40 {
  height: var(--space-40);
  width: var(--space-40);
}

.h35w35 {
  height: var(--space-35);
  width: var(--space-35);
}
.h38w38 , .comman_action_icon .action_icon.h38w38 {
  height: 38px;
  width: 38px;
}
.h45w45 {
  height: 45px;
  width: 45px;
}
.h42w42 , .comman_action_icon .action_icon.h42w42 {
  height: 42px;;
  width: 42px;;
}
.h30w30 {
  height: var(--space-30);
  width: var(--space-30);
}
.h16w16 {
  height:16px;
  width: 16px;
}
.h38w38 {
  height: 38px;
  width: 38px;
}
.width30 {
  width: 30px;
}
.width150 {
  width: 150px;
}

.h50w50 {
  height: var(--space-50);
  width: var(--space-50);
}
.h52w52 {
  height: 52px;
  width: 52px;
}
.h130w130 {
  height: 130px;
  width: 130px;
}

.h100w100 {
  width: 100px;
  height: 100px;
}
.h95w95 {
  width: 95px;
  height: 95px;
}
.h85w115 {
  width: 115px;
  height: 85px;
}
.h85px{
  height: 85px;
}
.h60w60 {
  height: var(--space-60);
  width: var(--space-60);
}

.h65w65 {
  height: 65px;
  width: 65px;
}

.h70w70 {
  height: 70px;
  width: 70px;
}
.h80w80{
  height: 80px;
  width: 80px;
}
.h75w75 {
  height: 75px;
  width: 75px;
}
.h62w62 {
  height: 62px;
  width: 62px;
}
.h180w180 {
  height: 180px;
  width: 180px;
}
.h120w120 {
  height: 120px;
  width: 120px;
}

.w65 {
  width: 65px;
}

.w50 {
  width: 50px;
}

.h25w25 {
  height: var(--space-25);
  width: var(--space-25);
}

.h20w20 {
  height: var(--space-20);
  width: var(--space-20);
}

.h15w15 {
  height: var(--space-15);
  width: var(--space-15);
}
.h80percent{
  height: 80%;
}
/* Comman  bg - backgrounds */
.bg-left-panel {
  background-color: var(--left-panel-bg);
}
.bg-blue{
  background-color: var(--color-blue);
}
.comman-table-thead-bg{
  background-color: var(--comman-table-thead-bg);
}
.bg-white-03 {
  background-color: var(--color-white-03);
}
.bg-white-hex-03{
  background-color: var(--bg-white-hex-03);
}
.bg-white-03-solid {
  background-color: var(--color-white-03-solid);
} 
.bg-white-03-solid-imp {
  background-color: var(--color-white-03-solid)!important;
} 
.bg-white-10-solid-imp {
  background-color: var(--color-white-10-solid)!important;
} 
.bg-white-05 {
  background-color: var(--color-white-05);
}

.bg-red {
  background-color: var(--color-red);
}
.bg-green {
  background-color: var(--color-green);
}
.bg-white-10 {
  background-color: var(--color-white-10);
}

.bg-gradient-green {
  background: var(--color-gradient);
}

.bg-base-header-color {
  background: var(--base-header-color) !important;
}
.calendar-modal-color{
  background-color: var( --calendar-modal-color);
}
a.bg-white-05 {
  background-color: var(--color-white-05);
}

a.bg-white-05:hover {
  background-color: var(--color-white-07);
  transition: var(--transiton-3s);
}

.on-hover-bg-white-05.bg-white-05:hover {
  background-color: var(--color-white-07);
  transition: var(--transiton-3s);
}

a.on-hover-bg-10:hover {
  background-color: var(--color-white-10);
  transition: var(--transiton-3s);
}

textarea {
  resize: none;
}

img {
  max-width: inherit;
  max-height: inherit;
  min-height: inherit;
  min-width: inherit;
  transition: var(--transiton-3s);
}
.minheight200px{
  min-height: 200px;
}
/* comman img width */
.img-width-16 {
  width: 16px;
}

.img-width-8 {
  width: 8px;
}

.img-width-10 {
  width: 10px;
}

.img-width-14 {
  width: 14px;
}

.img-width-13 {
  width: 13px;
}

.img-width-18 {
  width: 18px;
}

.img-width-20 {
  width: 20px;
}

.img-width-24 {
  width: var(--space-24);
}

/* opecity */
.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}
.opacity30 {
  opacity: 0.3;
}
.opacity-80 {
  opacity: 0.8;
}
.CustomFullCalendar .CalendarOverlay{
  opacity: 0.3;
}
.CustomFullCalendar .CalendarOverlay.daylist:hover{
  opacity: 1;
}
.CustomFullCalendar .CalendarOverlay.MonthEventbox:hover{
  opacity:1;
}
/* .CustomFullCalendar .CalendarOverlay.WeekDetailbox:hover{
  opacity: 1;
} */
/*line height*/
.line-height-0 {
  line-height: 0;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}
.line-height-1-8 {
  line-height: 1.8;
}

/* comman font-size */
.c-font.f-10 {
  font-size: var(--space-10);
}
.c-font.f-9{
  font-size: 9px;
}
.c-font.f-11 {
  font-size: 11px;
}

.c-font.f-11imp {
  font-size: 11px!important;
}
.c-font.f-12 {
  font-size: var(--space-12);
}

.c-font.f-13 {
  font-size: 13px;
}

.c-font.f-14 {
  font-size: var(--base-font);
}
.c-font.f-14imp {
  font-size: var(--base-font)!important;
}

.c-font.f-15 {
  font-size: 15px;
}

.c-font.f-16 {
  font-size: 15px;
}

.c-font.f-18 {
  font-size: 18px;
}

.c-font.f-20 {
  font-size: 20px;
}

.c-font.f-22 {
  font-size: 22px;
}

.c-font.f-24 {
  font-size: 24px;
}
.c-font.f-28 {
  font-size: 28px;
}
.c-font.f-30 {
  font-size: 30px;
}
.pt-70px{
  padding-top: 70px;
}
.c-font.f-32 {
  font-size: 32px;
}

/*radius*/
.radius_3 {
  border-radius: 3px;
}

.radius_5 {
  border-radius: 3px;
  /* border-radius: 5px; */
}
.radius-top-0,
.custom-select-menu.radius-top-0 .react-select__control {
  border-radius: 0px 0px 3px 3px!important;
}
.radius-bottom-0,
.custom-select-menu.radius-bottom-0 .react-select__control {
  border-radius: 3px 3px 0px 0px!important;
}
.radius-left-0,
.custom-select-menu.radius-left-0 .react-select__control {
  border-radius: 0px 3px 3px 0px!important;
}
.radius-rignt-0,
.custom-select-menu.radius-rignt-0 .react-select__control {
  border-radius: 3px 0px 0px 3px!important;
}
.custom-select-menu{
  position: relative;
  z-index: 2;
}
.custom-select-menu input{
  caret-color: transparent; 
}
.css-1fdsijx-ValueContainer,
.custom-select-menu .react-select__indicators{
  z-index: 2;
}
.custom-select-menu .react-select__indicators, .custom-select-menu .react-select__dropdown-indicator{
  /* opacity: 0; */
  /* background-image: none!important; */
}
/* .custom-select-menu:after{
  content: '';
  position: absolute;
  background-image: url("../images/caret-down-white.svg");
  top: 50%;
  right: -4px;
  background-color: var(--color-white-05);
  border-radius: 2px;
  border: 1px solid transparent;
  width: 32px;
  height: 32px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  z-index: 1;
} */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* calc CSS */
.maxw100minus180 {
  max-width: calc(100% - 180px);
}
.max-width-160px{
  max-width: 160px;
}
.width-160px{
  width: 160px;
}
.w100minus100 {
  width: calc(100% - 100px);
}

.w100minus120 {
  width: calc(100% - 120px);
}

.w100minus20 {
  width: calc(100% - 20px);
}
.w100minus25 {
  width: calc(100% - 25px);
}

.w100minus10 {
  width: calc(100% - 10px);
}

.w100minus40 {
  width: calc(100% - 40px);
}

.w100minus50 {
  width: calc(100% - 50px);
}

.w100minus35 {
  width: calc(100% - 35px);
}

.w100minus30 {
  width: calc(100% - 30px) !important;
}

.w100minus70 {
  width: calc(100% - 70px);
}

.w100minus85 {
  width: calc(100% - 85px);
}

.w100minus150 {
  width: calc(100% - 150px);
}
.m_w50 {
  max-width: 50px;
}

.m-w150 {
  max-width: 150px;
}

.m-w40 {
  max-width: 40%;
}
.w_260px{
  width: 260px;
}
.m-w100px {
  max-width: 100px;
}
.m-w80px {
  max-width: 80px;
}
.min-w-100imp {
  min-width: 100%!important;
}
.m-w110px {
  max-width: 110px;
}
.m-w180px {
  max-width: 180px;
}
.max-w400 {
  max-width: 400px;
}

.width-w400 {
  width: 400px;
}
.width-w140 {
  width: 140px;
}
.h-w-100{
  height: 100px;
  width: 100px;
}
.w80h55{
  width: 80px;
  height: 55px;
}
.w65h50{
  height: 50px;
  width: 65px;
}
.w100px , .h32wauto.w100px{
  width: 100px;
}
.h-200px{
  height: 200px;
}
.min-h-230px{
  min-height: 230px;
}
.min-h-32px{
  min-height: 32px;
}
.max-w300 {
  max-width: var(--space-300);
}
.max-w320 {
  max-width:320px;
}
.max-w120px{
  max-width: 120px;
}
.min-w320 {
  min-width:320px;
}
.h100minus20px{
  height: calc(100% - 20px);
}
.h100minus80px{
  height: calc(100% - 80px);
}
.h100minus50px{
  height: calc(100% - 50px);
}
.h100minus70px{
  height: calc(100% - 70px);
}
.custom-modal-type-of-space.custom-modal-style{
  height: calc(100% - 166px)!important;
  right: 7px!important;
}
.custom-modal-type-of-space.custom-modal-style .modal-content{
  height: 100%;
}
.use_cases_listing_ol li{
 list-style-type:  auto;
}
.ps0imp{
  padding-left: 0 !important;
}
/*comman spacing*/
.w-85 {
  width: 85%;
}
.w400px{
  width: 400px!important;
}
.w_400px{
  width: 400px;
}
.w-14 {
  width: 14%;
}

.w-33 {
  width: 33.33%;
}
.w-70{
  width: 70% !important;
}
.w-30{
  width: 30% !important;
}

.w_70px{
  width: 70px;
}
.w_170px{
  width: 170px;
}
.w_100px{
  width: 100px;
}
.w_270px{
  width: 270px;
}
.min-w-75px{
  min-width: 75px;
}
.ml-10 {
  margin-left: var(--space-10);
}
.mt-10 {
  margin-top: var(--space-10);
}
.mt40px{
  margin-top: 40px;
}
.mt-15px {
  margin-top: 15px;
}
.mt-5px {
  margin-top: 5px;
}
.mb-10 {
  margin-bottom: var(--space-10);
}
.pt-30px {
  padding-top: var(--space-30);
}
.pt-40px {
  padding-top: var(--space-40);
}
.pe-30px {
  padding-right: var(--space-30);
}
.pt-20px {
  padding-top: var(--space-20);
}
.pt-10px {
  padding-top: var(--space-10);
}
.py-10px{
  padding-top: var(--space-10);
  padding-bottom: var(--space-10);
}
.pb-5px{
  padding-bottom: 5px;
}
.ps-40px{
  padding-left: 40px;
}
.pe-40px{
  padding-right: 40px;
}
.pb-10px {
  padding-bottom: var(--space-10);
}
.mb-15px {
  margin-bottom:15px;
}
.margin25px_0{
  margin: 25px 0px;
}
.marginTop25px{
  margin-top: 25px;
}
.mlminus40 {
  margin-left: -40px;
}

.mlminus10 {
  margin-left: -10px;
}
.mrminus4 {
  margin-right: -4px;
}
.mlminus20 {
  margin-left: -20px;
}

.mlminus30 {
  margin-left: -30px;
}

.mlminus75 {
  margin-left: -76px;
}

.mlminus60 {
  margin-left: -60px;
  transition: var(--transiton-3s);
}

.mtminus5 {
  margin-top: -5px;
  transition: var(--transiton-3s);
}

.mxminus15 {
  margin-left: -15px;
  margin-right: -15px;
  transition: var(--transiton-3s);
}

.mr-10 {
  margin-right: var(--space-10);
}
.mb-20 {
  margin-bottom: var(--space-20);
}

.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}

.pt-15 {
  padding-top: 15px;
}
.me5px{
  margin-right: 5px;
}
/*comman btn */
.btn {
  border-radius: 3px;
  font-family: var(--base-family);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: var(--fw-semibold) !important;
}

.btn:active {
  border: 1px solid;
  border-color: transparent;
}

.btn.btn-sm {
  font-size: var(--space-12);
}

.btn.btn-smaller {
  font-size: 11px;
  padding: 1px 5px;
}
.btn-sm, .btn-group-sm > .btn{
  --bs-btn-padding-x: 1rem;
}
.btn-warning {
  /* background: var(--color-gradient); */
  background: var(--color-warning)!important;
  color: var(--btn-primary-color);
  border: 1px solid transparent !important;
}
.btn-primary {
  /* background: var(--color-gradient); */
  background: #000000;
  color: #ffffff;
  font-weight: var(--fw-semibold);
  border: 1px solid transparent !important;
}
.btn-outline-danger svg.c-icons[fill]{
  color: var(--color-red);
}
.btn-outline-danger:hover svg.c-icons[fill]{
  color: var(--base-font-color);
}
.c-icons.stroke-width-4px path
,.c-icons.stroke-width-4px line
,.c-icons.stroke-width-4px circle
,.c-icons.stroke-width-4px polyline
,.c-icons.stroke-width-4px rect
{
  stroke-width: 4px;
}
.c-icons.stroke-width-5px path
,.c-icons.stroke-width-5px line
,.c-icons.stroke-width-5px circle
,.c-icons.stroke-width-5px polyline
,.c-icons.stroke-width-5px rect
{
  stroke-width: 5px;
}
.c-icons.stroke-width-06px path
,.c-icons.stroke-width-06px line
,.c-icons.stroke-width-06px circle
,.c-icons.stroke-width-06px polyline
,.c-icons.stroke-width-06px rect
{
  stroke-width: 0.6px;
}
.c-icons.stroke-width-1px path
,.c-icons.stroke-width-1px line
,.c-icons.stroke-width-1px circle
,.c-icons.stroke-width-1px polyline
,.c-icons.stroke-width-1px rect
{
  stroke-width: 1px;
}
.c-icons.stroke-width-3px path
,.c-icons.stroke-width-3px line
,.c-icons.stroke-width-3px circle
,.c-icons.stroke-width-3px polyline
,.c-icons.stroke-width-3px rect
{
  stroke-width: 3px;
}
.bg-silder-btn {
  background-color: var(--color-white-03-solid);
  /* backdrop-filter: blur(5px); */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); */
}

.btn.btn-primary:disabled,
.btn.btn-primary.disabled,
fieldset:disabled .btn {
  background: var(--btn-primary-color);
  color: #ffffff;
  /* color: var(--btn-primary-color); */
  border: 1px solid transparent !important;
}

.btn.btn-secondary:disabled,
.btn.btn-secondary.disabled,
fieldset:disabled .btn {
  background: transparent;
  color: var(--btn-secondary-color);
  border: 1px solid var(--color-white-10);
}

.btn-secondary {
  background: transparent;
  color: var(--btn-secondary-color);
  border: 1px solid var(--color-white-10);
}
.btn-warning{
  background:var(--color-warning);
  border: 1px solid var(--color-warning);
}
/* .btn-warning:hover{
  background-color: transparent;
} */

.w_100px
.btn-secondary:hover {
  color: var(--btn-secondary-color);
  background-color: var(--btn-secondary-on-hover);
}

.btn-white-10 {
  background: var(--btn-secondary-bg);
  color: var(--btn-secondary-color);
  border: 1px solid var(--btn-secondary-border);
}

.btn-white-10:hover {
  background: var(--color-white-10);
  border: 1px solid var(--color-white-10);
}

.btn-white-10:focus {
  background: var(--color-white-10);
  border: 1px solid var(--color-white-10);
}

.btn-white-10:active {
  background: var(--color-white-10);
  border: 1px solid var(--color-white-10);
}

.btn-white-05 {
  background: var(--color-white-05);
  color: var(--base-font-color);
  border: 1px solid var(--color-white-05);
}

.btn-white-05.btn.show {
  background: var(--color-white-05);
  color: var(--base-font-color);
  border: 1px solid var(--color-white-05);
}
.btn-white-05:hover {
  border: 1px solid var(--color-white-10);
  transition: var(--transiton-3s);
  background-color: var(--color-white-05);
}

.btn-white-05:focus {
  border: 1px solid var(--color-white-10);
  transition: var(--transiton-3s);
  background-color: var(--color-white-05);
}

.btn-white-03 {
  background: var(--color-white-03);
  color: var(--base-font-color);
  border: 1px solid transparent;
}
.btn-white-03.view-more-btn{
  background: transparent;
  border-radius: 3px;
}
.task-list-accordian .btn-white-03.view-more-btn{
  margin-top: 2px;
}
.btn-white-03.view-more-btn:hover{
  background: var(--color-white-03);
}
.task-list-accordian .badge,
.offcanvas.offcanvas-end .task-setting-section .badge{
  font-size: 12px!important;
  display: flex;
  align-items: center;
  height: 32px;
}
.btn.btn-white-03:hover {
  border: 1px solid transparent;
  transition: var(--transiton-3s);
  background-color: var(--color-white-05);
}

.btn.btn-white-03:focus {
  border: 1px solid transparent;
  transition: var(--transiton-3s);
  background-color: var(--color-white-05);
}
.btn:focus-visible {
  outline: 0;
}
.btn.btn-white-05:hover {
  border: 1px solid var(--color-white-05);
  transition: var(--transiton-3s);
  background-color: var(--color-white-05);
}

.btn-white-05:focus {
  border: 1px solid var(--color-white-05);
  transition: var(--transiton-3s);
  background-color: var(--color-white-05);
}

.btn:active:focus {
  box-shadow: none !important;
}

.btn.btn-primary:focus {
  outline: none;
  box-shadow: none;
  color: initial;
  background-color: var(--color-black);
  border-color: transparent !important;
}

.btn.btn-secondary:focus {
  outline: none;
  box-shadow: none;
  color: var(--btn-secondary-color);
  background-color: var(--btn-secondary-bg);
  border: 1px solid var(--color-white-10);
}

.btn.btn-secondary:focus:active {
  background: transparent;
  color: var(--btn-secondary-color);
  border: 1px solid var(--color-white-10);
}

.btn.btn-primary:hover {
  color: var(--btn-primary-color);
  background-color: inherit;
  border: 1px solid !important;
  border-color: transparent !important;
  transition: var(--transiton-3s);
}

.view-more-btn.transparent {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--base-color);
}

.view-more-btn.transparent:hover {
  background-color: transparent;
  border: 1px solid transparent;
}

.view-more-btn.transparent:focus {
  background-color: transparent;
  border: 1px solid transparent;
}

.c-dots {
  height: var(--space-5);
  width: var(--space-5);
  display: inline-block;
  border-radius: 0 !important;
}

.c-dots.c-7 {
  height: 7px;
  width: 7px;
}
.c-dots.c-8 {
  height: 8px;
  width: 8px;
}

.c-dots.c-10 {
  height: 10px;
  width: 10px;
}

.c-dots.c-12 {
  height: 12px;
  width: 12px;
}

.h18w18 {
  height: 18px;
  width: 18px;
}

.c-dots.done {
  background: var(--color-green);
}

.c-dots.in-progress-blue {
  background-color: var(--color-blue);
}
.c-dots.inbox {
  background-color: var(--base-font-color);
}

.c-dots.warning {
  background-color: rgb(252, 161, 32);
}

.c-dots.in-progress-red {
  background-color: var(--color-red);
}

.c-dots.in-progress-white {
  background-color: var(--inprogress-status);
}

.c-dots.not-started {
  background-color: var(--color-white-10);
}

.c-text.done {
  color: var(--color-green);
}

.c-text.in-progress-blue {
  color: var(--color-blue);
}

.c-text.in-progress-white {
  color: var(--color-white-80);
}

.c-text.not-started {
  color: var(--color-white-30);
}

.comman-round-box.status:before {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 500px;
  position: absolute;
  right: 0px;
  top: 2px;
  border: 4px solid var(--base-body-color);
}

.comman-round-box.status.active:before {
  background-color: var(--color-green);
}

.comman-round-box.status.progress:before {
  background-color: var(--color-blue);
}

.comman-round-box.status.draft:before {
  background-color: var(--color-white-80);
}

.comman-round-box.status.inactive:before {
  background-color: var(--color-white-30);
}
.comman-round-box.status.inactive:before {
  background-color: var(--color-white-30);
}

/*active image toggle*/
.on-hover-active-toggle-img .default-img {
  display: block;
}

.light-theme-img .block-img {
  display: none;
}

.on-hover-active-toggle-img.active .default-img {
  display: none;
}
.comman_action_icon .on-hover-active-toggle-img.with_bg.active{
  background-color: var(--color-white-10);
  
}
.comman_action_icon .on-hover-active-toggle-img.with_bg.active svg.c-icons[fill]{
  color: var(--color-green);
}
.on-hover-active-toggle-img .active-img {
  display: none;
}

.on-hover-active-toggle-img.active .active-img {
  display: block;
}

/*comman active gradient border*/
.c-gradinet-active-border {
  position: relative;
  background: linear-gradient(#223444, #223444) padding-box,
    linear-gradient(45deg, var(--color-green), var(--color-green-blue))
      border-box;
  border: 1px solid transparent;
  border-radius: 50px;
}

.c-green-border {
  border: var(--color-green) 1px solid;
}

.c-green-border-50 {
  border: var(--color-green-50) 1px solid;
}

/* Comman Input Variant */
.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 5;
}

.input_caret_transparent {
  caret-color: transparent;
}

.hidden-input.z-index-3 {
  z-index: 3;
}

.hidden-input:disabled {
  z-index: -1;
}

.position-center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.comman-round-box.with-bg.p-5px {
  padding: 5px;
}
.comman-round-box.with-bg.p-3px {
  padding: 3px;
}
.comman-list-wrapper-main.p-15 {
  padding: 15px;
}
.badge-custom.badge-small{
  font-size: 8px;
  right: -2px;
  top: -6px;
  width: 16px;
  height: 16px;
}
.badge-custom.h18w18 {
  width: 18px;
  height: 18px;
}
.badge-custom.h19w19 {
  width: 19px;
  height: 19px;
}
.badge.pill-active{
  border: 1px solid var(--color-green-50)
}
.badge-custom {
  /* border: var(--left-panel-bg) 3px solid; */
  background-color: var(--color-green);
  color: var(--color-black);
  font-family: var(--title-family);
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 9px;
  line-height: 16px;
  /* width: 22px; */
  /* height: 22px; */
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.badge-custom.footerbadgechat{
  right: 18px;
  top: -6px;
}
footer .footer-links li:hover .badge-custom.footerbadgechat span{
  color: var(--color-black);
}
.badge-custom.left-side{
  right: 21px;
  z-index: 1;
}
.badge-custom.email-orange {
  background-color: orange;
  /* top: 22px;
  right: 3px; */
  top: -7px;
  right: -8px;
  line-height: 24px;
  height: 20px;
  width: 20px;
}

.badge-custom.pos-right {
  right: 10px;
}

.badge-custom.email-green {
  border: var(--left-panel-bg) 3px solid;
  background-color: var(--color-green);
  color: var(--color-black);
  font-family: var(--title-family);
  position: absolute;
  right: -7px;
  top: 30px;
  font-size: 9px;
  line-height: 16px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pin-office {
  position: absolute;
  top: 26px;
  left: 1px;
  transform: rotate(3deg);
  z-index: 5;
  background-color: var(--badge-inner-bg);
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: flex;
}
.bg-pin{
  background-color: var(--badge-inner-bg);
}
.badge-custom.f-color-white {
  color: var(--base-font-color);
}
.bg-warning {
  background-color: var(--color-warning)!important;
}
.badge-custom.bg-warning {
  background-color: var(--color-warning);
}

.badge-custom.bg-white-05 {
  background-color: var(--color-white-05);
}

.badge-custom.with-absolute-top {
  top: -12px;
  right: -12px;
}

.badge-custom.win-min-width-25 {
  min-width: 25px;
  min-height: 25px;
  line-height: inherit;
}

.badge-custom.with-position-relative {
  position: relative;
  display: inline-block;
  top: 0px;
  right: 0px;
}

.badge-custom.with-position-relative.with-d-flex {
  display: flex;
}

.badge.green-text {
  color: var(--color-green);
}

.badge.color-red {
  color: var(--color-red);
}

.badge-custom.done-border {
  border: 1px solid var(--color-green);
}

.badge-custom.done-border.with-bg {
  background-color: var(--color-green);
  color: var(--color-black);
}

.badge-custom.inprogress-border {
  border: 1px solid var(--color-blue);
}

.badge-custom.inprogress-border.with-bg {
  background-color: var(--color-blue);
  color: var(--color-black);
}

.badge-custom.to-do-border {
  border: 1px solid var(--color-white-80);
}
.bg-todo-calendar{
  background-color: var(--calednar-todo-border);
}
.badge-custom.with-to-do-border {
  border: 1px solid var(--calednar-todo-border);
  color: var(--base-font-color);
  background-color: transparent;
}

.badge-custom.with-orange-border {
  border: 1px solid var(--color-warning);
  color: var(--base-font-color);
  background-color: transparent;
}

.badge-custom.with-done-border {
  border: 1px solid var(--color-green);
  color: var(--base-font-color);
  background-color: transparent;
}

.badge-custom.with-inprogress-border {
  border: 1px solid var(--color-blue);
  color: var(--base-font-color);
  background-color: transparent;
}

.badge-custom.to-do-border.with-bg {
  background-color: var(--color-white-80);
  color: var(--color-black);
}

.badge-custom.not-started {
  border: 1px solid var(--color-white-20);
}

.badge-custom.not-started.with-bg {
  background-color: var(--color-white-20);
  color: var(--color-black);
}

.badge-green {
  font-family: var(--title-family);
  font-size: 10px;
  padding: 8px 5px;
  line-height: 0;
  color: var(--color-black);
  /* border: var(--color-green) 1px solid; */
  background-color: var(--lemon-yellow);
  border: 1px solid var(--left-panel-bg);
}
.badge-green.badge-with-margin-minus,
.margin-minus-20px{
  margin-right: -20px;
}
.custom-accordian-main .accordion-item .childTopicTaskFilter.accordian-body .task-sub-list:hover .badge-green.badge-with-margin-minus{
  margin-right: 3px;
}
.badge-green.email-orange {
  background-color: var(--color-warning);
}

.badge-green.h18w18 {
  height: 18px;
  width: 18px;
}

.badge.bg-left-panel {
  background-color: var(--left-panel-bg);
}

.badge-green.with-bg {
  background-color: var(--color-green);
  color: var(--color-black);
}

.badge-white {
  background-color: var(--color-white-05);
}

a.badge:hover {
  background-color: var(--color-white-10);
  transition: var(--transiton-3s);
}

.c_date_img_text img {
  margin-right: 10px;
}

/*input position absolute*/
.absolute-input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 5;
}
input[type='file'].absolute-input{
  font-size: 0;
}
/*pointer events*/
.cursor-pointer{
  cursor: pointer;
}
.cursor-unset{
  cursor: unset;
}
.absolute-input:disabled {
  cursor: auto;
}

.comman-list {
  padding: 10px;
  position: relative;
}

.comman-list.p-15 {
  padding: 15px;
}

.comman-list .list-bottom-part {
  padding-left: 40px;
}

.comman-list:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: var(--color-border) 1px solid;
  left: 0;
}

.comman-list.after-none:after {
  display: none;
}

ul:last-child:before {
  display: none;
}

.comman-list:last-child:after {
  display: none;
}

.comman-list.second-last-child-none:nth-last-child(2):after {
  display: none;
}

.comman-list.with-after-30:after {
  left: 30px;
}

.comman-list.with-after-40:after {
  left: 40px;
}

.comman-list.with-after-50:after {
  left: 50px;
}

.comman-list.with-after-60:after {
  left: 60px;
}

.comman-list.pinned .pin-icon {
  display: block;
}

.comman-list.with-badge .badge-icon {
  display: inline-block;
}

.comman-list .pin-icon {
  display: none;
}

.comman-list .badge-icon {
  display: none;
}

.c-top-calendar .calendar-date-wrapper {
  position: relative;
}
.slider_btn_left {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.slider_btn_right {
  position: absolute;
  right: -3px;
  top: 50%;
  transform: translateY(-50%);
}

.slider_btn_right img {
  transform: rotate(180deg);
  margin-left: -5px;
}

.slider_btn_left img {
  transform: rotate(0deg);
  margin-left: 4px;
}

.slider_btn {
  background-color: var(--base-body-color);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  z-index: 2;
}

.active-dropdown.show {
  border: var(--color-green) 1px solid;
}

.w-190 {
  width: 190px;
}

.overflowscroll {
  overflow: hidden auto;
  height: auto;
  max-height: 30vh;
}
.min-h25vh{
  min-height: 25vh;
}
.max-h20vh{
  max-height: 20vh;
}
.max-h40vh{
  max-height: 40vh;
}
.max-h50vh{
  max-height: 50vh;
}
.max-h15vh{
  max-height: 15vh;
}
.max-height-100vh{
  max-height: 100vh;
}
.h-100vh{
  height: 100vh;
}
.overflowscroll.max-height-50vh {
  max-height: 50vh;
}
.overflowscroll.max-height-60vh {
  max-height: 60vh;
}
.max-height-77vh {
  max-height: 77vh;
}
.overflowscroll.max-height-20vh {
  max-height: 20vh;
}

.comment-input-box .form-control,
textarea {
  padding-right: 0px;
}

.input_box_main.with_file_upload .upload_inputbox input {
  border: 0;
}

.input_box_main.with_file_upload .upload_inputbox.upload_relative .custom_file {
  padding: 7px;
}

.input_box_main.with_file_upload .upload_inputbox .upload_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  cursor: pointer;
}

.input_box_main.with_file_upload
  .upload_inputbox.upload_relative
  .upload_btn_text {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.input_box_main.with_file_upload .upload_inputbox .upload_btn_text {
  padding: 7px 25px;
  color: #000000;
  color: var(--color-white-40);
  background-color: var(--color-white-10);
  border-radius: 5px;
}

.form-check {
  margin-bottom: 0px;
  min-height: 18px;
}

.form-check-input.h20w20 {
  height: 20px;
  width: 20px;
}

.form-check-input:checked[type="radio"] {
  background-image: url(../images/on-off-btn-green.svg);
  background-size: 8px;
}

.form-check-input:checked ~ span {
  color: var(--base-font-color);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--color-white-03);
  /* opacity: 0.5; */
}

/* .form-check-input:checked[type="checkbox"] {
  background-image: url(../images/ic-check-green.svg);
} */

.form-check .form-check-label {
  padding-left: 5px;
  color: var(--color-white-60);
}

.cursor-pointer .form-check-label {
  cursor: pointer;
}

.form-check.with-span {
  padding-left: 0;
}

.hr_sepretr {
  position: relative;
  width: 100%;
  height: 100%;
}

.hr_sepretr:before {
  position: absolute;
  content: "";
  height: 1px;
  background-color: var(--color-white-10);
  bottom: 0;
  left: 40px;
  right: 10px;
}
.hr_sepretr.w100:before{
  left: 0;
  right: 0;
  width: 100%;
}
.hr_sepretr.l_10px:before{
  left: 10px;
}
.hr_sepretr.l_50px:before{
  left: 50px;
}
.hr_sepretr.r_0:before{
  right: 0px;
}
.hr_sepretr.left-25:before {
  left: 25px;
}

.form-check-input:checked {
  background-color: var(--color-white-10);
  border-color: transparent;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check {
  margin-bottom: 0px;
  min-height: 18px;
}

.form-check-input {
  height: 20px;
  width: 20px;
  margin-top: 0px;
  cursor: pointer;
}

.form-check-input:checked[type="radio"] {
  background-image: url(../images/on-off-btn-green.svg);
  background-size: 8px;
}

.form-check-input:checked ~ span {
  color: var(--color-white-80);
}

.form-check-input:checked[type="checkbox"] {
  background-image: url(../images/ic-check-green.svg);
}

.premium-wrapper-main {
  width: 65%;
}

.form-check.with-span {
  padding-left: 0;
}

.form-check.with-span .form-check-label {
  padding-left: 21px;
}

.form-check.with-span .form-check-label span {
  padding-left: 10px;
}

.form-switch .form-check-input {
  width: 35px;
}

.with-no-label-space.form-check label {
  padding: 0;
}

.form-check-input:checked {
  background-color: var(--color-white-10);
  border-color: transparent;
}
.taskmenufilter.border-bottom-10:last-child{
  border-bottom: 0;
}
.form-check-input:focus {
  box-shadow: none;
}

.form-check-input {
  background-color: var(--color-white-10);
  border: 0;
}

.input_box_main.with_file_upload .upload_inputbox {
  width: calc(100% - 100px);
  border-radius: 3px;
  margin-bottom: 0px;
  position: relative;
  border: 1px solid var(--color-white-03);
}

.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.form-switch .form-check-input {
  position: relative;
  background-image: none;
  transition: var(--transiton-3s);
}

.form-switch .form-check-input:focus {
  background-image: none;
}

.form-switch .form-check-input:checked {
  background-image: none!important;
}

.form-switch .form-check-input:after {
  position: absolute;
  content: "";
  background-color: var(--color-white-20);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
}

.form-switch .form-check-input:checked:after {
  background-color: var(--color-green);
  left: 19px;
}

.row-btn-label {
  position: relative;
  width: 33.33%;
}

.row-btn-label.with-margin-5px {
  margin-right: 5px;
}

.row-btn-label.with-margin-5px:last-child {
  margin-right: 0px;
}

.row-btn-label .row-btn-box {
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: var(--color-white-60);
  position: relative;
}
.w200px{
  width: 200px;
}
.login_page {
}

.login_page .login-logo {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: -90px;
}

.login_page .header-logo-img {
  width: 150px;
  height: 50px;
}

.login_page .login_box {
  border: var(--color-white-10) 1px solid;
  border-radius: 5px;
  width: 50%;
  max-width: 630px;
  padding: 30px;
  padding-top: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  background-color: var(--white);
}

.login_page .login_box .comman_title_white {
  margin-bottom: 30px;
  color: var(--base-font-color);
  font-weight: 800;
  font-family: var(--title-font);
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 20px;
}

.login_page .login_box .login_btn a {
  margin-right: 20px;
}
.login_page .login_btn .btn-primary:focus{
  background: #000;
  color: var(--white) !important;
}
.login_page .login_box .login_btn a:last-child {
  margin-right: 0px;
}

.login_page .forger_link {
  color: var(--color-green);
}

.login_page .custom_input_box_main {
  position: relative;
}

.login_page .custom_input_box_main .custom_input_box {
  width: 100%;
  text-align: left;
  margin-bottom: 50px;
  border-bottom: var(--color-border) 1px solid;
  position: relative;
}

.login_page .custom_input_box_main .custom_input_box .input_box_label {
  font-size: 16px;
  color: var(--color-font-color);
  display: block;
  position: relative;
  display: flex;
}

.login_page
  .custom_input_box_main
  .custom_input_box
  .input_box_label
  .left_img {
  margin: 0px 10px;
  min-width: 12px;
}

.login_page
  .custom_input_box_main
  .custom_input_box
  .input_box_label
  .title_text {
  position: relative;
}

.login_page
  .custom_input_box_main
  .custom_input_box
  .input_box_label
  .title_text
  span {
  color: var(--color-black);
  font-size: 16px;
  line-height: 0;
  position: absolute;
  top: 10px;
  right: -15px;
}

.login_page .custom_input_box_main .custom_input_box .input_box {
  flex-grow: 1;
  border: 0;
  background-color: transparent;
  padding: 20px 0px;
  padding-right: 20px;
  color: var(--color-green);
  font-size: 20px;
  font-weight: var(--font-weight-300);
  letter-spacing: 1.5px;
  position: relative;
}

/*/ Chrome, Safari, Edge, Opera /*/
input[type="number"].input_hide_arrow::-webkit-outer-spin-button,
input[type="number"].input_hide_arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom_input_box_main .custom_input_box .with_right_img {
  position: relative;
}

.custom_input_box_main .custom_input_box .with_right_img .hide_password {
  display: none;
}

.custom_input_box_main .custom_input_box .with_right_img .show_password {
  display: none;
}

.custom_input_box_main
  .custom_input_box
  .with_right_img.hide_pass
  .hide_password {
  display: block;
}

.custom_input_box_main
  .custom_input_box
  .with_right_img.show_pass
  .show_password {
  display: block;
}

.custom_input_box_main .custom_input_box .with_right_img img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

@media screen and (max-width: 767px) {
  .login_page {
  }

  .login_page .login_box {
    width: 50%;
    padding: 20px;
    padding-top: 20px;
    min-width: 90%;
  }

  .login_page .login_box .comman_title_white {
    margin-bottom: 20px;
  }

  .login_page .login_box .login_btn a {
  }

  .login_page .login_box .login_btn a:last-child {
  }

  .login_page .forger_link {
  }
}

.right-tabing-part .comment-input-box .form-control {
  height: 35px;
}

.h_45{
  height: 45px;
}
.h_53px.form-control{
  height: 53px;
  padding: 10px;
}
.filter-header-responsive {
  display: none;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.list-view-toggle .fade:not(.show) {
  opacity: 1;
}

/*Footer Menu CSs*/
#menu {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -75px 0 0 -75px;
  list-style: none;
  font-size: 200%;
}

.menu-button {
  opacity: 0;
  z-index: -1;
}

.menu-button {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  background: #424242;
  background-size: 100%;
  overflow: hidden;
  text-decoration: none;
}

#menu:not(:target) > a:first-of-type,
#menu:target > a:last-of-type {
  opacity: 1;
  z-index: 1;
}

#menu:not(:target) > .icon-plus:before,
#menu:target > .icon-minus:before {
  opacity: 1;
}

.menu-item {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 55%;
  line-height: 5px;
  top: 50%;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  background-color: #424242;
  transform: translate(0px, 0px);
  transition: transform 500ms;
  z-index: -2;
  transition: 0.5s;
}

.menu-item:hover {
  opacity: 0.5;
  box-shadow: 0 5px 10px black;
}

.menu-item a {
  color: #fff;
  position: relative;
  top: 30%;
  left: 0;
  text-decoration: none;
}

#menu:target > .menu-item:nth-child(6) {
  transform: rotate(60deg) translateY(-150px) rotate(300deg);
  transition-delay: 0s;
}

#menu:target > .menu-item:nth-child(5) {
  transform: rotate(20deg) translateY(-155px) rotate(-20deg);
  transition-delay: 0.1s;
}

#menu:target > .menu-item:nth-child(3) {
  transform: rotate(-20deg) translateY(-155px) rotate(20deg);
  transition-delay: 0.2s;
}

#menu:target > .menu-item:nth-child(4) {
  transform: rotate(-60deg) translateY(-150px) rotate(60deg);
  transition-delay: 0.3s;
}

.content {
  position: absolute;
  text-align: center;
  margin: -10px 0 0 -30px;
  top: 70%;
  left: 50%;
  font-size: 20px;
}

.spinner-border-sm {
  height: 13px;
  width: 13px;
}
.spinner-border-sm.h20w20 {
  height: 20px;
  width: 20px;
}
.with-overlay-img::before{
  position: absolute;
  content: "";
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-black2);
  z-index: 0;
  border-radius: 3px;
}
/* blur full page  */
.with-overlay-blur {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--base-body-color);
  z-index: 4;
  opacity: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.with-overlay-blur:before {
  content: "";
}

.css-2613qy-menu {
  display: block !important;
}

.pac-container {
  font-family: var(--base-family);
  background-color: var(--base-header-color);
  border-radius: 3px;
  border-top: 0;
  box-shadow: none;
  z-index: 10000;
  margin-top: 5px;
}

.pac-logo:after {
  display: none;
}

.pac-item {
  border-top: 1px solid var(--color-white-10);
  color: var(--color-white-60) !important;
  padding: 7px;
  font-size: 13px !important;
}

.pac-item:first-child {
  border-top: 1px transparent;
}

.pac-item:hover {
  background-color: var(--color-white-05) !important;
  cursor: pointer;
}

.pac-item-query {
  color: var(--color-white-60) !important;
}

.pac-item-selected,
.pac-item-selected:hover {
  background-color: var(--color-white-05);
}

.map-box-parent > div {
  width: 100% !important;
}

.plan-iframe-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.upper-part .company-logo-box .list-right-part {
  margin-top: -5px;
}

.comman_action_icon.show .on-hover-active-toggle-img .default-img {
  display: none;
}

.comman_action_icon.show .on-hover-active-toggle-img .active-img {
  display: block;
}

.comman_action_icon .show .on-hover-active-toggle-img .default-img {
  display: none;
}

.comman_action_icon .show .on-hover-active-toggle-img .active-img {
  display: block;
}

.text-on-hover-active-green:hover {
  color: var(--color-green);
  transition: var(--transiton-3s);
}

.comman_action_icon.show .text-on-hover-active-green,
.comman_action_icon.show svg.c-icons[fill]{
  color: var(--color-green);
}

.CustomiFrameClass {
  height: 10px;
  width: 10px;
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.myspace-fixed-box {
  width: auto;
  position: fixed;
  top: 50%;
  right: 0px;
  background-color: var(--base-modal-bg);
  z-index: 2;
}

.desktop-session-active .error {
  display: none !important;
}

.comman-search-dropdown {
  position: sticky;
  background: var(--bg-white-03-solid);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid var(--color-white-10);
}

.commn-search {
  /* background-color: var(--color-white-03); */
  border-bottom: 1px solid var(--color-white-05);
}

.commn-search .form-control {
  background-color: transparent;
}

.staff_user {
  border: 1px solid var(--inprogress-status);
}

.contact_user {
  border: 1px solid var(--color-blue);
}

.operator_user {
  border: 1px solid var(--color-green);
}

.three-row-btn {
  border: 1px solid var(--three-row-border-color);
}

.three-row-btn-bg {
  background-color: var(--three-row-bg-color);
  border:1px solid transparent;
}

input:checked ~ .row-btn-box {
  background-color: var(--three-row-bg-color);
  color: var(--three-row-text-color);
}

.row-btn-label:hover {
  background-color: var(--three-row-bg-hover-color);
  transition: var(--transiton-3s);
}

li.three-row-btn.dropdown-item .d-flex.bg-white-03 {
  border-radius: 3px;
}

li.three-row-btn.dropdown-item .row-btn-label.active-language {
  background-color: var(--dropdown-header-bg);
}

li.three-row-btn.dropdown-item .row-btn-label.active-language .row-btn-box {
  color: var(--base-font-color);
}

.dropdown-item {
  color: var(--dropdown-item-color) !important;
  padding: 10px;
  font-size: 13px !important;
  line-height: initial;
}

.dropdown-menu .dropdown-item.no-hover:focus,
.dropdown-item.no-hover:hover {
  background-color: transparent !important;
  cursor: auto!important;
}
a.dropdown-item.no-hover:hover {
  background-color: transparent !important;
  cursor: pointer!important;
}
/* .no-hover:hover {
  background-color: transparent !important;
  cursor: pointer!important;
} */
.dropdown-item.with-border {
  border-bottom: var(--color-white-05) 1px solid;
}

.dropdown-item:last-child.with-border {
  border-bottom: 0px;
}
.notification-listing li:last-child:before{
  display: none;
}

.dropdown-item.custom-assigneesMain.show,
.dropdown-item.custom-assigneesMain.show .comman-icons,
.dropdown-item.custom-assigneesMain.show svg.c-icons[fill] {
  color: var(--color-green) !important;
  /* stroke: var(--color-green); */
}

.dropdown-item .c-list-detail {
  font-size: 13px;
  color: var(--color-white-60);
  text-transform: capitalize;
}

.dropdown-item li:last-child .hr_sepretr {
  display: none;
}

.dropdown-divider {
  border-top: 1px solid var(--dropdown-dorder-color);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: inherit;
  color: inherit;
  transition: var(--transiton-3s);
}

a.dropdown-item:focus,
a.dropdown-item:hover {
  background-color: var(--dropdown-header-bg);
  color: var(--color-white);
  transition: var(--transiton-3s);
}

.dropdown-head {
  background-color: var(--dropdown-header-bg);
}
.dropdown-footer {
  background-color: var(--dropdown-header-bg);
}

.dropdown-item.active {
  background-color: var(--dropdown-active-bg-color);
  color: var(--color-green) !important;
}

/* a.dropdown-item.active,
a.dropdown-item:active {
  background-color: var(--dropdown-active-bg-color);
  color: var(--color-white) !important;
} */

.dropdown-item.active.with-tick {
  position: relative;
  width: 100%;
  height: 100%;
}
.dropdown-item.active.with-tick .c-list-detail{
  color: var(--color-green);
}
.dropdown-item.active.with-tick:after {
  position: absolute;
  content: "";
  /* width: 100%; */
  /* width: 32px;     */
    right: 0;
  height: 100%;
  background-image: url(../images/ic-check-green.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  /* background-position: center right 15px; */
  background-position: center right 8px;
  top: 0;
}

.dropdown-menu.time-slot-dropdown {
  height: 40vh;
  bottom: 100px;
}

.dropdown-menu.time-slot-dropdown .dropdown-item {
  padding: 10px;
}

.dropdown-menu {
  background-color: var(--dropdown-bg-color);
  border: 1px solid var(--dropdown-dorder-color);
  /* color: var(--dropdown-text-color) !important; */
  color: var(--base-font-color) !important;
  border-radius: 5px;
  font-size: 13px !important;
  width: 280px;
  box-shadow: var(--dropdown-box-shadow-color);
  padding: 0;
  z-index: 1055;
  text-align: left;
}
.on-hover-transition-dropdown .with-transform-revert-main {
  opacity: 0;
  transition: opacity 3.5s;
}
.on-hover-transition-dropdown:hover .with-transform-revert-main {
  opacity: 1;
  transition: opacity 0.5s;
}
.on-hover-transition-dropdown .dropdown-menu.with-transform-revert {
  /* transform: translate(0px, 45px) scaleY(0)!important; */
  transform-origin: top;
  transition: transform ease-in-out 0.8s;
  display: block!important;
  transition-delay: 1.5s;
  transition: max-height 1.5s;
  max-height: 0;
}
.on-hover-transition-dropdown:hover .dropdown-menu.with-transform-revert {
  /* transform: translate(0px, 45px) scaleY(1)!important; */
  transform-origin: top;
  transition: transform ease-in-out 0.8s;
  transition-delay: 1.5s;
  transition: max-height 1.5s;
  max-height: 300px;
  opacity: 1;
}
/*Uncontrol dropdown code */
.dropdown-menu .dropdown-toggle{
  padding:0;
}
.dropdown-menu .btn.btn-secondary{
  text-align: left;
  padding: 0;
  border: 0;
}
.dropdown-menu .btn.btn-secondary:hover{
  background-color: transparent;
}
.dropdown-menu .btn.btn-secondary:focus{
  border: 0px;
  background-color: transparent;
}
.dropdown-menu .btn.btn-secondary.no-hover:hover{
  background-color: transparent;
}
.dropdown-menu .btn.btn-secondary{
  text-align: left;
  padding: 0;
  border: 0;
}

/* ----------- */
.dropdown .btn.btn-secondary:hover{
  background-color: transparent;
}
.dropdown .btn.btn-secondary:focus{
  /* border: 0px; */
  background-color: transparent;
}
.dropdown .btn.btn-secondary.no-hover:hover{
  background-color: transparent;
}


/*Uncontrol dropdown code */

.dropdown-menu .dropdown-header {
  background-color: var(--dropdown-header-bg);
  padding: 12px;
}
.dropdown-menu .dropdown-header.bg-white-hex-03{
  background-color: var(--bg-white-hex-03);
}
.dropdown-menu.w-550px {
  width: 550px;
}

.dropdown-menu.w-650px {
  width: 650px;
}

.dropdown-menu.w-240px {
  width: 240px;
}

.dropdown-menu.max-width-280 {
  max-width: 280px;
}

.dropdown-menu.w-140 {
  width: 140px;
  /* min-width: auto; */
}

.dropdown-menu.w-50px {
  width: 40px;
  min-width: 40px;
}

.dropdown-menu .comment-dropdown-with-min-height-20vh {
  min-height: 20vh;
}

.dropdown-menu.min-w-100px {
  min-width: 110px;
  width: 110px;
}

.dropdown-menu.min-w-320px {
  min-width: 320px;
}

/* .dropdown-menu .dropdown-header-sticky{
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: var(--dropdown-header-bg);
} */
.dropdown-menu .dropdown-footer-sticky {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 7;
  background-color: var(--dropdown-bg-color);
  padding: 10px;
  border-top: 1px solid var(--dropdown-dorder-color);
}

.dropdown-menu.w-250 {
  width: 250px;
}

.dropdown-menu.w-200 {
  width: 200px;
}
.dropdown-menu.w-170{
  width: 170px;
}
.dropdown-menu.w-280 {
  width: 280px;
}

.dropdown-menu.w-300 {
  width: 300px;
}

.list-view-toggle .dropdown-menu.w-200 {
  width: 140px;
}
.list-view-toggle .dropdown-menu.w-350 {
  width: 350px;
}
.dropdown-menu.w-350 {
  width: 350px;
}

.dropdown-menu.w-370 {
  width: 370px;
}

.dropdown-menu.w-420 {
  width: 420px;
}

.dropdown-menu.w-500 {
  width: 500px;
}

.dropdown-menu li:last-child.hr_sepretr:before {
  display: none;
}
ul li.hr_sepretr:last-child:before{
  display: none;
}
.popover-body .popover-detail-wrapper .border-bottom:last-child {
  border-bottom: 0 !important;
}
.popover-body .PopoverClose{
  top: -7px;
  right: -5px;
  padding: 1px;
}
.badge-yellow.with-bg {
  background-color: #fca120;
  color: black;
  position: absolute;
  right: 9px;
}

.dropdown-static .dropdown-menu {
  transform: translate(8px, 35px) !important;
}

.dropdown-static .dropdown-menu .dropdown-menu {
  transform: translate(-134px, 0px) !important;
}

.team-list-main {
  background-color: var(--color-white-03);
  border-radius: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  min-height: 130px;
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
}
.team-list-main .fav-icon{
  position: absolute;
  top: 7px;
  left: 10px;
}
.team-list-main.active {
  border: 1px solid var(--color-green-50);
}

.team-list-main .team-list-box {
  background: linear-gradient(
    360deg,
    #0f2a3c -10.06%,
    rgba(23, 49, 66, 0) 95.1%
  ) !important;
  padding: 10px;
  margin-top: auto;
  line-height: 20px;
  border-radius: 5px;
}

.team-list-image-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.dropdown.team-list-dropdown .dropdown-menu.w-200 {
  width: 140px !important;
  transform: translate(0px, 25px) !important;
}
.dropdown-menu .dropdown-toggle::after{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.position-sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 3;
}
.position-sticky-bottom.bottom_51px{
  bottom: 0px;
}
.mr-30 {
  margin-right: 30px;
}

/* pill-checkbox */
.pill-checkbox {
  position: relative;
}

.pill-checkbox .badge {
}

.pill-checkbox input:checked ~ .badge {
  border: 1px solid var(--color-green);
  background-color: transparent;
}

.employeeListingDropDownminHeight {
  min-height: 150px;
}

.pill-checkbox .ofice-list-checkbox {
  display: none;
}

.pill-checkbox input:checked ~ .ofice-list-checkbox {
  display: block;
}

.dropstart .dropdown-toggle::before {
  border: none;
}

.replying-box-main , .pill-box-main {
  /* margin: 0px -10px; */
  padding: 10px;
}

.replying-box-main.with-min-height {
  min-height: 50%;
}

/* Convert checklist dropdwon */
.task-setting-section.convert-options-filter {
  padding: 10px !important;
}

.convert-options-filter .custom-select-menu.with-filter-select {
  margin-right: 10px;
}

.convert-checklist-list li {
  padding-bottom: 15px;
}

.convert-checklist-list .left-checklist-detail {
  width: calc(100% - 200px);
}

.convert-checklist-list .right-convert-option {
  width: 200px;
}

.login-section .login_box {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translate(0);
  padding: 30px;
}

.modal-sticky-spacing {
  background-color: var(--base-modal-bg);
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 10px;
  width: 100%;
  z-index: -1;
}

.modal-sticky-spacing.with-top {
  top: -10px;
  bottom: 0;
}

.mension-box__input {
  display: block !important;
}

.transform-scale-0 {
  transform: scale(0);
}

.dropdown-menu .dropdown-inner-scroll-50px {
  max-height: calc(30vh - 50px);
  overflow: hidden auto;
}
.dropdown-menu .dropdown-inner-scroll-50px.withheight60vh {
  max-height: calc(60vh - 50px);
  overflow: hidden auto;
}

.checkbox-border-wrapper {
  padding: 5px;
}

.checkbox-border-wrapper.active .checkbox-with-border {
  border-color: var(--color-green);
}

.checkbox-border-wrapper:first-child {
  /* margin-left: 0; */
}

.checkbox-with-border {
  position: relative;
  padding: 6px 12px;
  border: 1px solid var(--color-white-10);
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.offcanvas-end.custom-modal-style .checkbox-border-wrapper.with-fix-width {
  width: 50%;
}

.checkbox-border-wrapper.with-fix-width,
.custom-modal-style.modal-full .checkbox-border-wrapper.with-fix-width {
  width: 25%;
}

.checkbox-with-border .check-box {
  height: 16px;
  width: 16px;
  background: var(--color-white-07);
}

.checkbox-with-border .check-text {
  padding-left: 10px;
  font-size: 14px;
}

.checkbox-border-wrapper input:checked ~ .checkbox-with-border {
  border: 1px solid var(--color-green-50);
}

.checkbox-border-wrapper input:checked ~ .checkbox-with-border .check-box {
  background-image: url(../images/ic-check-green.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.schedule-count-box-main .schedule-count-box {
  min-width: 35px;
  max-width: 114px;
  padding-left: 20px;
  padding-right: 20px;
  /* text-align: center; */
}

.schedule-count-box-main .overflow-auto::-webkit-scrollbar {
  height: 4px;
}

.videos {
  position: relative;
  padding-top: 56%;
  border-radius: 5px;
  overflow: hidden;
}

.map-main {
  width: 100%;
  height: calc(100% - 70px);
  margin-top: 10px;
  display: flex;
}

.map-left {
  height: 100%;
  padding-right: 10px;
  flex-grow: 1;
}

.map-left .map-box {
  /* border: 1px solid var(--color-green-50) !important;
  border-radius: 5px; */
}

.map-left .map-box > div {
  height: 100%;
}

.map-right {
  /* max-width: 62%; */
  height: 100%;
}

.card-list {
  position: relative;
}

.bg-img-bottom-right {
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  filter: brightness(1.5);
}

.map-right-task-request {
  width: 500px;
}

.main-card-list {
  width: 440px;
}

.map-main .card-list {
  border-radius: 5px;
}
/* .map-main .card-list:hover {
  background-color: var(--color-white-05);
} */
.h-55{
  height: 55%;
}
.h-45{
  height: 45%;
}
.margin15{
  margin: 15px;
}
.main-card-list .card-list {
  margin-bottom: 10px;
}
.main-card-list .card-list.with-height-calc{
  height: calc(25% - 12px);
}
.main-card-list .card-list.with-width-calc{
  width: calc(33.33% - 12px);
  margin-right: 12px;
}
.main-card-list .card-list.with-width-calc:last-child{
  margin-right: 0;
}
.main-card-list .card-list:last-child {
  margin-bottom: 0;
}
.bs-popover-end > .popover-arrow::after{
  border-right-color: var(--color-white-10-solid);
}
.task-request-Progress {
  width: 100%;
  display: flex;
  height: 40px;
  overflow: hidden;
}

.map-task-box {
  /* width: 50%; */
  width: calc(50% - 10px);
  margin-right: 20px;
  position: relative;
}
.map-task-box.wih-before-box:before{
  content: "";
  position: absolute;
  right: -3px;
  bottom: -10px;
  height: 25px;
  width: 25px;
  z-index: 1;
  background-color: var(--bg-white-hex-03);
}
.map-task-box:nth-child(even){
  margin-right: 0;
  border-right: 0;
}

.setting-menu-main {
  background-color: var(--dropdown-bg-color) !important;
  width: 850px;
  position: fixed;
  bottom: 10px;
  left: 70px;
  z-index: 23;
  color: #fff;
  border: 1px solid var(--color-border) !important;
  border-radius: 5px;
  /* overflow: hidden; */
}
.setting-menu-main.with-half-width{
  width:425px;
}
.setting-menu-main:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--dropdown-bg-color);
  bottom: 77px;
  left: 0;
  margin-left: -5px;
  transform: rotate(135deg);
}

.setting-menu-body {
  display: flex;
}

.setting-menu-right {
  width: 220px;
  border-left: 1px solid var(--color-border);
  max-height: 70vh;
}

.setting-menu-list-items {
}

.setting-menu-left {
  overflow: auto;
  max-height: 70vh;
}
.tools-main-box {
  border: 1px solid transparent;
}
.tools-main-box.active {
  border: 1px solid var(--color-green-50);
}

/* MAp Pin style  */
.gm-style .gm-style-iw-c{
  padding:0;
  background-color: transparent;
  border-radius: 5px;
}
.gm-style-iw-d {
  overflow: hidden !important;
  position: relative;
  z-index: 1;
}
.gm-style .gm-style-iw-tc{
  filter: none;
}
.gm-style .gm-style-iw-tc:after{
  background:var(--dropdown-bg-color);
}
button.gm-ui-hover-effect {
  z-index: 5;
  filter: invert(1);
  top: 2px !important;
  right: 1px !important;
}
.comman-list-dots {
  position: absolute;
  content: '';
  width: 17px;
  height: 17px;
  border-radius: 50%;
  top: 10%;
  left: -21px;
  border: 4px solid var(--base-modal-bg);
  background-color:  var(--color-white-10-solid);
  z-index: 1;
}
.comman-list-dots.done{
  background-color: var(--color-green);
}
.comman-list-line:after{
  position: absolute;
  content: '';
  width: 2px;
  height: 93%;
  /* transform: translateY(-50%); */
  background: var(--color-white-10-solid);
  top: 18px;
  left: 10px;

}
.upload-image-preview .comman-image-box.with-border{
  border: 1px solid var(--color-white-10);
}
.upload-image-preview .comman-image-box.with-border.active{
  border: 1px solid var(--color-green-50);
}
.upload-image-preview .comman-image-box.active{
  border: var(--color-green-50) 1px solid;
}

/* Global Search */
.global-search-main{
   /* max-width: 500px; */
  /* width: 500px; */
  /* z-index: 999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 100%;
}
.global-search-main.w_500px{
  width: 500px;
}
.global-search-main-modal{
  max-width: 500px;
  width: 500px;
  z-index: 16;
  margin-top: -30px;
  position: absolute;
  top: -6px;
}
.backdrop-div{
  position: fixed;
  height: 100%;
  width: 100%;
  top:0;
  left: 0;
  background-color: var(--base-modal-backdrop-color);
}
.search-box-main{
  position: absolute;
  height: 500px;
 width: 500px;
 /* top: 60px; */
 top: 10px;
 overflow-y: scroll;
  background-color: var(--global-search-bg-color);
}
.min-width100{
  min-width: 100px;
}
.min-width120{
  min-width: 120px;
}
.min-width200{
  min-width: 200px;
  width: 200px;
  
}
.w100px{
  width: 100px;
}
.min-width350{
  min-width: 350px;
  width: 350px;
  
}
.comman-backdrop-search{
  position: relative;
}
footer.comman-backdrop-search{
  position: fixed;
}
/* footer.comman-backdrop-search:before{
  bottom: 15px;
  top: auto;
} */
.footer-box-shadow{
  box-shadow: var(--footer-action-icon-box-shadow);
}
.task-list-accordian-main .accordion-collapse.show .accordion-body > div{
  /* box-shadow: var(--accordion-body-drop-shadow); */
}
.accordion-body > div:last-child .task-list-card {
  margin-bottom: 0;
  border-radius: 0px 0px 3px 3px !important;
}
.task-list-accordian-main .accordion-collapse.show .accordion-body > div.comman-placeholder-wrapper{
  box-shadow:none;
  background-color: var(--white);
}
.comman-backdrop-search:before{
  content:"";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--base-modal-backdrop-color);
  z-index: 10;
  opacity: 0.8;
}
.comman-backdrop-search.modaloverlay:before{
  background-color: var(--modal-overlay);
}
footer.comman-backdrop-search:before{
  opacity: 0.9;
  top: auto;
  height: 80px;
}
.checkbox_absolute{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  display: none;
  left: 8px;
}
.checkbox_absolute  .form-check-input{
  background-color: var(--color-white-10-solid);
}
.with_checkbox_absolute .accordian-header-box .c-dots{
  position: relative;
  left: 4px;
}
.with_checkbox_absolute .checkbox_accordian:hover .checkbox_absolute{
  display: block;
}
.space-document-main .space-document-add-button{
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
}
.space-document-main .space-placeholder-box{
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.comment-input-box .action_icons_aboslute{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.search_with_absolute{
  right: 70px;
  top: 15px;
}
.search_with_absolute .comment-input-box input::placeholder{
  color: var(--base-color);
}
/* button[aria-label="Toggle fullscreen view"]{
  display: none !important;
} */
img[alt="Google"]{
  display: none;
}
.gmnoprint[role="menubar"]{
  display: none !important; 
}
button[title="Toggle fullscreen view"]{
  bottom: 0px !important;
  left: 0px !important;
  right: auto !important;
}
/* .gmnoprint , .gm-style-cc{
  display: none !important;
} */
.close_absolute{
  position: absolute;
  right: 0px;
  top: 0px;
}
.demo{
  zoom: 1;
}
.attechment-viewer{

}
.attechment-viewer .unsupported-message{
  background-color: transparent !important;
}
.attechment-viewer video{
  height: 100%;
  width: 100%;
}
.attechment-viewer .pdf-canvas{
  display: flex;
}
.attechment-viewer .pdf-canvas canvas{
  margin:auto ;
}
.attechment-viewer .document-container{
  /* background:transparent;
    margin: auto;
    border: var(--color-white-10) 1px solid; */
    color:initial;
}
.attechment-viewer .pg-viewer{
  color:inherit;
}
.attechment-viewer .spreadsheet-viewer{
  color:inherit !important;
}
.schedule-right-header.w-45{
width: 45%;
}
.chat-attachment-loader{
  top: -74px;
}
.w_80px{
  width: 80px;
}
.w_92px{
  width: 92px;
}
.comman_action_icon .action_icon.h32w40{
  height: 32px;
  width: 40px;
}
.w_80calc{
  width: calc(100% - 80px);
}
.after-separator:after{
  position: absolute;
  content: '';
  left: 50%;
  top: 40px;
  bottom: 0;
  height: calc(100% - 40px);
  width: 1px;
  background-color: var(--color-white-05);
}
/* @media screen and (min-width: 1400){
  .daily-schedule-main.with-ms-5px{
      margin-left: 5px !important;
  }
} */
/* .modal-open .after-login-header, */

.modal-open .fixed-left-panel,
.after-login-header .left-part,
.after-login-header .center-part,
.after-login-header .right-part .link-part,
.modal-open .c-top-calendar,
.modal-open .accordion-item .list-view,
.modal-open .accordion-button,
.modal-open footer{
  /* opacity: var(--modal-open-focus-visibility); */
}

.list-view-toggle .accordion-item .list-view.task-list-card.active,
.modal-open .offcanvas-body .list-view{
  opacity: 1;
}

.modal-overlay,
.modal-overlay-calender{
  display: none;
}
body.searchbackdrop .modal-overlay{
  display: block;
    box-shadow: var(--modal-overlay) !important;
    z-index: 7;
    position: absolute;
}
.custom-accordian-main .accordion-item{
  /* z-index: 7; */
}
body.modal-open .modal-overlay:hover{
  display: block;
  box-shadow: var(--modal-overlay) !important;
  z-index: 7;
  position: absolute;
}
.modal-overlay1{
  box-shadow: var(--modal-overlay) !important;
  /* z-index: 7; */
}
.modal-overlay1.withzindex{
  z-index: 9;
}
body.modal-open .modal-overlay {
  /* line 159 uncomment */
  display: none;
  /* display: block; */
  box-shadow: var(--modal-overlay) !important;
  z-index: 7;
  position: absolute;
}
body.modal-open .modal-overlay-calender{
  display: block;
  box-shadow: var(--modal-overlay) !important;
  z-index: 7!important;
}
body.modal-open .task-list-accordian.custom-accordian-main .accordian-header-box {
  z-index: 8;
  /* box-shadow: var(--modal-overlay)inset !important; */
  /* background-color: transparent!important; */
  border-bottom: 1px solid transparent !important;
  /* width: 0; */
}
.pill-wrappe-modal-overlay{
  display: none;
}
body.modal-open .pill-wrappe-modal-overlay {
  display: block;
  box-shadow: 557px -352px 0px 387px #152a3a;
  background: #152a3a;
}
body.modal-open .task-list-accordian.custom-accordian-main .accordion-header{
  background: var(--color-white-05)!important;
  border-radius: 3px 3px 0 0;
}
body.modal-open .offcanvas .task-list-accordian.custom-accordian-main .accordian-header-box {
  z-index: inherit;
  box-shadow: inherit!important;
}
body.modal-open .modal-overlay2{
  display: block;
  box-shadow: var(--modal-overlay)inset !important;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}
body.modal-open .modal-overlay3{
  display: block;
  box-shadow: var(--modal-overlay)inset !important;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}
body.modal-open  .custom-modal-style .task-detail-right-main{
  background-color: transparent;
}

body.modal-open .task-list-accordian .pill-wrapper {
z-index: 9;
}
body.modal-open .list-view-toggle .task-list-card.active{
  z-index: 7;
  background: var(--color-white-03-solid);
}
.fixed-left-panel{
  z-index: 20;
  position: relative;
}
svg.check_span_img.inactive, svg.check_span_img.inactive path {
  stroke: var(--base-font-color);
  fill: var(--base-font-color);
}
.border-width2{
  border-width: 2px;
}
.react-select__indicator-separator{
  display: none;
}

/* custom-autocomplete */
.custom-autocomplete{
  width: 100%!important;
  color: var(--base-font-color)!important;
}
.custom-autocomplete input{
  padding: 10px 15px!important;
}
.custom-autocomplete .MuiOutlinedInput-notchedOutline{
  border-color: var(--color-white-10) !important;
  font-family: var(--base-family)!important;
}
.custom-autocomplete .MuiInputBase-colorPrimary {
  padding: 0!important;
  height: 43px!important;
  color: var(--base-font-color);
  background-color: var(--color-white-03);
  border-radius: 3px;
  font-size: 14px !important;
  font-family: var(--base-family);
  color: var(--base-font-color);
}
.custom-autocomplete .MuiButtonBase-root{
  filter: invert(1);
}
.MuiPaper-elevation,
.MuiAutocomplete-listbox{
  background-color: var(--base-header-color)!important;
  font-size: 13px!important;
  color: var(--color-white-60)!important;
  transition: var(--transiton-3s)!important;
  font-family: var(--base-family)!important;
  box-shadow: var(--dropdown-box-shadow-color)!important;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option,
.MuiAutocomplete-listbox li{
  border-bottom: 1px solid var(--color-white-10);
}
.MuiAutocomplete-listbox .MuiAutocomplete-option:last-child,
.MuiAutocomplete-listbox li:last-child{
  border-bottom: none;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option:hover,
.MuiAutocomplete-listbox li:hover{
  background-color: var(--color-white-05)!important;
}
.MuiAutocomplete-listbox li[aria-selected="true"] {
  background-color: var(--color-white-05)!important;
}
.MuiDialog-container .MuiDialog-paperScrollPaper{
  background-color: var(--base-header-color)!important;
  box-shadow: var(--dropdown-box-shadow-color)!important;
  font-size: 13px!important;
  color: var(--base-font-color)!important;
  font-family: var(--base-family)!important;
}
.MuiDialog-container .MuiDialog-paperScrollPaper h2{
  font-size: 16px!important;
  color: var(--base-font-color)!important;
  font-family: var(--base-family)!important;
}
.MuiDialog-container .MuiDialog-paperScrollPaper div,
.MuiDialog-container .MuiDialog-paperScrollPaper label{
  color: var(--base-font-color)!important;
  font-family: var(--base-family)!important;
}
.css-zrqpq1::after{
  border-bottom: 1px solid var(--color-white-10)!important;
}
.MuiDialog-container .MuiDialog-paperScrollPaper .MuiDialogActions-spacing{
  justify-content: space-between!important;
}
.MuiDialog-container .MuiDialog-paperScrollPaper .btn{
  font-size: var(--space-12);
  --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 0.25rem;
}
.MuiDialog-container .MuiDialog-paperScrollPaper .MuiDialogActions-spacing .btn:first-child{
  order: 1!important;
  background: transparent;
    color: var(--btn-secondary-color)!important;
    border: 1px solid var(--color-white-10)!important;
}
.MuiDialog-container .MuiDialog-paperScrollPaper .MuiDialogActions-spacing .btn:last-child{
  order: 0!important;
}
/* custom-autocomplete / */
.GeneralItemTable .WithHeaderbg{
  background-color: rgb(40 63 78);
}

.call-ring-circle {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: var(--color-black2);
  border-radius: 100%;
  animation: coccoc-alo-circle-anim 0.8s infinite ease-in-out;
}

@keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(0) skew(1deg);
      opacity: .1
  }
  30% {
      transform: rotate(0) scale(.3) skew(1deg);
      opacity: .6
  }
  100% {
      transform: rotate(0) scale(1.2) skew(1deg);
      opacity: .1
  }
}
.custom-accordian-main.with-left-header-arrow .comman_action_icon .action_icon:hover.no-hover:hover{
  background-color: var(--color-white-05);
  cursor: pointer;
}
.audio-call-main {
  /* background-color: var(--base-modal-header-color)!important; */
  background-color: #253d4c;
  padding: 10px;
  position: fixed;
  top: 71px;
  right: 11px;
  z-index: 1061;
  color: #fff;
  border-radius: 5px;
  max-width: 250px;
}
.officeList_dropdown_transform{
  transform: translate(0, 35px)!important;
}
.floor-step-wrapper .floor-step-box-main .floor-step-box{
  border:transparent 1px solid;
}
.floor-step-wrapper .floor-step-box-main .floor-step-box.active{
  border:var(--color-green) 1px solid;

}
.bottom-fix-button{
  position: fixed;
  bottom: 20px;
  right: 30px;
}
.z-index-21{
  position: relative;
  z-index: 21;
}

.custom-datepicker.WithFixWidthAndSelectYear{

}
.custom-datepicker.withinputabsolute .react-datepicker-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.custom-datepicker.withinputabsolute .react-datepicker-wrapper .react-datepicker__input-container input{
  opacity: 0;
  width: 100%;
}
.custom-datepicker.WithFixWidthAndSelectYear .react-datepicker-popper , .custom-datepicker.WithFixWidthAndSelectYear .react-datepicker{
  /* min-width: 250px; */
  width: 250px;
  max-width:300px;
}
.custom-datepicker.WithFixWidthAndSelectYear .react-datepicker__header{
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
}
.custom-datepicker.WithFixWidthAndSelectYear .react-datepicker__day-names{
  /* margin-top: 8px; */
  width: 100%;
}
.custom-datepicker.WithFixWidthAndSelectYear .react-datepicker__year-read-view--down-arrow{
  top:4px;
}
.custom-datepicker.WithFixWidthAndSelectYear .react-datepicker__year-read-view{
  visibility: visible !important;
  font-size: 15px;
}
.custom-datepicker.WithFixWidthAndSelectYear .react-datepicker-wrapper .react-datepicker__input-container input{
  width: 100%;
}
.custom-datepicker.WithFixWidthAndSelectYear .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  display: none !important;
}
/* .autofill-off:-webkit-autofill,
.autofill-off:-moz-autofill,
.autofill-off:-o-autofill,
.autofill-off:-ms-autofill,
.autofill-off {
  background-color: transparent !important;
  -webkit-text-fill-color: inherit !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #0f2a3c inset !important;
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff !important;
  border:0 !important;
} */
.popover.popoveraroowposition.bs-popover-bottom > .popover-arrow{
  top:-10px !important;
}
.comman_action_icon.iconactive svg.c-icons[fill]{
  color: var(--color-green);
}
.comman_action_icon .action_icon.with-border-active{
  border: 1px solid transparent;
}
.comman_action_icon .action_icon.with-border-active.active{
  border: 1px solid var(--color-white-10);
}
.comman_action_icon .action_icon.with-border-active.active svg.c-icons[fill]{
  /* color: var(--color-green); */
}
.mx35px{
  margin: 0px 35px;
}
.Comment-header.pb22px{
  padding-bottom: 22px;
}
.count-absolute{
  position: absolute;
  top: 16%;
  text-wrap: nowrap;
  width: 100%;
  left: 0;
}
.external_header .after-login-header.pl-85 {
  padding-left: 85px !important;
}
.left-option-box-absolute{
  background-color: var(--dropdown-bg-color);
  box-shadow: var(--dropdown-box-shadow-color);
  border-radius: 5px;
  position: absolute;
  top: -74px;
  right: 0;
  width: calc(100% - 60px);
  z-index: 2;
}
.comman-round-box:hover svg.c-icons[fill].fill_green path{
  fill: var(--color-green);
}
.w_800px{
  width: 800px;
}
.SelectWithAbsolute{
  position: absolute;
  top: 50%;
  right: 55px;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  display: flex;
  background-color: var(--color-white-03);
  border-radius: 3px;
  border: 1px solid transparent;
}
.aicommentbox{
  /* position: absolute; */
  /* position: sticky; */
  /* bottom: 80px; */
  /* bottom: 85px; */
  /* bottom: 65px; */
  /* bottom: 5px; */
  /* background-color: #153042; */
  /* background-color: #193343; */
  background-color: #0f2a3c;
  width: 700px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  margin-left: 5px;
  /* border-top: 1px solid var(--color-white-05); */
  position: fixed;
  bottom: 10px;
  z-index: 17;
  left: 80px;
  width: calc(100% - 90px);
}
.aicommentbox .aicommentbox-reply{
  background-color: var(--base-header-color);
  border-bottom: var(--color-white-10) 1px solid;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  /* overflow: hidden auto; */
  border-radius: 3px 3px 0px 0px;
  position: relative;
}
.aicommentbox .aicommentbox-reply .aicomment-reply-close{
  position: absolute;
  top: -10px;
  right: 0;
  background-color: var(--white);
  padding: 1px 5px;
    border-radius: 50%;
    border: 1px solid var(--color-border);
}
.hover-left-penal .aicommentbox,
.with-left-penel .aicommentbox{
  left: 360px;
  width: calc(100% - 370px);
  
}
.aicommentbox.aiinputrelative{
  position: absolute;
    width: 100%;
    left: auto;
    bottom: 10px;
    /* bottom: -62px; */
}
.aicommentbox.with-toggle-input{
  bottom: 5px;
}
.AiAbsolute{
  position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 113px;
}
.aicommentbox:after {
  display: none;
  content: " ";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
    border-top: 15px solid #153042;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
}
/* footer .footer-links li.mid-footer-link:hover .aicommentbox{
  display: block;
} */
.aicommentbox-goto-bottom{
  position: absolute;
  bottom: 70px;
  left: 0;
}
.leftminus80{
  left: -80px;
}
.comman-round-box.with-fullimg-overlay .comman-bg-img::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-black);
  opacity: 0.8;
  border-radius: 3px;
}
.comman-round-box.with-fullimg-overlay .unread_count.absolute{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  font-size:14px;
}
hr.w-7{
  border-top:1px solid var(--color-white-07);
  margin: 10px 0px;
}
.task-list-card.list-view.aichatlist{
  background-color: transparent;
  border-bottom: 0;
  padding:0;
}
.task-list-card.list-view.aichatlist .task-list-wrapper .task-list-detail{
  padding-left: 0;
  width: 100%;
}
.task-list-card.list-view.aichatlist .task-left .task-left-part-toggle{
  flex-wrap: wrap;
}
.task-list-card.list-view.aichatlist .task-left .task-left-part-toggle .task-top-part {
  align-items: center;
  /* margin-top: -10px; */
}
.task-list-card.aichatlist .task-list-name{
  padding: 10px;
}
.task-list-card.list-view.aichatlist .task-left .task-bottom-part-full{
  width: 100%;
}
.task-list-card.list-view.aichatlist .task-left .task-bottom-part-full .task-right-static-options{
  width: 100%;
}
.task-list-card.list-view.aichatlist .task-left .task-bottom-part-full .task-right-static-options > div{
  width: 100%;
}
/* .task-list-card.list-view.aichatlist .task-left .task-bottom-part-full .task-right-static-options .multi_inner_wrapper{
  margin-left: auto;
} */
.task-list-card.list-view.aichatlist .task-left .task-bottom-part-full .task-right-static-options .multi_inner_wrapper .assignee-list-wrapper{
  align-items: center;
}
.task-list-card.aichatlist .white-border-box , .task-list-card.list-view.aichatlist .task-left .task-bottom-part-full .task-right-static-options .comman_action_icon{
  order: -1;
}
.task-list-card.list-view.aichatlist .task-assign-section{
  /* margin-top: 25px; */
  margin-top: auto;
}
.wrapper-main{

}
.wrapper-main .left-part , .w-300{
width:300px;
}
.wrapper-main .right-part{
  width: calc(100% - 300px);
}
.comman-vertical-tabs a:hover{
  background-color: var(--color-white-03);
}
.taskListing .aichatTaskListing:last-child{
  margin-bottom: 0px !important;
}
.activet-bg-white-03.active {
  background-color: var(--color-white-03);
}
.activet-bg-white-03.with-on-hover:hover {
  background-color: var(--color-white-03);
}
.ModalCloseIcon{
  top: 0px;
  right: 0px;
}
 .chat-wrapper.WithBorderLeftRight{
      /* border-left: 1px solid transparent;  */
     /* border-right:  1px solid transparent; */
    }
.task-modal-offcanvas-open.modal-open #lock-cards-wrapper .daily-schedule-main{
  width: auto;
  margin: 0;
}
/* ul.dropdown-menu.chat-page li.hr_sepretr:nth-last-child(2)::before{
  display: none;
} */
.assignee-list-wrapper .assignee-box.hr_sepretr:last-child::before{
  display: none;
}
.ps10per{
  padding-left: 10%;
}
.pe10per{
  padding-right: 10%;
}
.provider-screen-modal{
  z-index: 20;
}
.provider-screen-modal .task-list-card.list-view {
  /* margin-bottom:5px; */
}
.absolute-input.hidden{
  display: none;
}
.multi_inner_wrapper.absolute{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  z-index: 8;
}
.multi_image_wrapper.absolutesmall{
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
  z-index: 15;
}
.onboarding-content-scroll-wrapper   .Forbeforednone.with_separator_10:before{
  display: none;
}
.custom-datepicker .react-datepicker, .custom-datepicker.react-datepicker, .custom-datepicker .react-datepicker{
  padding-top: 36px!important;
  border: 1px solid var(--color-white-20);
}
.custom-datepicker.w-100 .react-datepicker-wrapper{
  width: 100%;
}
.custom-datepicker.react-datepicker.pt-0{
  padding-top: 0 !important;
}
.custom-datepicker.react-datepicker .datepicker-label, .custom-datepicker .datepicker-label{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--dropdown-dorder-color);
  line-height: 1.42857143;
  padding: 8px 12px;
  background-color: var(--base-header-color);
  border-radius: 5px 5px 0px 0px;
  white-space: nowrap;
}
.custom-timepicker .custom-datepicker .react-datepicker,
.custom-timepicker .custom-datepicker.react-datepicker,
.custom-timepicker .react-datepicker.react-datepicker--time-only{
  padding: 0px!important;
}
.custom-datepicker .react-datepicker__children-container {
  width: auto;
  margin: 0;
  padding: 0;
}
.custom-datepicker .react-datepicker__navigation{
  /* top: 38px; */
}
body.modal-open .modal-overlay.box-shadow-none{
  box-shadow: none !important;
  display: none !important;
}
.task-sub-list .comman_action_icon.SettingIcon,
.Setting-icon-hover-effect .SettingIcon{
  display: none;
  /* position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%); */
  transform: translateX(-121px);
}
.task-sub-list:hover .comman_action_icon.SettingIcon ,
.task-sub-list .SettingIcon.comman_action_icon.show,
.Setting-icon-hover-effect:hover .SettingIcon{
  display: block;
  transform: translateX(0);
}
.task-sub-list.active .comman_action_icon.SettingIcon svg.c-icons[fill] , .task-sub-list:hover  svg.c-icons[fill]{
  color: var(--color-green);
}

.dim{
  background: #fff;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 1em;
  font-weight: 700;
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
  --min-hotspot-opacity: 0;
}
.dimensionLine{
  stroke: #16a5e6;
  stroke-width: 2;
  stroke-dasharray: 2;
}

#controls {
  position: absolute;
  bottom: 16px;
  left: 16px;
  max-width: unset;
  transform: unset;
  pointer-events: auto;
  z-index: 100;
}


.dimensionLineContainer{
  pointer-events: none;
  display: block;
}

.dimensionLine{
  stroke: #16a5e6;
  stroke-width: 2;
  stroke-dasharray: 2
}
.TopicModalRightPart{
  background-color: var(--calendar-box-bg);
}
.Push-Pin-fill{
  color: #00ff6a;
}
#chatAIFeedInput{
  border: 0;
  padding-left: 10px;
  /* color: var(--color-green)!important; */
  /* color: var(--color-green)!important; */
}
.select-input-cox .check-image g path{
  stroke: #000000;
}
.daily-schedule-main.w340px{
  width: 340px;
}
.comman-round-box-border-color{
  border-color: #2c4250;
  /* stroke: var(--base-font-color); */
}
.btn-inner-w-100 .btn{
  width: 100%;
}
.Push-Pin-fill {
  color: var(--color-green)!important;
}
.transition_03s{
  transition: var(--transiton-3s);
}
.emojiabsolute{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
}
aside.EmojiPickerReact.epr-main{
  position: absolute;
  right: 50px;
  bottom: 10px;
}
.comman-comments .EmojiPickerReact{
  transform: translateX(-60px);
}
.file-parent-box{
  background-color: var(--base-modal-bg);
  color: #FFF;
}
.feed-chat-in-task-modal{
  padding: 15px;
  background-color: var(--base-modal-bg);
  position: sticky;
  bottom: 173px;
  flex-grow: 1;
  display: flex;
  min-height: 52%;
}
.premium-estimate-dots{
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 4;
  /* position: relative; */
}
.file-parent-box.h106px{
  height: 105px;
}
.file-parent-box.withbordertop:before{
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  right: -10px;
  width: calc(100% + 20px);
  height: 1px;
  background-color:var(--color-white-05);
}
.pt6pximp{
  padding-top: 6px !important;
}
.calendarpadding{
  padding-left: 10px;
  padding-right: 10px;
}
.headmemberstatus{
  bottom: 0px;
    right: -4px;
}
.headmemberstatus.right2px{
  right: -2px;
}
.emojiabsolute{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
}
aside.EmojiPickerReact.epr-main{
  position: absolute !important;
  right: 50px;
  bottom: 10px;
  z-index: 1;
  background: var(--white);
}
.epr_-8623pq,
.epr_x558go:focus{
  background: var(--white)!important;
}
.epr_-oj65po{
  padding-top: 10px;
  padding-bottom: 10px;
}
.epr_jkst5z:hover {
  background-color: var(--action-icon-bg)!important;
}
.epr_l33asz {
  background: transparent !important;
}
.messaging-footer-main aside.EmojiPickerReact.epr-main {
  position: fixed;
  right: auto;
  bottom: 50px;
  z-index: 999;
  width: calc(100% - 50px) !important;
  height: calc(100% - 60px) !important;
  box-shadow: var(--box-shadow-dropdown);
}
.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label{
  font-weight: 400;
  font-family: var(--title-family);
  border-bottom: 1px solid var(--color-white-10);
  font-size: var(--space-13);
}
.file-list-wrapper.w100miuns125{
  width: calc(100% - 65px);
  /* width: calc(100% - 125px); */
}
.file-list-wrapper .uploadimages{
  padding-right: 10px;
}
.file-list-wrapper .uploadimages:last-child{
  padding-right: 0px;
}
.w20per{
  width: 20%;
}
.pe10px{
  padding-right: 10px !important;
}
.maxw_80px{
  max-width: 80px;
}
.minw230px.color-blue {
  min-width: 230px;
}
.ListingDashboard{
  width: 1000px  ;
}
.ListingDashboard.px15px{
  padding: 0px 15px;
}
.ListingDashboard .Filterparentdiv a:last-child{
  margin-right: 0px !important;
}
.CardDetail{
  width: calc(100% - 250px);
}
.with-abs-position-bg-taskcard{
  position: absolute;
  max-width: calc(100% - 50px);
  right: 20px;
  justify-content: end;
  background-color: #173141;
}
.list-view-toggle .task-list-card.active .with-abs-position-bg-taskcard {
  background-color: #193343;
}
.list-view-toggle .task-list-card.active .with-abs-position-bg-taskcard:before{
  background: linear-gradient(270deg, #193343 0%, rgb(25 51 67) 93.06%,  rgb(25 51 67 / 61%) 100%);
}
.with-abs-position-bg-taskcard:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  height: 100%;
  width: 100%;
  background: linear-gradient(270deg, #193343 0%, rgb(25 51 67) 93.06%, rgb(25 51 67 / 63%) 100%);
  z-index: 0;
}
.with-abs-position-bg-taskcard.leads {
  background-color: #253d4c;
}
.with-abs-position-bg-taskcard.leads:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  height: 100%;
  width: 100%;
  background: linear-gradient(270deg, rgb(37 61 76) 0%, rgb(37 61 76) 93.06%, rgb(37 61 76 / 63%) 100%);
  z-index: 0;
}
.task-list-card:hover .with-abs-position-bg-taskcard.leads:before {
  background: linear-gradient(270deg, rgb(44 66 80) 0%, rgb(44 66 80) 93.06%, rgb(44 66 80 / 75%) 100%);
}
.task-list-card:hover .with-abs-position-bg-taskcard.leads {
  background: #2c4250;
}
.task-list-card:hover .with-abs-position-bg-taskcard:before {
  background: linear-gradient(270deg, #1f3748 0%, rgb(31 55 72) 93.06%, rgb(31 55 72 / 75%) 100%);
}
.task-list-card:hover .with-abs-position-bg-taskcard {
  background: #1f3748;
}
.liteversion-input-checlist-abs-position{
  position: absolute;
  z-index: 1;
  /* width: calc(100% - 30px); */
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 4px;
  z-index: 8;
}
.modal-open .liteversion-input-checlist-abs-position{
  margin: 0;
  width: calc(100% - 0px);
}
.modal-open .liteversion-input-checlist-abs-position{
  z-index: 2;
}
.liteversion-input-checlist-main{
  min-height: 50vh;
  overflow: auto;
  /* z-index: 99; */
  z-index: 16;
  max-height: 65vh;
}
.liteversion-input-checlist-close_icon{
  z-index: 16;
  /* z-index: 9999; */
  margin-left: auto;
  margin-right: -80px;
  position: absolute;
  top: 0;
  right: -10px;
}
.liteversion-input-checlist-close_icon.space-request-close-btn{
  z-index: 16;
  margin-left: auto;
  margin-right: 0;
  position: absolute;
  top: -44px;
  right: -32px;
}
.maxw200px{
  max-width: 200px;
}
.star-rating-main{
  /* padding: 15px; */
  overflow: auto;
}
.rating-popover .rating-popover-items svg.c-icons[fill] {
  color: var(--color-green);
}
.rating-popover .rating-popover-items.active svg.c-icons[fill] {
  color: #1EBF61;
}
.star-rating-items svg.c-icons[fill]{
  color: var(--color-white-03);
}
.rating-popover .rating-popover-items svg.c-icons[fill] {
  color: var(--color-green);
}
.color-white-03-solid,
svg.c-icons.color-white-03-solid[fill]{
  color: var(--color-white-03-solid);
}
.star-rating-items.active svg.c-icons[fill]{
  color: var(--color-green);
}
.star-rating-items {
  padding: 10px;
}
.overflow-x-auto{
  overflow-x: auto;
}
.light-theme-border{
  border: 1px solid transparent;
}
.dropdown-menu.WithPosition{
  top:5px;
  left: 0px;
}
.feed-chat-main{
  position: absolute;
  left: 30px;
  bottom: 32px;
  z-index: 3;
}
.mb-2px{
  margin-bottom: 2px;
}
.downloadarrow{
  top: 17px;
    right: 60px;
}
.kpi-main-box-shadow {
  box-shadow: -40px 0 20px -8px #1d3545;
}
.scale05{
  transform: scale(0.5);
}
.scale06{
  transform: scale(0.6);
}
.scale07{
  transform: scale(0.7);
}
.scale08{
  transform: scale(0.8);
}
.scale09{
  transform: scale(0.9);
}
.scale1{
  transform: scale(1);
}
.list-view-toggle .task-checklist.activegreenborder{
  border:1px solid var(--color-green);
}
.hotspot-box{
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 100%;
  animation: coccoc-alo-circle-anim 2s infinite ease-in-out;
  z-index: -1;
}

@keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(1.5) skew(1deg);
      opacity: .1
  }
  30% {
      transform: rotate(0) scale(0.3) skew(1deg);
      opacity: .6
  }
  100% {
      transform: rotate(0) scale(0) skew(1deg);
      opacity: .1
  }
}
.search-backdrop {
  position: relative;
}
.search-backdrop:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--base-modal-backdrop-color);
  z-index: 10;
  opacity: 0.8;
}
.h-160px{
  height: 160px;
}
.CardListingWrapper .filterWrapper > a.me-10px:last-child{
  margin-right: 0;
}
.calender-footer-tabs .calender-footer-items{
  border-bottom: 1px solid transparent;
  transition: 0.3s;
}
.calender-footer-tabs .calender-footer-items:hover{
  border-color: var(--color-white-60);
}
.custom-select-menu .css-1f43avz-a11yText-A11yText{
  z-index: 1;
}
.reviews-main{
  background-color: var(--dropdown-bg-color);
  border: 1px solid var(--dropdown-dorder-color);
  color: var(--base-font-color) !important;
  border-radius: 5px;
  font-size: 13px !important;
  width: 280px;
  box-shadow: var(--dropdown-box-shadow-color);
  z-index: 2;
}
.border-bottom-base-body-3px{
  border-bottom: 8px solid var(--base-body-color);
}
.custom-modal-parent-div-flex-property .custom-modal-style{
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-white-03);
  padding: 20px;
}
/* .custom-select-menu.profileSelecth100 .react-select__control{
  height: 100%;
  border: 0;
} */
.audio-call-feed-chat-main{
  position: absolute;
  top: 140px;
  right: 0;
  background-color: var(--calendar-modal-color);
  padding: 10px;
  z-index: 9;
  border-radius: 3px;
  transform-origin: right;
  transition: all 8s;
  z-index: 13;
  min-width: 353px;
}
.max-width-200px{
  max-width: 200px;
}
.audio-call-feed-chat-main .audio-call-feed-chat-on-hover{
  display: flex;
  align-items: center;
  transform-origin: right;
  transition-delay: 5s;
  transition: max-width 0.8s;
  max-width: 353px;
}
.audio-call-feed-chat-main:hover .audio-call-feed-chat-on-hover {
  transform-origin: right;
  transition-delay: 5s;
  transition: max-width 1.5s;
  /* max-width: 300px; */
}
.action-icon-opacity-transparent-bg{
  background: rgb(15 42 60 / 40%);
}
.profile-image-wave{
  height: 100%;
  width: 100%;
  background-color: var(--color-white-03);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.profile-image-wave-main .profile-image-wave:nth-child(1){
  padding: 10%;
}
.profile-image-wave-main .profile-image-wave:nth-child(2){
  padding: 6%;
}
.profile-image-wave-main .profile-image-wave:nth-child(3){
  padding: 4%;
}
.typing-main{
  position: absolute;
  bottom: 0;
  padding: 5px 10px;
  backdrop-filter: blur(50px);
}
.typing {
  display: flex;
}

.typing__dot {
  width: 6px;
  height: 6px;
  margin: 0 2px;
  background: var(--base-font-color);
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.5s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}


.ScreenMinHeight{
  height: calc(100vh - 250px);
}

.comman-content-scroll.aichatfullscroll > div{
  /* max-height: calc(100vh - 300px) !important; */
  /* width: calc(100vw - 390px) !important; */
  min-height: 150px !important;
}
.comman-content-scroll.aichatfullscroll > div > div > div{
  overflow: hidden  !important;
}

#message-list > div > div > div > div{
  overflow: scroll !important;
}

.transformX-0{
  transform: translateX(0) !important;

}

.task-sub-list .comman_action_icon.SettingIcon, .Setting-icon-hover-effect .SettingIcon.active svg{
  color: var(--color-green);
}
.radius10{
  border-radius: 3px;
}
.radius10imp{
  border-radius: 10px!important;
}
.square_box .pin-office{
  top: 40px;
  left: -5px;
}

.active .green_icon
{
  color: #00ff6a;
}
.registrationHeader .after-login-header{
  background-color: transparent;
  height: auto;
  padding: 20px 30px 20px 30px;
}
.registrationHeader .after-login-header .left-part , .registrationHeader .after-login-header .center-part , .registrationHeader .after-login-header .right-part{ 
  width: 33.33%;
}
.registrationHeader .after-login-header .center-part {
  transform: translate(40px, 0);
}
.registrationHeader .after-login-header .center-part.transform0{
  transform: translate(0 , 0);
}
.registrationHeader .ps80{
  padding-left: 80px;
}
.registrationHeader .extranlthemebtn{
  left: 30px;

}
.RegNew .regNewcontent{
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;
  margin-top: 20px!important;
  height: calc(100% - 20px)!important;
}
.RegNew .regNewcontent.mt-0{
  margin-top: 0 !important;
}
/* glow-shadow  */
.glow-shadow .comman-body-wrapper.modified-height{
  overflow: auto;
  height: calc(100vh - 70px);
}
.glow-shadow .comman-body-wrapper.modified-height.h100vh{
  height: 100vh;
}
.glow-shadow-img{
  display: none;
  z-index: -1;
}
body.glow-shadow{
  /* padding-left: 0; */
  padding-right: 0;
  padding-bottom: 0;
}
/* glow-shadow  */
.c-input-box .inputWrapper{
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-white-10-solid);
}
.c-input-box .inputBeforeBox{
  width: 50px;
}
.c-input-box .inputBeforeBox.w90px{
  width: 90px;
}
.c-input-box .form-control.regFormInput , .PhoneInputInput{
  background-color: transparent;
    border: 0;
    padding: 4px 20px;
    font-size: 24px; 
    font-family: var(--title-family); 
}
.c-input-box .form-control.regFormInput.withBorderLeft , .PhoneInputInput{
  border-left: 1px solid var(--color-white-10-solid);
}
.custom-select-menu.regSelect{

}

.custom-select-menu.regSelect .react-select__control{
  background-color: transparent;
  height: 30px;
}
.custom-select-menu.regSelect .react-select__value-container{
  padding: 0px;
  font-size: 22px;
  font-family: var(--title-family);
}
.custom-select-menu.regSelect .react-select__input-container{
  margin: 0;
}
.custom-select-menu.regSelect .react-select__option{
  font-size: 22px;
  font-family: var(--title-family);
}
.w600px{
  width: 600px;
}
.typing-with-profile.typing-main {
  background: var(--color-white-20);
  padding: 3px 3px;
  border-radius: 30px;
  position: absolute;
  bottom: 0px;
  right: -5px;
  box-shadow: 0 0 1px 2px var(--base-header-color);
}
.typing-with-profile.typing-main .typing-text{
  display: none!important;
}
.typing-with-profile.typing-main .typing .typing__dot {
  width: 4px;
  height: 4px;
  margin: 0 1px;
}
.registrationHeader .custom-select-menu .react-select__control{
  background-color: transparent;
}
.list-styl-disc{
  padding-left: 2rem;
}
.list-styl-disc li{
  list-style-type: disc;
}
.map-shadow{
  height: 100px;
  width: 100%;
  position: absolute;
  z-index: 3;
  /* background: linear-gradient(180deg, rgb(15 42 60 / 90%) 35%, rgb(15 42 60 / 90%) 65.06%, rgb(15 42 60 / 15%) 100%); */
  /* background: linear-gradient(180deg, rgb(255 255 255 / 90%) 35%, rgb(255 255 255 / 90%) 65.06%, rgb(255 255 255 / 15%) 100%); */
  position: absolute;
  top: -57px;
  }


  .scrollheight{
    height: calc(100vh - 60px) !important;
  }
  .sloar-scrollheight{
    height: calc(100vh - 200px) !important;
  }
  .react-tel-input .form-control , .react-tel-input .flag-dropdown{
    background: transparent !important;
    border-color:transparent !important;
    font-size: 22px !important;
    /* padding-left: 60px !important; */
  }
  .react-tel-input .form-control{
    padding-left: 60px !important;
    text-align: center !important;
    width: auto !important;
  }
  .react-tel-input .flag-dropdown.open{
    background: transparent  !important;
  }
  .react-tel-input .flag-dropdown {
    /* left: 10% !important; */
  }

  .react-tel-input .country-list{
    background-color: var(--base-header-color) !important;
    box-shadow: none !important;
    margin-top:0px !important;
    font-size: 14px !important;
    top: 50px !important;
  }
  .react-tel-input .country-list .country:hover , .react-tel-input .country-list .country.highlight{
    background-color: var(--color-white-03) !important;
  }
  .react-tel-input .country-list .country{
    padding: 8px 12px;
    text-align: left;
  }

  .react-tel-input .selected-flag .flag{
    transform: scale(1.5);
  }
  .react-tel-input .selected-flag{
    padding-left: 0 !important;
  }
  .react-tel-input .selected-flag .arrow {
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-top: 5px solid var(--color-green) !important;
    margin-left: 5px !important;

  }
  .react-tel-input .selected-flag .arrow.up{
    border-top: none !important;
    border-bottom: 5px solid var(--color-green) !important;
  }
  .flaginput .react-tel-input .selected-flag .arrow{
    border-top-color: #000645 !important;
  }
  .flaginput .react-tel-input .selected-flag .arrow.up{
    border-bottom-color: #000645 !important;
  }
  .react-tel-input .flag-dropdown.open .selected-flag , .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background: transparent !important;
  }
  .react-tel-input .country-list .divider{
    border-color: var(--color-white-05) !important;
  }
  .box-shadow-none{
    box-shadow: none !important;
  }
  .registration-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 17;
    transition: var(--transiton-3s);
    background-color: transparent;
    height: 70px;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    background-color: #ffffff;
  }
  .task-modal-offcanvas-open .RegNewFlow .registration-header,
  .task-modal-offcanvas-open .RegNewFlow .registration-step-header,
  .task-modal-offcanvas-open .RegNewFlow .modal-opne-d-none-div{
    display: none !important;
  }
  .task-modal-offcanvas-open .RegNewFlow .modal-opne-w-full-div{
    width: 100% !important;
  }
  .registration-header .center-part{
    /* transform: translate(30px, 0); */
    /* width: 33.33%; */
    width: auto;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .with-left-penel .registration-header .center-part{
    /* transform: translate(30px, 0); */
    width: 33.33%;
    transform: translate(-25%, -50%)!important;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .mt-65px{
    margin-top: 65px;
  }
  .spinner-border-static{
    border: 2px solid var(--color-white-05);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
  }
  .react-tel-input .selected-flag .arrow{

  }
  .mt-30px{
    margin-top: 30px;
  }
  .mb-30px{
    margin-bottom: 30px;
  }
  .mapareabox{
    position: absolute;
    top: 60px;
    left: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    width: 515px;
  }
  .arealist{
    max-height: 120px;
    overflow: hidden auto;
  }
  .w195h110{
    width: 195px;
    height: 110px;
  }
  .py-30px{
    padding: 30px 0px;
  }
  .mb-70{
    margin-bottom: 70px;
  }
  .cblue70{
    /* color: rgb(0 6 70 / 70%); */
    color: #000000;
  }
  .h100px{
    height: 100px;
  }
  .mb-70{
    margin-bottom: 70px;
  }
  .absolutebtn {
    position: absolute;
    right: 0;
}
.iconabsolute {
  position: absolute !important;
  left: 0;
}
.Regnewphone .react-tel-input .form-control{
  padding-left: 55px!important;
  padding-right: 40px!important;
  text-align: center!important;
  border: 0!important;
  box-shadow: none!important;
  width: 100%!important;
  font-family: var(--title-family)!important;
}
.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -5px;
}
.react-tel-input .selected-flag .flag {
  transform: scale(1.5);
}
.react-tel-input .flag-dropdown, .react-tel-input .form-control {
  background: transparent!important;
  border-color: transparent!important;
  font-size: 22px!important;
}
.Regnewphone .react-tel-input .flag-dropdown{
  left: 0 !important;
}

.spinner-border-sm20 {
  height: 20px;
  width: 20px;
}
.z-index-22{
  z-index: 22 !important;
}
  /* .rangeSlider{
    position: absolute;
    top: 100px;
    right: 50px;
    background-color: red;
    width: 10px;
    height: 200px;
    z-index: 5;
  } */
  
  .single-thumb .range-slider__thumb[data-upper] {
    width: 0 !important;
    height: 0 !important;
  }
  .single-thumb .range-slider__range {
    border-radius: 6px !important;
  } 
.rangeSlider{
  position: absolute;
  width: 50px;
  background: #ffffff;
  z-index: 5;
  top: 50px;
  right: 20px;
  height: 350px;
  border-radius: 10px;
  
}
.rangeslider, .rangeslider .rangeslider__fill{
  box-shadow: none !important;
}
.rangeslider{
  background: rgba(0, 0, 0, 0.23) !important;
}
.rangeslider-vertical{
  height: 300px !important;
  position: absolute !important;
  left: 20px !important;
  top: 50% !important;
  transform: translateY(-50%);
  z-index: 5 !important;
  background: #e5e4e4 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 0px !important;
}
.rangeslider-vertical .rangeslider__fill{
  background: linear-gradient(to top, #ffeb3b, #4caf50) !important; 
  border-radius: 500px !important;
}
.range-slider .range-slider__thumb{
  background: #cccccc !important;
  border:1px solid #cccccc;
  height: 18px !important;
  width: 18px !important;
}

.rangeslider-vertical .rangeslider__handle {
  height: 18px !important;
  width: 18px !important;
  border-radius: 500px !important;
  left: -4px !important;
}

.rangeslider__labels .rangeslider__label-item{
  font-size: 10px !important;
  font-family: var(--title-family);
  color: var(--color-black);
  padding: 5px;
  border-radius: 3px;
  top: -48px !important;
  background-color: var(--lemon-yellow);
  margin-left: 5px;
}
.rangesliderhorizontal{
  padding-left: 25px;
  padding-right: 37px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item{
  /* transform: translate3d(0, 0%, 0) !important; */
}
.rangeslider-horizontal .rangeslider__labels .rangeslider__label-item:before{
  width: 1px;
  height: 8px;
  background: #000;
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  z-index: -1;
}
.react-tel-input .country-list .search
{
  padding: 10px 10px 10px 10px !important;
  background-color: #1c3544!important;
}

.react-tel-input .country-list .search-box {
  margin-left: 0px !important;
  background-color: var(--white-03);
  border: 1px solid var(--color-white-10) !important;
}

.comman_action_icon .action_icon.h55w55{
  height: 55px;
  width: 55px;
}
.right-20{
  right: 20px;
}
.bottom-20{
  bottom: 20px;
}

.office-subtitle.f-9px
{
  font-size: 9px;
}

.w100minus33 {
  width: calc(100% - 33px);
}

.mapareabox.right_panel {
  position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    border-radius: 10px;
    width: auto;
    z-index: 1;
    border: 1px solid transparent;
    background: transparent;
    gap: 10px;
    overflow-y: auto;
    justify-content: center;
}
.gap10{
  gap: 10px;
}
.minw250px{
  min-width: 350px;
}
.minw378px{
  min-width: 378px;
}
.maxw378{
  max-width: 378px;
}
.c-font.f-38 {
  font-size: 38px;
}
.rangesliderhorizontal .rangeslider-horizontal .rangeslider__fill{
  background-color: #000645;
}
.rangesliderhorizontal, .rangesliderhorizontal .rangeslider__fill{
 box-shadow: none; 
}
.rangeslider-horizontal .rangeslider__handle{
  background: #ffffff !important;
  border-color: #ffffff !important;
  height: 24px !important;
  width: 24px !important;
  box-shadow: none !important;
  border: 1px solid transparent;
  fill: #FFF;
filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 6px rgba(156, 163, 175, 0.35));
}
.rangeslider-horizontal .rangeslider__handle:after{
  display: none;
}
.rangeslider-horizontal{
  height: 8px !important;
}
.pt1px{
  padding-top: 1px;
}
.pt1px{
  padding-top: 1px;
}
.info_card,
.info_card_left_position {
        position: relative;
        right: inherit;
        bottom: inherit;
        max-width: 100%;
        width: 100%;
        margin-top: 30px;
    }

.info_name{
  background-color: var(--white);
  box-shadow: 0px 7px 11px 0px rgba(0, 0, 0, 0.25);
}

.f_light-black{
  color: var(--light-black);
}

.f_black70{
  color: var(--black70);
}

.info_intro
{
  max-width: 300px;
  background-color: transparent;
}

.card_img{
  background-image: url(../images/user-placeholder.jpg);
  width: 50px;
    height: 50px;
    background-size: cover;
}
.h180px{
  height: 180px;
}
.top-270{
  top:270px;
}
.mapareabox_img{
  width: 100px;
  height: 100px;
}

.suxess-page{
  width: 33.33%;
}

.panel_img{
  width: 62%;
}
.light-theme .glow-shadow .fixed-left-panel .task-sub-list.active {
  color: var(--color-green);
  background-color: #272727;
}
.staff-user-bg{
  background-color: var(--base-font-color) !important;
  color: #000 !important;
  color: #000 !important;
}
.light-theme .glow-shadow .fixed-left-panel .staff-user-bg .color-white-80{
  color: #000;
}
.react-tel-input-small .react-tel-input .form-control{
  font-size: 14px!important;
}
.react-tel-input-small .react-tel-input .flag-dropdown{
  left: 15px!important;
}
.react-tel-input-small .react-tel-input .form-control{
  padding-left: 65px !important;
  width: 100%!important;
  text-align: left!important;
}
.react-tel-input-small .react-tel-input .selected-flag .arrow{
  left: 12px;
}
.maxw100px{
  max-width: 100px;
}
.space-select-box.select-input-cox.w150px{
  width: 150px;
}
.badge-custom.right0px
{
  right: 0px;
}
.task-modal-offcanvas-open  .open-modal-header .right-part{
  flex-grow: 0!important;
  min-width: 20%;
}
.task-modal-offcanvas-open  .open-modal-header .center-part a{
  padding: 5px 0px !important;
}
.count-box .activeorangeborder.border,
.count-box .activegreenborder.border,
.count-box .activeblueborder.border{
  height: 100%;
}
.count-box .activeorangeborder.border .c-font.f-24,
.count-box .activegreenborder.border .c-font.f-24,
.count-box .activeblueborder.border .c-font.f-24{
  font-size: 28px;
}
.count-box .activeorangeborder.border .c-font.f-10,
.count-box .activegreenborder.border .c-font.f-10,
.count-box .activeblueborder.border .c-font.f-10{
  font-size: 12px;
}
.maxhw100{
  max-height: 90px;
  max-width: 90px;
  height: auto;
  width: auto;
}
.light-theme .hovereffectleftmenu:hover {
  background-color:var(--leftpenal-header-color-95) !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .hovereffectleftmenu svg.c-icons[fill]{
  color: #fff !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .hovereffectleftmenu .on-hover-active-toggle-img:hover .office-subtitle{
  color: var(--white) !important;
}
.light-theme .hovereffectleftmenu:hover svg.c-icons[fill] , .light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .hovereffectleftmenu:hover svg.c-icons[fill]{
  color: #fff !important;
}
.light-theme .hovereffectleftmenu:hover .color-white-80 {
  color: #000 !important;
}
.light-theme  .ratingabsstar{
      position: absolute;
      top: 55%;
      right: 20px;
}


.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .on-hover-active-toggle-img:hover.c-three-dots  svg.c-icons[fill]{
  color: var(--white) !important;
}
.light-theme .glow-shadow .fixed-left-panel .comman-round-box.active{
  background-color: var(--leftpenal-header-color-95) !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel.office-list.intro-office-list .hovereffectleftmenu.comman-round-box.active svg.c-icons[fill]{
  color: #fff !important;
}
.light-theme .glow-shadow .fixed-left-panel.office-list , .fixed-left-panel.office-list .fixed-left-panel-wrapper{
  /* width: 70px !important; */
}
.h70px{
  height: 70px;
}
.light-theme .glow-shadow .fixed-left-panel .office-box:hover{
  background-color: #272727;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .comman_action_icon .action_icon.with_bg.lightgrey  {
  background-color: var(--leftpenal-header-color-95);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .searchInputLeft > div{
  background-color: var(--color-white-05);
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .comman_action_icon .action_icon.lightgrey svg.c-icons[fill]{
  color: var(--white) !important;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel  .on-hover-active-toggle-img:hover .action_icon.lightgrey svg.c-icons[fill] {
  color: var(--white) !important ;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .searchInputLeft{
  height: 50px;
}
.light-theme .glow-shadow.solar-login.with-left-penel .registration-header > div .action_icon.with_bg.ms100px{
  margin-left: 350px;
}
.BigPill{
  padding: 8px 15px;
  background-color: var(--black-001);
  border: 1px solid var(--black-op01);
  border-radius: 5px;
  min-width: 130px;
}
.notificationlist:hover{
  background-color: var(--alice-blue-opa53);
}
.notificationlist.unread{
  background-color: var(--alice-blue-opa53);
}
.popover.box-shadowpopover{
  box-shadow: var(--top-calendar-shadow);
}
.WithoverlayRight:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgb(255 255 255 / 10%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 3;
}
.Absolutecomingsoon{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:4;

}
.commanbgwithborder{
  background-color: var(--black-001);
  border: 1px solid var(--black-op01);
}
.bgblackop002 , 
.light-theme .glow-shadow .form-control.file-upload , 
.light-theme .glow-shadow .upload-box{
    background-color: var(--black-002);
}

.fc-daygrid-day{
  height: 132px !important;
  width: 144px !important; 
}
.registration-header a.btn-sm.btn-primary{
  color: var(--color-white-03-solid);
}
.bordertop0imp{
  border-top: 0 !important;
}
.btn-success{
  background-color: var(--radium_green);
  border-color: var(--radium_green);
  color: var(--white)!important;
}
.light-theme .glow-shadow .btn-success svg.c-icons[fill] , .light-theme .glow-shadow .btn-danger svg.c-icons[fill],
.light-theme .glow-shadow .RegNewFlow .btn-success svg.c-icons[fill] , .light-theme .glow-shadow .RegNewFlow .btn-danger svg.c-icons[fill]
{
  color: var(--white);
}
.btn-danger{
  background-color: var(--redbtn);
  border-color: var(--redbtn);
}
.btn-success:hover{
  background-color: transparent ;
  border-color: var(--radium_green);
  color: var(--color-black)!important;
}
.btn-danger:hover{
  background-color: transparent;
  border-color: var(--redbtn);
}
.light-theme .glow-shadow .btn-success:hover svg.c-icons[fill] , .light-theme .glow-shadow .btn-danger:hover svg.c-icons[fill]{
  color: var(--color-black);
}
.light-theme .glow-shadow .uploadabsimg > div{
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.light-theme .glow-shadow .uploadabsimg > div > canvas{
  height: 90%;
  width: 100%;
}
.registration-footer-content.w_1120px{
  width: 1120px;
}
.h90vh{
  height: 90vh;
}
.custom-modal-style.h90vh.modal-content100per .modal-content ,
.custom-modal-style.h90vh.modal-content100per .modal-dialog  {
  height: 100%;
}
.custom-datepicker.fw-medium .react-datepicker-wrapper .react-datepicker__input-container input{
  font-weight: 500;
}
.h50w150{
  height: 50px;
  width: 150px;
}
.top160px {
  top: 140px;
}
.timelinemain{
  border: 1px solid var(--black-op10);
  border-bottom: 1px solid transparent;
  padding: 10px 20px;
}
.timelinemain:last-child{
  border-bottom: 1px solid var(--black-op10);
}
.timelinemain:before{
  content: "";
  width: 2px;
  background-color: var(--color-black);
  position: absolute;
  left: -1px;
  height: calc(100% + 2px);
  top: 0px;
}
.timelinemain:after{
  content: "";
  position: absolute;
  top: 20px;
  left: -8px;
  background-color: var(--lemon-yellow);
  height: 15px;
  width: 15px;
  border-radius: 500px;
}
/* .timelinemain:last-child:before{
  display: none;
} */
 .gap1px{
  gap: 1px;
  display: grid;
 }
 .light-theme .RegNewFlow .customFieldsWrapper .form-control, .light-theme .RegNewFlow .customFieldsWrapper textarea{
  font-weight: var(--fw-regular);
 }
 .light-theme .custom-accordian-main .accordion-header.active.not-started:before, .light-theme .custom-accordian-main .accordion-header.active.orange:before ,
 .light-theme .custom-accordian-main .accordion-header .left-task-header .c-dots.not-started {
  background: var(--color-warning);
  z-index: 1;
}
.light-theme .custom-accordian-main .accordion-header.active.ongoing:before , 
.light-theme .custom-accordian-main .accordion-header .left-task-header .c-dots.ongoing {
  background: #fde400;
  z-index: 1;
}
.light-theme .custom-accordian-main .accordion-header .left-task-header .c-dots.in-progress{
  background:var(--color-blue);
  z-index: 1;
}
.light-theme .custom-accordian-main .accordion-header .left-task-header .c-dots.to-do{
  background: var(--inprogress-status);
z-index: 1;
}