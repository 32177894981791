/*Comman DAtepicker */
.datepicker-hidden-input {
}
.datepicker-hidden-input .react-datepicker-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.datepicker-hidden-input .react-datepicker__input-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.custom-datepicker .react-datepicker-popper {
  z-index: 12;
}
.custom-datepicker.with-fixed-pos .react-datepicker-popper{
  position: fixed !important;
}
.custom-datepicker.h_20px{
  height: 20px;
}

.custom-datepicker .react-datepicker__header {
  background-color: var(--dropdown-bg-color);
  color: var(--base-font-color);
  border-bottom: 0px;
  border-bottom: var(--color-white-10) 1px solid;
}
.custom-datepicker .react-datepicker__time-container {
  border-left: var(--color-white-10) 1px solid;
}
.custom-datepicker .react-datepicker__time-container .react-datepicker__time {
  background-color: var(--dropdown-bg-color);
}
.custom-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: var(--color-white-07);
  transition: var(--transiton-3s);
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: auto;
}
.custom-datepicker .react-datepicker,
#custom_datepicker .custom-datepicker.react-datepicker {
  background-color: var(--dropdown-bg-color);
  border: 1px solid var(--dropdown-dorder-color);
  color: var(--base-font-color);
  border-radius: 5px;
  font-family: var(--base-family);
  /* font-weight: 300; */
  padding-right: 1px;
  /* width: 330px; */
  width: auto;
  font-size: var(--base-font);
}
#custom_datepicker .react-datepicker-popper{
  z-index: 1060;
}
.custom-datepicker.datepicker_w_100 .react-datepicker{
  width: 100%;
}
.custom-datepicker.w-330px .react-datepicker{
  width: 330px;
  padding-left: 0;
}
.custom-datepicker .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  opacity: 0.6;
  user-select: none;
  background-color: transparent !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: var(--base-header-color);
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: var(--left-panel-bg);
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
  border-top-color: var(--left-panel-bg)
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: var(--color-white-03);
}
.custom-datepicker .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-weight: 700;
  font-size: 15px;
  font-family: var(--title-family);
}
.custom-datepicker .react-datepicker__navigation-icon {
  position: relative;
  top: 5px;
}
.custom-datepicker .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: var(--color-white) !important;
  font-family: var(--title-family);
}
.custom-datepicker .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
}
.custom-datepicker .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: var(--color-white) !important;
  font-size: 12px;
}
.custom-datepicker
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: var(--color-white-10);
  border-radius: 500px;
}
.custom-datepicker .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: var(--color-white-07);
  border-radius: 500px;
  transition: var(--transiton-3s);
}
.custom-datepicker .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
  background-color: var(--color-white-07);
  border-radius: 500px;
}
/* .custom-datepicker{
  width:100%;
} */
.custom-datepicker
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  background: transparent;
  border: 0;
  padding-left: 5px;
  height: 100%;
  cursor: pointer;
}
.react-datepicker-wrapper-w-100 .react-datepicker-wrapper{
  width: 100%;
}
.custom-datepicker .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown{
  background-color: var(--dropdown-bg-color);
  border:1px solid var(--color-white-10);
}
.custom-datepicker .react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option{
  padding:5px 0px;
  border-bottom:1px solid var(--color-white-10);
}
.custom-datepicker .react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover{
  background-color: var(--color-white-05);
}
.custom-datepicker .react-datepicker__year-option.react-datepicker__year-option--selected_year{
  background-color: var(--color-white-10);
}
.custom-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--color-white-05);
  font-weight: var(--fw-semibold);
}
.custom-datepicker .react-datepicker__year-option .react-datepicker__navigation{
  height: 20px;
  width:100%;
}
.custom-datepicker .react-datepicker__year-option .react-datepicker__navigation.react-datepicker__navigation--years-previous{
  background-image: url(../images/ic-caret-down-fill-white.svg);
  background-position: top -3px center;
  background-size: 20px;
  background-repeat: no-repeat;
  top: 4px;
}
.custom-datepicker .react-datepicker__year-option .react-datepicker__navigation.react-datepicker__navigation--years-upcoming{
  background-image: url(../images/ic-caret-down-fill-white.svg);
  background-position: top -3px center;
  background-size: 20px;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  top: -2px;
}
.custom-datepicker.max-w-100px .react-datepicker-wrapper .react-datepicker__input-container input {
  max-width: 100px;
}
.swal-modal2 {
  background: var(--base-body-color) !important;
  border: 1px solid var(--color-border);

}
/*swal alert*/
.swal-modal {
  background-color: var(--left-panel-bg);
  border: none;
}
.swal-button {
  background: var(--color-white-10);
  color: var(--base-font-color);
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}
.swal-button:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg) !important;
  border-color: 1px solid var(--color-white-10);
  transition: var(--transiton-3s);
}
.swal-button:focus {
  outline: none;
  box-shadow: none;
}
.swal-title {
  font-family: var(--title-family) !important;
  color: var(--base-color) !important;
}
.swal-text {
  font-family: var(--base-family) !important;
  color: var(--base-color);
}
.swal-footer {
  text-align: center !important;
}
/*Full calednar*/
.custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-unread-button{
  background-color: var(--color-white-05);
  color: var(--base-font-color);
  padding-left: 30px;
  margin-right: 20px;
  border:1px solid transparent !important;
}
.custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-unread-button.active{
  border:1px solid var(--color-green-50) !important;
}
.custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-unread-button:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0px;
  height: 15px;
  width: 33px;
  background-size: 18px;
  background-position: center 10px;
  background-repeat: no-repeat;
  height: 100%;
  background-image: url('../images/ic-chat-circle.svg');
}
.custom-full-calendar .fc .fc-cell-shaded, .fc .fc-day-disabled{
  background: var(--color-white-03)!important ;
  z-index: -1;
  position: relative;
}
.custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-count-button {
  position: absolute;
  top: -6px;
  left: 90px;
  background-color: var(--color-green);
  font-size: 9px;
  line-height: 16px;
  width: 17px;
  height: 17px;
  display: inline-block;
  color: #000;
  border-radius: 60%;
  text-align: center;
  padding: 0;
}
.custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-count-button:hover,
.custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-count-button:focus {
  background-color: var(--color-green)!important;
}
.custom-full-calendar {
  height: 100%;
  /* overflow: hidden auto; */
}
.custom-full-calendar .fc-event-main{
  color: var(--color-black) !important;
}
.custom-full-calendar .fc-timegrid-more-link-inner{
  color: var(--color-black)
}
.custom-full-calendar .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link{
  box-shadow:none !important;
  margin-right: 5px;
}
.custom-full-calendar .fc .fc-toolbar.fc-header-toolbar {
  /* display: none; */
  position: sticky !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--base-modal-bg);
  /* z-index: 5; */
  z-index: 8;
  margin-bottom: 0;
  padding: 10px;
}
.custom-full-calendar .fc-theme-standard .fc-scrollgrid thead{
  position: sticky !important;
  top: 60px;
  /* top: 0px; */
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--base-modal-bg);
  /* z-index: 5; */
  z-index: 8;
  /* display: none; */
}
.calendar-box-bg{
  background-color: var(--calendar-box-bg);
}
.custom-full-calendar .fc-theme-standard .fc-scrollgrid thead .fc-scrollgrid-sync-inner{
  /* border-top: 1px solid var(--color-white-05)!important; */
}
/* .custom-full-calendar .fc-event{
  height: 20px !important;
} */
/*week View*/
 .custom-full-calendar .fc-dayGridWeek-view .fc-scrollgrid-section-body .fc-scroller .fc-daygrid-body .fc-scrollgrid-sync-table{
  /* height: calc(100vh - 105px); */
  height: calc(100vh - 295px);
 }
 .custom-full-calendar .fc-dayGridWeek-view .fc-scrollgrid-section-body .fc-scroller .fc-daygrid-body .fc-scrollgrid-sync-table tbody{
  height: 100%;
 }
 .custom-full-calendar .fc-dayGridWeek-view .fc-scrollgrid-section-body .fc-scroller .fc-daygrid-body .fc-scrollgrid-sync-table tbody tr{
  height: 100%;
 }
/*day View*/
  .custom-full-calendar .fc-dayGridDay-view .fc-scrollgrid-section-body .fc-scroller .fc-daygrid-body .fc-scrollgrid-sync-table{
    height: calc(100vh - 295px);
    /* height: calc(100vh - 105px); */
  }
  .custom-full-calendar .fc-dayGridDay-view .fc-scrollgrid-section-body .fc-scroller .fc-daygrid-body .fc-scrollgrid-sync-table tbody{
   height: 100%;
  }
  .custom-full-calendar .fc-dayGridDay-view .fc-scrollgrid-section-body .fc-scroller .fc-daygrid-body .fc-scrollgrid-sync-table tbody tr{
   height: 100%;
  }
/*end*/
.custom-full-calendar .fc-v-event .fc-event-main-frame{
  flex-direction: row;
  font-weight: 600;
}
.custom-full-calendar .fc .fc-toolbar-title {
  color: var(--base-font-color);
  font-size: 15px;
  font-family: var(--title-family);
  opacity: 1;
  text-transform: capitalize;
}
.custom-full-calendar .fc-theme-standard .fc-popover {
  background: var(--base-body-color);
  border: 1px solid var(--color-border);
  border-radius: 3px;
  z-index: 10;
}
.custom-full-calendar .fc .fc-button-group .fc-button-primary {
  padding: 9px 15px;
  border: 1px solid var(--color-white-10);
  color: var(--base-font-color);
  opacity: 1;
  text-transform: capitalize;
  font-family: var(--title-family);
}
.custom-full-calendar .fc .fc-button-primary {
  background-color: transparent;
  border: 1px solid transparent;
}
.custom-full-calendar .fc .fc-button-primary.fc-prev-button {
  border: 1px solid transparent;
  padding: 9px 5px;
}
.custom-full-calendar .fc .fc-button-primary.fc-next-button {
  border: 1px solid transparent;
  padding: 9px 5px;
}
.custom-full-calendar .fc .fc-button-primary:hover {
  background-color: var(--color-white-05);
  border: 1px solid transparent;
  transition: var(--transiton-3s);
}
.custom-full-calendar .fc .fc-button-primary:not(:disabled):active {
  background-color: var(--color-white-05);
  box-shadow: none;
  border: 1px solid transparent;
}
.custom-full-calendar .fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}
.custom-full-calendar .fc .fc-button-primary:focus {
  box-shadow: none;
}
.custom-full-calendar .fc .fc-button-primary.fc-today-button {
  background-color: var(--color-white-05);
  color: var(--base-font-color);
  opacity: 1;
  text-transform: capitalize;
  font-family: var(--title-family);
  padding: 9px 15px;
  border: 1px solid transparent;
}
.custom-full-calendar .fc .fc-button-primary.fc-button-active {
  background-color: var(--color-white-05);
  border: 0px;
}
.custom-full-calendar .fc .fc-button-primary.fc-button-active {
  background-color: var(--color-white-05);
  border: 0px;
  box-shadow: none;
}
.custom-full-calendar .fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: transparent !important;
  border: 0px !important;
}
.custom-full-calendar .fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none !important;
}
.custom-full-calendar .fc .fc-col-header-cell-cushion {
  padding: 10px 5px;
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
 color:  rgba(15, 42, 60, 0.70);
}
.custom-full-calendar .fc-h-event .fc-event-title{
  color: var(--color-black);
}
.custom-datepicker .fc-daygrid.fc-dayGridMonth-view.fc-view {
  font-family: var(--base-family);
}

.custom-full-calendar .fc-theme-standard .fc-scrollgrid {
  border: 1px solid transparent;
  border: 0;
}
.custom-full-calendar .fc-theme-standard th {
  border: 0 !important;
}
.custom-full-calendar .fc-theme-standard .fc-scrollgrid thead{
  /* border-bottom: 1px solid var(--color-border)!important; */
}

.custom-full-calendar .fc-theme-standard td {
  /* border: 1px solid var(--color-white-07); */
  border-bottom: 0;
}
.fc .fc-daygrid-day-frame:hover {
  background-color: var(--color-white-03);
}

.fc .fc-daygrid-day-frame.show {
  background-color: var(--color-white-03-solid);
}
.fc .fc-more-popover .fc-popover-body{
  max-height:20vh;
  overflow:auto;
}
.custom-full-calendar .fc .fc-daygrid-day-number {
  padding: 15px;
}
.custom-full-calendar .fc .fc-daygrid-day.fc-day-today {
  background-color: var(--color-white-03);
}
.custom-full-calendar .fc-myCustomButton-button {
  background-image: url(../images/ic-list-white.svg);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
.custom-full-calendar .fc .fc-button-primary:focus-visible {
  border: 1px solid transparent !important;
}
.custom-full-calendar .fc-button {
  box-shadow: none !important;
}
.custom-full-calendar .fc-button {
  border: 0px !important;
}
/* Comman react table  */
.comman-data-table .eSTlnH {
  background-color: transparent;
}
.comman-data-table .gZWIKX {
  background-color: var(--color-white-03);
  min-height: 42px;
}
.comman-data-table .idPHNo {
  color: var(--base-font-color);
  font-family: var(--title-family);
  font-weight: var(--fw-semibold);
  font-size: 14px;
}
.comman-data-table .jVeafb {
  background: transparent;
  font-size: 14px;
  color: var(--base-font-color);
  border-bottom: 0 !important;
}
.comman-data-table .jfrgbA {
  padding: 15px 20px;
}
.comman-data-table .jxflYm {
  background: var(--color-white-03);
  color: white;
  border: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 3;
  min-height: 42px;
}
.comman-data-table .jMMJgk ,
.comman-data-table .lazlwA ,
.comman-data-table .sc-bmzYkS , 
.comman-data-table .sc-ezWOiH,
.comman-data-table .sc-eACynP,
.comman-data-table .ihCgAW,
.lazlwA, .sc-bmzYkS{
  filter: invert(1)!important;
}
.comman-data-table .fwKvpK {
  background: var(--color-white-03);
  color: var(--base-font-color);
}
.comman-data-table .iTdiYx {
  max-width: inherit;
}
.comman-data-table .joGKUm {
  min-width: auto;
  max-width: inherit;
}
.comman-data-table .kJrhuj option {
  background: var(--base-body-color);
  color: var(--base-font-color);
  padding: 10px;
}
.comman-data-table .kJrhuj {
  background-color: var(--color-white-05);
  border-radius: 3px;
  margin-left: 15px;
}
/* Comman Data table stgging style over */
.comman-data-table .rdt_Table {
  background-color: transparent;
}
.comman-data-table .WQNxq {
  overflow-y: auto !important;
  overflow: auto !important;
  overflow-x: hidden !important;
  height: 100%;
}
.comman-data-table .rdt_TableHead {
  position: sticky;
  top: 0;
  background-color: var(--bg-white-hex-03);
  width: 100%;
  z-index: 8;
}
.comman-data-table .rdt_TableHeadRow {
  background-color: var(--color-white-03);
  min-height: 42px;
}
.comman-data-table .rdt_TableHeadRow .bWToSS span.sc-kFuwaP{
  display: none;
}
.comman-data-table .rdt_TableHead {
  color: var(--base-font-color);
  font-family: var(--title-family);
  font-weight: var(--fw-semibold);
  font-size: 14px;
}
.comman-data-table .rdt_TableRow {
  background: transparent;
  font-size: 14px;
  color: var(--base-font-color);
  border-bottom: 0 !important;
}
.comman-data-table.comman-data-table-bg .rdt_TableRow {
  background-color: var(--color-white-03);
  margin: 5px;
  border-radius: 3px;
}
.comman-data-table.comman-data-table-bg .rdt_TableRow:hover {
  background-color: var(--color-white-05);
}
.comman-data-table.comman-data-table-bg .jfrgbA {
  padding: 8px 15px;
}
.comman-data-table .sc-hLBbgP {
  padding: 15px 20px;
}
.comman-data-table .rdt_Pagination {
  background: var(--color-white-03);
  color: white;
  border: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 3;
  min-height: 42px;
}
.comman-data-table .rdt_TableCell{
  /* flex-grow: initial; */
}
.comman-data-table .sc-ezOQGI {
  filter: invert(1);
}
.comman-data-table .fwKvpK {
  background: var(--color-white-03);
  color: var(--base-font-color);
}
.comman-data-table .sc-hLBbgP {
  max-width: inherit;
}
.comman-data-table .jhEETM {
  min-width: auto;
  max-width: inherit;
}
.comman-data-table .sc-cxabCf option,
.comman-data-table .sc-cyRfQX option ,
.comman-data-table .sc-ilEZps  option{
  background: var(--base-body-color);
  color: var(--base-font-color);
  padding: 10px;
}
.comman-data-table .sc-cxabCf,
.comman-data-table .sc-cyRfQX ,
.comman-data-table .sc-ilEZps
 {
  background-color: var(--color-white-05);
  border-radius: 3px;
  margin-left: 15px;
}
.comman-data-table span.sc-cxabCf,
.comman-data-table span.sc-cyRfQX,
.comman-data-table span.sc-ilEZps
 {
  opacity:0;
}
/* Comman Data table stagging style over */
.comman-data-table {
}
.comman-data-table thead tr th.for-text-center {
  text-align: center;
}
.comman-data-table thead tr th.width-50px {
  width: 50px;
}
.comman-data-table thead {
  background-color: var(--base-header-color);
  position: sticky;
  top: 0;
  background-color: rgb(34 52 67);
  padding: 15px 20px;
  z-index: 4;
}
.comman-data-table .tablewithborder{
  border: 1px solid var(--black-op10);
}
.comman-data-table table.tablewithborder.dataTable thead th{
  border-bottom: 1px solid var(--black-op10);
  border-top: 1px solid var(--black-op10);
}
.comman-data-table .tablewithborder tbody tr td {
  border-right: 1px solid var(--black-op10);
  border-bottom: 1px solid var(--black-op10);
}
.comman-data-table .tablewithborder tbody tr td:last-child{
  border-right: 0;
}
.comman-data-table .tablewithborder tbody tr:last-child td{
  border-bottom: 0;
}
.sticky-top34px{
  top: 40px!important;
}
.bottom50px{
  bottom: 50px;
}
.bottom67px{
  bottom: 67px;
}
.comman-data-table thead.bg-white-03{
  background-color: var(--color-white-03);
}
.comman-data-table thead tr th {
  color: var();
}
.comman-data-table table.dataTable tbody tr {
  background-color: transparent;
}
.checklist-creation-abs .comman-table.dataTable tbody tr:nth-last-child(2) td{
  border-bottom: 1px solid transparent;
}
.comman-data-table table.dataTable.display tbody tr.odd {
  background-color: transparent;
}
.comman-data-table table.dataTable.no-footer {
  border-bottom: 0px;
}
.comman-data-table table thead th {
  color: var(--base-font-color);
  font-family: var(--title-family);
  font-weight: var(--fw-bold);
}
.comman-data-table table.dataTable thead th,
.comman-data-table table.dataTable thead td {
  /*border-bottom: 1px solid var(--color-white-10);*/
  border: 1px solid transparent;
}
.comman-data-table table.dataTable thead th.border-bottom {
  border-bottom: 1px solid var(--color-white-10);
  /*border: 1px solid transparent;*/
}
.comman-data-table table.dataTable thead th.sticky-head{
  background-color: red;
  top:0;
  left:0;
  position: static;
}
.comman-data-table table.dataTable tbody td.sticky-col{
  background-color: blue;
  left:0;
  position: static;
}
.comman-data-table table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1,
.comman-data-table table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: transparent;
}
.comman-data-table table.dataTable.hover tbody tr:hover,
.comman-data-table table.dataTable.display tbody tr:hover {
  background-color: var(--color-white-03);
  cursor: pointer;
  transition: var(--transiton-3s);
}
.comman-data-table table.small-padding tbody td {
  padding: 5px 20px;
}
.comman-data-table table.dataTable tbody td {
  border-top: 0px;
}
.comman-data-table table.dataTable.display tbody td {
  border-top: 0px;
}
.comman-data-table table tbody tr td.max-width-500 {
  max-width: 500px;
}
.comman-data-table table tbody tr td.max-width-100 {
  max-width: 100px;
}
.comman-data-table table tbody tr td.max-width-200 {
  max-width: 200px;
}
.comman-data-table table tbody tr td.max-width-300 {
  max-width: 300px;
}
.comman-data-table table tbody tr td.max-width-400 {
  max-width: 400px;
}
.comman-data-table table tbody tr td .max-width-500 {
  max-width: 500px;
}
.comman-data-table table tbody tr td .max-width-100 {
  max-width: 100px;
}
.comman-data-table table tbody tr td .max-width-200 {
  max-width: 200px;
}
.comman-data-table table tbody tr td .max-width-300 {
  max-width: 300px;
}
.comman-data-table table tbody tr td .max-width-400 {
  max-width: 400px;
}
.comman-data-table table.dataTable thead th {
  padding: 12px 15px;
}
.user-select-none{
  user-select: none;
}
/* Mension box */
.mension-box {
  /* width: calc(100% - 59px); */
  width: calc(100% - 35px);
  background-color: transparent;
  /* height: 40px; */
  max-height: 35vh;
  border: 0;
  padding-left: 5px;
}
.dropdown-menu .mension-box{
  max-height: 10vh;
}
.mension-box.with-calc-95px{
  width: calc(100% - 95px);
}
.mension-box .mension-box__highlighter {
  border: 1px solid transparent;
}
.mension-box .mension-box__input {
  border: 0;
  padding: 0px;
  /* padding-left: 10px;
  padding-right: 10px; */
  overflow: auto !important;
  color: var(--base-font-color);
}
.mension-box.withpl7px .mension-box__input{
  left: 7px !important;
}
.mension-box.withpm4px .mension-box__input{
  left: -4px !important;
}
.mension-box textarea {
}
.mension-box .mension-box__suggestions {
  /*padding: 5px 10px;*/
  font-size: 12px;
  font-family: var(--base-family);
  top: auto !important;
  bottom: 40px !important;
  width: 100% !important;
  margin-top: 0 !important;
  background-color: var(--dropdown-bg-color)!important;
  border: 1px solid var(--dropdown-dorder-color);
  color: var(--dropdown-text-color) !important;
  border-radius: 5px;
  box-shadow: var(--dropdown-box-shadow-color);
}
.mension-box.custom-width .mension-box__suggestions{
  width: auto !important;
  min-width:30% !important ;
  left: 0;
  right: auto !important;
  font-size: 11px;
}
.mension-box .mension-box__suggestions .mension-box__suggestions__list {
  max-height: 30vh;
  overflow: hidden auto;
}
.mension-box .mension-box__suggestions .mension-box__suggestions__list li {
  padding: 5px 0px;
  border-bottom: 1px solid var(--color-border);
}
.mension-box .mension-box__suggestions .mension-box__suggestions__list li:hover{
  background-color: var(--color-white-05);
}
.mension-box
  .mension-box__suggestions
  .mension-box__suggestions__list
  li:last-child {
  border-bottom: 0;
}
/* popover */
.popover {
  /* background-color: var(--calendar-modal-color);
  border: 1px solid var(--popover-border); */
  background-color: var(--dropdown-bg-color);
  border: 1px solid var(--dropdown-dorder-color);
  color: var(--base-font-color) !important;
  border-radius: 5px;
  box-shadow: var(--dropdown-box-shadow-color);
}
.popover.w-400 {
  width: 400px;
  max-width: initial;
}
.popover.w-300 {
  width: initial;
  max-width: 300px;
}
.popover.w-500 {
  width: 500px;
  max-width: initial;
}
.popover.chart-overflow {
  overflow-x: scroll; 
  overflow-y: hidden;
  width: 500px;
  max-width: initial;
}
.chart-overflow g text{
  transform: translate(0)!important;
}
.popover-body {
  color: var(--popover-text-color);
}
.popover-body.w-200 {
  width:200px ;
}
.popover-body.w-250 {
  width:250px ;
}
.popover-body.w-280 {
  width:280px ;
}
.popover-body.timeslot-popover-body{
  padding: 10px;
}
.popover-body.timeslot-popover-body .timeslot-popover-main{
  padding-right: 10px;
}
.popover-body .popover-detail-wrapper {
  padding: 15px 0px;
  border-bottom: 1px solid var(--color-border);
}
.popover-body .popover-detail-wrapper.padding_10{
  padding: 10px;
}
.popover-body .popover-detail-wrapper:last-child {
  border-bottom: 0;
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-bottom-color: var(--color-white-1);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  border-bottom-color: var(--color-white-1);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after{
  border-left-color: var(--base-body-color);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before{
  border-left-color: var(--color-white-10-solid);
}
.dropdown-checklist-wrapper .info-icon[aria-describedby] svg.comman-icons{
  stroke: var(--color-green);
}
.timeslot-popover-main .week-schedule-box .week-box .available-status{
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.timeslot-popover-main .list-group-item .margin-top-minu{
  margin-top: 2px;
}
.timeslot-popover-main li.list-group-item.hr_sepretr:last-child:before {
  display: none;
}
.timeslot-popover-main li.list-group-item.hr_sepretr.py-3:last-child {
  padding-bottom: 0px !important;
}
/*React Fancy Box   */
.with-react-fancybox {}
.with-react-fancybox .react-fancybox {
  height: 100%;
  width: 100%;
}
.with-react-fancybox .thumbnail {
  height: 100%;
  width: 100%;
}
.with-react-fancybox .react-fancybox .thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
}
.with-react-fancybox .react-fancybox .box {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}
/* map suggestion */
.custom-map-suggestion {
  background-color: var(--left-panel-bg);
  border: 1px solid var(--color-white-10);
  border-top: 0;
  color: var(--white-color);
  max-height: 20vh;
  overflow: hidden auto;
}
.custom-map-suggestion div {
  padding: 10px;
  border-bottom: 1px solid var(--color-white-10);
}
/* editor */
.editor-box-main {
  height: 100%;
  width: 100%;
}
.editor-box-main .ql-toolbar.ql-snow {
  border: 0;
  font-family: var(--base-font);
  background-color: var(--color-white-03);
  border-bottom: 1px solid var(--color-white-07);
}
.editor-box-main .ql-container.ql-snow {
  border: 0;
  background-color: var(--color-white-03);
}
.editor-box-main .ql-formats {
  filter: invert(1);
  opacity: 60%;
}
.editor-box-main .ql-snow.ql-toolbar button:hover {
  background-color: var(--color-white-05);
}
.editor-box-main .ql-editor p {
  caret-color: var(--base-font-color);
}
/* react tooltip */
.__react_component_tooltip{
  padding: 4px 10px !important;
  /* z-index: 9999 !important; */
  z-index: 21 !important;
  background-color: var(--tooltip-bg) !important;
  color: var(--tooltip-color) !important;
  box-shadow: var(--tooltip-shadow) ;
  font-weight: var(--tooltip-fw);
}
.__react_component_tooltip.show{
  opacity: 1!important;
}
.tooltip-tetx-break{
  white-space: break-spaces;
  max-width: 50vh;
}
.__react_component_tooltip.place-top::before{
  width:13px  !important;
  height: 6px  !important;
}
.css-1pndypt-Input,
.color-white-imp{
  color: var(--base-font-color)!important;
}
.employee-list-main{
  height: calc(100% - 100px );
    /* overflow: auto; */
}
.time-sheet-box{
  min-width:190px ;
  padding: 10px;
}
.left-sticky-bar {
  position: sticky;
    left: 0;
    /* background-color: red; */
    width: 300px;
}
.left-sticky {
  position: sticky;
  left: 0;
  z-index: 8;
}
.time-sheet-box.name-box{
  min-width: 200px;
  position: sticky;
  left: 0;
  background-color: var(--base-body-color);
  z-index: 5;
}
.time-sheet-box.name-box.bg-white-03-solid {
  background-color: var(--color-white-03-solid);
} 
.time-sheet-box.hour-box{
  position: sticky;
  left: 200px;
  background-color: var(--base-body-color);
  z-index: 5;
  min-width:100px ;
}
.timesheet-head{
  position: sticky;
  top: 0;
  width: 100%;
  /* background-color: green; */
  z-index: 10;
} 

.timesheet-list{
  height: calc(100% - 45px);
      overflow: auto;
}
.timesheet-foot{
  position: sticky;
  bottom: -1px;
  width: 100%;
  z-index: 8;
}
.dropdown-foot{
  position: sticky;
  bottom: -1px;
  width: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-white-03-solid)!important;
  padding: 8px;
  border-top: 1px solid var(--dropdown-dorder-color);
}
.timesheet-head .time-sheet-box , .timesheet-foot .time-sheet-box  {
  background-color: #1f3748;
}
/* tr.timesheet-foot td{
  height: 30px;
}
tr.timesheet-head td {
  height: 30px;
} */
.custom-datepicker .react-datepicker__navigation-icon::before{
  height: 6px;
  width: 6px;
}
.custom-datepicker .react-datepicker__year-read-view--down-arrow{
  right: -16px;
  top: 6px;
}
.custom-datepicker .react-datepicker__year-read-view--down-arrow::before{
  width: 8px;
  height: 7px;
  border-width: 1px 1px 0 0;
}