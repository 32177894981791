.btn-close:focus{
  box-shadow: none;
}
.custom-modal-style.modal {
  background-color: var(--base-modal-backdrop-color);
}
.custom-modal-style.modal.modal-style-modified {
  top:135px;
  right:0;
  left: auto;
  width: 25vw;
  max-width: 600px;
  height: auto;
  background-color: transparent;
}
.custom-modal-style.modal.modal-style-modified.w-40vw {
  width: 40vw;
}
.custom-modal-style.modal.modal-style-modified .modal-dialog{
  margin: 0px;
}
.tutorial-3d-map {
  background-color: var(--base-modal-backdrop-color);
  z-index: 20;
}
.custom-modal-style .offcanvas {
  background-color: var(--left-panel-bg);
}
.modal-backdrop{
  height: 100%;
  width: 100%;
  background: rgba(59, 60, 61, 0.60);
}
.modal-fullscreen{
  width: 100%;
}
.custom-modal-style .offcanvas-header {
  background-color: var(--color-white-05);
}
.custom-modal-style .modal-header {
  background-color: var(--custom-modal-header-color);
  padding: 15px;
  border: 0px;
}
.custom-modal-style .modal-footer {
background-color: var(--custom-modal-footer-color);
}
.custom-modal-style .modal-header .modal-title {
  font-size: var(--base-font);
  font-family: var(--title-family);
  font-weight: 600 !important;
}
.custom-modal-style .modal-header .modal-titleh4{
  font-size: var(--base-font);
}
.custom-modal-style .offcanvas-title {
  font-size: var(--base-font);
  font-family: var(--title-family);
  font-weight: 600 !important;
}
.task-detail-modal .modal-header .modal-title .back-arrow-icon {
  display: none;
}
.custom-modal-style .modal-header .btn-close {
  filter: invert(1);
}
.custom-modal-style .modal-header .btn-close:focus {
  outline: 0;
  box-shadow: none;
}
.custom-modal-style .modal-body {
  padding: 15px;
  max-height: calc(100vh - 100px);
  /* overflow: hidden auto; */
}
.custom-modal-style .modal-body.with-custom-height {
  max-height: 70vh;
}
.custom-modal-style .modal-body.with-custom-height80vh {
  max-height: 80vh;
}
.maxh55vh{
  max-height: 55vh;
}
.custom-modal-style .modal-body.with-left-shadow{
  position: relative;
}
.custom-modal-style .modal-body.with-left-shadow:after{
  content: '';
  position: absolute;
  top:0;
  left:0;
  /* width: 50%; */
  width:calc(50% - 300px - 10% - -25px );
  height: 100%;
  background-color:var(--base-body-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 0;
}
.wrapper-main .TopicModalLeftPart{
  background-color:var(--base-body-color);
  width: 410px;
  min-width: 410px;
  padding-left: 110px;
}
.wrapper-main .ModalLeftPart-BgWithShadow.TopicModalLeftPart{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.custom-modal-style .modal-body.with-overflow {
  /* max-height: calc(100vh - 100px); */
  max-height: calc(100vh - 20vh);
  overflow: hidden auto;
}
.custom-modal-style.checklist-modal-with-maxheight .modal-content{
  max-height: 70vh;
}
.custom-modal-style.maxheight85vh .modal-content{
  max-height: 85vh;
}
.custom-modal-style.checklist-modal-with-maxheight .modal-content .modal-body{
  /* overflow: auto; */
}
/* .custom-modal-style .modal-body.with-scroll{
  overflow: hidden auto;
    max-height: calc(100% - 55px);
} */
.custom-modal-style .modal-dialog{
  max-height: calc(100% - var(--bs-modal-margin) * 2);
}
.custom-modal-style .modal-fullscreen.modal-dialog,
.custom-modal-style .modal-fullscreen.modal-dialog .modal-body{
  max-height: 100%;
}
.custom-modal-style .modal-tabs-fixed{
  position: fixed;
  width: 170px;
  height: calc(100% - 226px);
  overflow: hidden auto;
}
.task-detail-modal .modal-body {
  height: calc(100% - 55px);
}
.task-detail-modal .offcanvas {
  background-color: var(--left-panel-bg);
  max-width: calc(100% - 450px - 70px);
  width: 100%;
  box-shadow: -4px 4px 20px 0px rgb(0, 0, 0, 0.15);
  border: 0;
  transition: var(--transiton-3s);
}
.offcanvas.offcanvas-end .task-detail-left-main .task-detail-header .task-header-right {
  width: auto;
  border-radius: 3px;
}
.task-header-right input.badge-white {
  width: 80px;
}
.task-detail-modal .task-detail-left-main .task-detail-modal .task-detail-left-main {
  padding: 15px;
  width: calc(100% - 270px);
}
.task-detail-modal .task-detail-left-main {
  padding: 15px;
  width: calc(100% - 270px);
}
.task-detail-modal .task-detail-left-main .task-detail-header {
  padding-bottom: 10px;
}
.task-detail-modal .task-setting-section {
  padding: 10px 0px;
}
.task-detail-modal .task-setting-section .white-border-box {
  margin-right: 10px;
}
.task-setting-section.withbox-shadow:before{
  content: "";
  position: absolute;
  top: 0;
  /* left: -70px; */
  left: -30px;
  height: 100%;
  width: 100%;
  background: linear-gradient(270deg,#1d3545,#1d3545 93.06%,rgb(29 53 69 / 70%));
  z-index: 0;
}
.task-detail-modal .task-detail-right-main {
  background-color: var(--color-white-03);
  width: 270px;
  position: relative;
}
.custom-datepicker.w-90.datepicker-input .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 82px;
  max-width: 134px;
  font-size: 13px;
}
.custom-datepicker.w-90.datepicker-input .react-datepicker-wrapper .react-datepicker__input-container input{
  padding-left: 8px !important;
}
.custom-datepicker.abs-input .react-datepicker-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
}
.task-setting-section .react-datepicker__input-container input {
  width: 70px;
}
/* seprate style */
.custom-modal-style .task-detail-left-main {
  padding: 15px;
  /* padding: 10px; */
  width: 100%;
  padding-top:15px;
}
.custom-modal-style .task-detail-left-main .tab-wrapper_main{
  z-index: 4;
  position: relative;
}
.list-view-toggle .list-view .main-calc-width .task-list-name {
  width:70px;
  flex-grow: 1;
}
.custom-modal-style .task-detail-right-main {
  background-color: var(--color-white-03);
  /* width: 300px; */
  /* border-left: 1px solid transparent; */
}
.offcanvas.taskDetailModal {
  /* top: 140px; */
  top: 0;
  /* right: 10px; */
  right: 0;
  width: calc(50% - 30px);
  /* height: calc(100vh - 137px - 98px); */
  /* height: calc(100% - 137px - 88px); */
  /* height: calc(100% - 137px - 88px); */
  height: 100%;
  /* z-index: 8; */
  z-index: 16;
  background-color: var(--base-modal-bg);
  border: 0;
  transition: var(--transiton-0s);
} 
.offcanvas.taskDetailModal .task-setting-section.with-abs-position{
  position: absolute;
  top: 10px;
  right: 15px;
 background-color: var(--badge-inner-bg);
}
.offcanvas.taskDetailModal .task-setting-section .task-setting-right {
  position: relative;
}
/* .offcanvas.taskDetailModal .task-setting-section.with-abs-position.d-non{
  right: 142px;
} */
.offcanvas {
  color: var(--base-color);
} 
.offcanvas.taskDetailModal.toggle-zindex{
  z-index: 21;
}
.offcanvas.taskDetailModal.calendar{
  top: calc(145px - 75px);
  height: calc(100% - 137px - 98px + 75px);
}
.offcanvas.taskDetailModal.canvas-height-modified {
  height: calc(100% - 137px - 12px);
} 
.offcanvas.taskDetailModal.canvas-height-modified.with-header {
  /* height: calc(100% - 147px - 7px); */
  /* top: 70px; */
  height: 100%;
  top: 0;
} 
.offcanvas.taskDetailModal.canvas-height-modified.with-header.modal-full {
  height: auto;
  top: 0px;
} 
.offcanvas.taskDetailModal.canvas-height-modified.no-footer {
  height: calc(100% - 87px - 0px);
  top: 70px;
} 
.with-left-penel .offcanvas.taskDetailModal {
  /* width: calc(50% - 165px); */
  width: calc(50% - 155px);
}
.with-left-penel.with-right-penel .offcanvas.taskDetailModal {
  /* width: calc(50% - 310px); */
  /* right: 307px; */
  z-index: 11;
}
.with-left-penel.with-right-penel .offcanvas.taskDetailModal.stand-alone-comments{
  right: 0px;
}
.with-left-penel.right-penel-no-padding .offcanvas.taskDetailModal.stand-alone-comments{
  padding-right: 10px !important;
  right: 0px;
}
.right-penel-no-padding .offcanvas.taskDetailModal.modal-full{
  width: calc(100% - 230px - 77px);;
  right: 300px;
  border-right: rgb(21 41 58) 3px solid;
}
.right-penel-no-padding .offcanvas.taskDetailModal.modal-full-left-panel {
  width: calc(100% - 230px - 77px);
  right: 300px;
  border-right: rgb(21 41 58) 3px solid;
}
.offcanvas.taskDetailModal.without-top-calendar{
  top: 90px;
  height: calc(100vh - 90px - 98px);
}
.offcanvas.EquipmentlModal {
  top: 0;
  right: 0;
  background-color: var(--base-modal-bg);
  border: 0;
  transition: var(--transiton-3s);
}
.offcanvas.offcanvas-end.transform-right {
  right: -270px;
}
.offcanvas.offcanvas-end .offcanvas-body {
  overflow: visible;
  height: 100%;
}
.new_schedule.offcanvas.offcanvas-end .offcanvas-body {
  overflow: auto;
  height: 100%;
}
.schedule-checbox-disbled{
  opacity: 0.5;
}
.schedule-checbox-disbled span , .schedule-checbox-disbled input{
  color: var(--color-white-80);
}
.offcanvas-body.with-scroll{
  max-height: calc(100vh - 180px);
}
.offcanvas.offcanvas-end .modal-body {
  /* overflow: hidden; */
  height: 100%;
  max-height: 100%;
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper {
  height: calc(100% - 175px);
  overflow: hidden;
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper.h-125 {
  /* height: calc(100% - 125px); */
  height: calc(100% - 54px);
  overflow: hidden;
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper.h-160 {
  height: calc(100% - 160px);
  overflow: hidden;
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper.h-135 {
  /* height: calc(100% - 135px); */
  height: calc(100% - 55px);
  /* height: calc(100% - 95px); */
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper.h-115 {
  height: calc(100% - 115px);
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper.h-190 {
  height: calc(100% - 190px);
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper .task-checklist-wrapper {
  /* height: calc(100% - 55px); */
  height: calc(100% - 65px);
  overflow: hidden auto;
}
.offcanvas.offcanvas-end .task-detail-right-main .right-chat-wrapper {
  height: calc(100% - 60px);
  flex-grow: 1;
}
.offcanvas.offcanvas-end .offcanvas-CommonHeader-main {
  padding: 10px 15px;
}
.task-detail-right-main .right-chat-wrapper .search-with-close {
  left: 50%;
  top: 0;
  width: 97%;
  z-index: 5;
  margin: auto;
  padding-top: 4px;
}
.task-detail-right-main.task-comment-modal{
  position: fixed;
  top:0;
  right:calc(var(--space-300) - (var(--space-300)*2));
  background-color: var(--base-modal-bg);
  width:var(--space-300);
  z-index:10;
  transition: var(--transiton-3s);
}
.task-detail-right-main.task-comment-modal .right-modal-task-header{
  height:60px;
}
.task-detail-right-main.task-comment-modal.show-comments{
  right:0px;
}
.task-detail-right-main .right-chat-wrapper .search-with-close .form-control:focus {
  background-color: var(--bg-base-header-color);
}
.offcanvas.offcanvas-end .task-setting-section .white-border-box {
  /* margin-right: 10px; */
}
.offcanvas.offcanvas-end .task-detail-left-main .task-detail-header {
  /* padding-bottom: 15px; */
  /* padding-bottom: 10px; */
}
.offcanvas.offcanvas-end .task-setting-section {
  padding: 10px 0px;
}
/* seprate style */
.task-detail-modal .tast-detail-tab-wrapper {
  height: calc(100% - 180px);
  overflow: hidden;
}
.task-detail-modal .tast-detail-tab-wrapper .task-checklist-wrapper {
  height: calc(100% - 40px);
  overflow: hidden auto;
}
.task-checklist {
  padding: 10px 0px;
  padding-right: 0px;
  border-bottom: 1px solid var(--color-white-10);
}
.task-checklist .left-checklist {
  width: calc(100% - 40px);
}
.task-checklist .right-checklist-action .number-counter-toggle{
  display:none;
}
.task-checklist .right-checklist-action .dropend.w_172{
  min-width:auto !important;
}
.task-checklist .right-checklist-action .with-abs-position{
  position: absolute;
  top: 0;
  right: 32px;
  /* background: var(--base-modal-bg); */
  background: linear-gradient(270deg,#1d3545,#1d3545 93.06%,rgb(29 53 69 / 70%));
  padding-left:30px;
}
.task-checklist .right-checklist-action .with-abs-position.bg-white03{
  background: var(--color-white-03-solid);
}
.with-abs-position.with-abs-position-div-res{
  display: none!important;
}
.with-abs-position-div-none-res{
  display: block!important;
  padding-left:30px
}
/* .task-checklist .right-checklist-action .checklist-icon-box{
  display:none;
}
.task-checklist .right-checklist-action:hover .checklist-icon-box,
#commentDropDownOpen:hover{
  display: block;
} */
.task-checklist .checklist-list {
  padding-left: 10px;
  width: calc(100% - 60px);
}
.task-checklist .bottom-checklist-options {
  padding-top: 5px;
  padding-left: 85px;
}
.task-checklist .bottom-checklist-options .assignee-img-with-remove {
  position: relative;
  padding-left: 10px;
}
.task-detail-modal .task-detail-right-main .right-task-header {
  position: relative;
  padding: 10px;
}
.task-detail-modal .task-detail-right-main .right-chat-wrapper {
  height: calc(100% - 125px);
  flex-grow: 1;
}
.chat-main-box.with-bg{
  margin: 0px -15px;
}
.chat-main-box.with-bg .mension-box{
  width: calc(100% - 355px);
}
.chat-main-box.with-bg .comment-bottom-option{
  margin-top: 3px;
}
.chat-main-box .chat-wrapper {
  flex-grow: 1;
  overflow: hidden auto;
}
.chat-wrapper .chat-box {
  padding: 10px;
  max-width: 90%;
}
.chat-wrapper .chat-box.with-padding {
  padding: 20px 20px 0px 20px;
}
.chat-wrapper .chat-box.max-w-100 {
  max-width: 1000%;
}
.chat-wrapper .chat-box .chat-msg {
  width: calc(100% - 30px);
  padding: 0px 10px;
}
.chat-wrapper .chat-box .chat-msg .chat-text-box {
  background-color: var(--color-white-05);
  padding: 10px 10px;
  border-radius: 5px 5px 5px 1px;
  font-size: 14px;
  color: var(--base-color);
  display: inline-block;
  word-break: break-word;
  position: relative;
}
.chat-wrapper .chat-box.right-side-msg .chat-msg .chat-text-box {
  border-radius: 3px 3px 0px 3px;
  /* margin-left: auto; */
}
.chat-wrapper .chat-box .chat-msg .chat-text-box.with-media .chat-img {
  width: 250px;
  height: 250px;
}
.chat-wrapper .chat-box .chat-action-option {
  position: absolute;
  right: -20px;
  top: 0;
}
.chat-wrapper .chat-box .chat-action-option.rightminus10 {
  right: -10px;
  top: 8px;
}
.chat-wrapper .chat-box .chat-msg .chat-send-name {
  padding-top: 5px;
}
.chat-wrapper .chat-box .delivered-status-icon{
  display: block;
}
.chat-wrapper .chat-box.right-side-msg .delivered-status-icon{
  display: block;
}
.delivered-status-icon .msg-status{
  display: none;
}
.delivered-status-icon.delivered .msg-delivered-double{
  display: block;
}
.delivered-status-icon.delivered-single .msg-delivered-single{
  display: block;
}
.delivered-status-icon.message-read .read-msg{
  display: block;
}
.chat-wrapper .chat-box.right-side-msg {
  margin-left: auto;
  justify-content: flex-end;
  display: flex;
}
.chat-wrapper .chat-box.right-side-msg .chat-send-name > div{
  justify-content: end;
}
.chat-wrapper .chat-box.right-side-msg .comman-image-box {
  order: 2;
}
.chat-wrapper .chat-box.right-side-msg .chat-action-option {
  left: -20px;
  right: auto;
}
.chat-wrapper .chat-box.right-side-msg .chat-action-option.l12px {
  left: -12px;
 
}
.chat-wrapper .chat-box.right-side-msg .chat-action-option.LR40px{
  left: -40px;
}
.chat-wrapper .chat-box .chat-action-option.LR40px{
  right: -40px;
}
.chat-wrapper .chat-box.right-side-msg .chat-msg {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.chat-wrapper .chat-box.right-side-msg .chat-msg .chat-text{
  text-align: left;
}
.chat-wrapper .chat-box.right-side-msg .chat-msg .chat-send-name {
  /* text-align: left; */
  text-align: right;
}
.chat-main-box .chat-comment-box {
  margin-top: auto;
  background-color: var(--color-white-03);
  padding: 10px 10px;
  border-top: 1px solid transparent;
  border-radius: 3px;
}̥
.chat-main-box .comment-input-box {
  padding-bottom: 10px;
}
.chat-main-box .comment-bottom-option {
  padding-top: 10px;
}
.chat-comment-box .comment-input-box.with-mention-top .mension-box .mension-box__suggestions {
  max-height: 20vh;
}
.chat-comment-box.with-bg{
}
.chat-comment-box .comment-input-box.with-mention-top .mension-box .mension-box__suggestions .mension-box__suggestions__list {
  max-height: 15vh;
}
/*notes list*/
.task-checklist-wrapper .note-listing-main {
  min-height: 150px;
  margin-top: 15px;
  background: var(--color-white-03);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid transparent;
}
.task-checklist-wrapper .note-listing-main .note-discription {
  padding-top: 10px;
}
.task-checklist-wrapper .note-list {
  padding: 15px;
  padding-left: 0px;
}
.task-checklist-wrapper .note-list .link-text {
  width: calc(100% - 40px);
  padding-left: 15px;
  cursor: pointer;
}
.task-checklist-wrapper .note-list .left-link {
  width: calc(100% - 40px);
}
.task-checklist-wrapper .form-check .form-check-label{
  min-height: 30px;
}
.file-image-list {
  padding: 20px 20px 0px 0px;
}
.c-list-icon.with-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  z-index: 1;
  border-radius: 3px;
}
.c-list-icon.with-overlay.h_16vh:before {
  /* height: 16vh; */
  height: 150px;
}
.file-image-list .file-action-option {
  display: none;
  position: absolute;
  right: 3px;
  top: 5px;
  z-index: 2;
}
.file-image-list .c-list-icon:hover .file-action-option {
  display: block;
  transition: var(--transiton-3s);
}
.custom-modal-style .modal-body .equipment-listing {
  padding: 15px;
}
.custom-modal-style .modal-body .equipment-top-detail .equipment-name-wrapper {
  padding: 15px 0px;
}
.custom-modal-style .modal-body .equipment-description {
  padding: 15px 0px;
}
.custom-modal-style .set-topic-list.with-max-height{
  max-height: 45vh;
}
.custom-modal-style.with-min-height .modal-content{
  min-height: 74vh;
}
.file-uploader-name{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  color: var(--base-font-color);
  font-size: 10px;
}
#commanCanvasImageBody .file-uploader-name{
  height: 150px;
  border-radius: 3px;
}
/* bottom offcanvas */
.offcanvas-backdrop{
  background-color:var(--base-body-color);
  height: 100%;
  width: 100%;
}
.offcanvas-backdrop.show{
  opacity: 0.9;
}
.offcanvas.offcanvas-bottom{
  background-color: var(--base-header-color);
  bottom: 85px;
  width: 500px;
  right: 20px ;
  left: auto ;
  height: auto ;
}
.offcanvas.offcanvas-center{
  background-color: var(--base-header-color);
  transform: translate(-50%, -50%)!important;
  left: 50%;
  top: 50%;
  bottom: auto;
}
.offcanvas.offcanvas-bottom.w-950{
  width: 950px;
}
.offcanvas.offcanvas-bottom.with-max-h-70vh{
  max-height: 70vh;
}
.offcanvas.offcanvas-bottom.with-max-h-85vh{
  max-height: 85vh;
}
.bottom-offcanvas .offcanvas-header{
  background-color: var(--color-white-05);
  padding: 15px;
}
.bottom-offcanvas .offcanvas-header .offcanvas-title {
  font-size: var(--base-font);
    font-family: var(--title-family);
    font-weight: 600 !important;
}
.bottom-offcanvas .offcanvas-body{}
.bottom-offcanvas .offcanvas-body .task-detail-header.with-pb{
  padding-bottom: 15px;
}
.bottom-offcanvas .offcanvas-body .checklist-inputbox{
  padding: 15px 0px;
}
.bottom-offcanvas .offcanvas-body .form-check.with-span{
  padding: 10px 0px;
}
.custom-modal-style .modal-body .form-check.with-span{
  padding: 10px 0px;
}
.dropdown-menu.with-transform-option{
  transform: translate(-206px, 30px) !important;
}
.dropdown-menu.with-transform-outside{
  transform: translate(-8px, 30px) !important;;
}
.dropdown-menu.with-transform-revert{
  transform: translate(0px, 45px) !important;;
}
.comman-offcanvas .comman-offcanvas-modal{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.comman-offcanvas{
  background-color: var(--base-header-color);
  width: 26vw;
  position: absolute;
}
.offcanvas.offcanvas-end .tast-detail-tab-wrapper.comman-offcanvas-content-part{
  flex-grow: 1;
  overflow: hidden auto;
}
.offcanvas.w-600{
  width: 600px;
}
.comman-table.dataTable.smaller-table thead th, .comman-table.dataTable.smaller-table thead td , .comman-table.dataTable.smaller-table tbody th,.dataTable.smaller-table tbody td{
  padding: 10px 5px;
}
.comman-data-table .dataTable.smaller-table thead{
  z-index: 2;
}
.offcanvas.offcanvas-bottom.w-820{
  width: 820px;
}
.offcanvas.offcanvas-bottom.w-1200{
  width: 1200px;
}
.offcanvas.offcanvas-bottom.w-950{
  width: 950px;
}
.chat-main-box .chat-wrapper .chat-box{
  max-width: 95%;
}
.chat-main-box .chat-wrapper .chat-box .chat-msg .chat-text-box{
  /* padding: 3px 6px; */
  padding:6px;
}
/* create-task-absolute-mobile-liteversion */
.custom-modal-style.create-task-absolute-mobile-liteversion .modal-content{
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-45%);
  width: 800px;
  max-height: 100vh;

}
.custom-modal-style.create-task-absolute-mobile-liteversion .min-h25vh {
  min-height: 10vh;
}
.create-task-absolute-mobile-liteversion.custom-modal-style .modal-body {
  padding: 10px;
}
.create-task-absolute-mobile-liteversion .modal-content{
  border-radius: 3px!important;
}
.create-task-absolute-mobile-liteversion.custom-modal-style .modal-header{
  display: none;
}
/* .create-task-absolute-mobile-liteversion.custom-modal-style.modal{
  background-color:rgb(22 41 58 / 66%)!important;
} */
.custom-modal-style.modal.solarcommanmodal {
  background-color: initial
}
.custom-modal-style.modal.solarcommanmodal .modal-header{
  background-color: transparent;
  padding: 0;
}
.custom-modal-style.modal.solarcommanmodal .modal-header .btn-close{
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 3;
}
.custom-modal-style.modal.solarcommanmodal .modal-body{
  background-color: #fff;
}
.custom-modal-style.modal.solarcommanmodal .modal-footer{
  background-color: #fff;
}
.stripeparent label{
  width: 100%;
}
.StripeElement{
  height: 53px !important;
  background-color: #e4ecf7 !important;
  border-radius: 3px !important;
  border: 1px solid #E4ECF7 !important;
  font-size: var(--base-font) !important;
  font-family: var(--base-family) !important;
  color: var(--base-font-color) !important;
  padding: 15px !important;
  margin-top: 8px;
}
 .__PrivateStripeElement,  .__PrivateStripeElement iframe{
  top: 0px;
    left: 0px;
    height: 53px !important;
    width: 100%;
}
.custom-canvas-style {
  background-color: #e4ecf7 !important;
}
/* .StripeElement.StripeElement--empty .__PrivateStripeElement iframe {
  background-color: var(--color-white-03) !important;
  border-radius: 3px !important;
  border: 1px solid #E4ECF7 !important;
  font-size: var(--base-font) !important;
  font-family: var(--base-family) !important;
  color: var(--base-font-color) !important;
  height: 53px !important;
} */

.bgblue05{
  background-color: rgba(0, 6, 69, 0.05);
}
.borderblue{
  border:1px solid #000645;
}
.radius10px{
  border-radius: 10px;
}
.fblueimp{
  color: #000645 !important;
}
.fblue60{
  color: rgba(0, 6, 69, 0.6);
}
.btn.px12pxpy8px{
  padding: 8px 12px;
}
.w145px{
  width: 145px !important;
}
.btn.bluebtn{
  padding: 10px 25px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #03229B;
  border:1px solid #03229B;
  font-size: 14px;
}
.light-theme .glow-shadow.solar-login .fixed-left-panel .custom-accordian-Header-sticky{
  background-color: #1b874f;
}
.messaging-dropdown-main{
  width: 350px;
}
.light-theme .glow-shadow .chat-wrapper .chat-box .comman-image-box.bg-white-05.rounded-circle , 
.light-theme .glow-shadow .ai-chat-header .chat-wrapper .chat-box.right-side-msg .chat-msg .chat-text-box{
  background-color: var(--alice-blue-opaf7);
}