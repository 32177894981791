.introjs-tooltip{
	background-color: var(--base-header-color) !important;
    border: 1px solid var(--color-border) !important;
    color: var(--white-color-60) !important;
    border-radius: 5px !important;
    font-size: 13px !important;
    width: 280px ;
    box-shadow: var(--color-box-shadow-dropdown) !important;
    padding: 0;
    z-index: 1046;
	font-family: var(--base-family) !important;
	text-align:center ;
}
.introjs-tooltip{
	min-width: 350px;
}
.introjs-skipbutton{
	color:var(--color-white);
}
.introjs-skipbutton:focus, .introjs-skipbutton:hover{
	color:var(--color-white) !important;
	background-color: transparent !important;
	/* background-color: var(--color-white-05) !important; */
}
.introjs-tooltip.intro-top-20{
	top:20px;
}
.introjs-tooltip-header{
	padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}
.introjs-fixedTooltip{
	box-shadow: var(--color-black) 0px 0px 1px 2px, rgb(0 0 0 / 65%) 0px 0px 0px 5000px !important;
}
.introjs-tooltip-title{
	color: var(--white-color-60) !important;
	font-family: var(--title-family) !important;
}
.introjs-tooltiptext{
	padding:15px;
	color: var(--white-color-60) !important;	
	font-family: var(--base-family) !important;
    letter-spacing: 0.2px;
}
.introjs-button{
	border-radius: 3px !important;
	font-size: var(--base-font) !important;
	padding: 0.375rem 0.75rem;
}
.introjs-button.introjs-fullbutton,
.introjs-button.introjs-nextbutton{
	/* background: var(--color-gradient); */
	/* color: var(--color-black); */
	/* border: 1px solid transparent !important; */
	text-shadow: none;
	color: var(--color-green) !important;
	background-color: transparent;
    border: 0px solid;
}
.introjs-button.introjs-prevbutton{
	/* background: transparent;
    color: var(--color-white);
    border: 1px solid var(--color-white-10); */
    text-shadow: none !important;
	background-color: transparent  !important;
    border: 0px !important;
    color: var(--color-white) !important;
}
.introjs-tooltipbuttons{
	border-top: 1px solid var(--color-border) !important;
	padding: 0;
}
.introjs-button:focus {
    box-shadow: none;
}
.introjs-bullets ul li a.active {
    background: var(--color-white-80);
}
.introjs-bullets ul li a{
	background: var(--color-white-30);
}
/* .intro-js .fixed-left-panel.provider-list{
	transform: translateX(0px);
	opacity:0;
} */
.intro-js-left-0{
	left: 0px !important;
}
.intro-js-left-60{
	left: 60px !important;
}
.intro-js-task-modal{
	left: 1130px !important;
    top: 145px !important;
	width: calc(50% - 30px) !important;
	height: calc(100vh - 137px - 98px) !important;
	right: 15px !important;
}
.introjs-arrow.top{
	border-bottom-color: var(--color-white-30) !important;
}
.introjs-arrow.top-middle{
	border-bottom-color: var(--color-white-30) !important;
}
.introjs-arrow.left{
	left: -11px;
    top: 10px;
    border-right-color: var(--color-white-30) !important;
}
.introjs-arrow.bottom{
	border-top-color: var(--color-white-10-solid) !important;
}
.introjs-arrow.top-right{
	border-bottom-color: var(--color-white-10-solid) !important;
}
.introjs-arrow.bottom-right{
	border-top-color: var(--color-white-10-solid) !important;
}
.introjs-arrow.bottom-middle{
	border-top-color: var(--color-white-10-solid) !important;
}
/* .introjs-tooltip.last .introjs-prevbutton, .introjs-tooltip.last .introjs-nextbutton{
	display: none;
} */
.introjs-button:active{
	background-color: transparent !important;
    color: var(--color-green) !important;
    text-shadow: none !important;
}
.introjs-button:focus{
	box-shadow: none !important;
	border: 0px !important;
	background-color: transparent !important;
}
.introjs-bullets ul li a.active{
	background: var(--color-gradient) !important;
}
.introjs-bullets {
padding-top: 0 !important;
}
.introjs-button.introjs-prevbutton:hover{
	border: 0px !important;
	background-color: transparent !important;
	color: var(--color-white) !important;
}
.introjs-button.introjs-nextbutton:hover{
	border: 0px !important;
	background-color: transparent !important;
	color: var(--color-green) !important;
}