/* Week view css */

.CustomFullCalendar{
    /* border:1px solid var(--color-border); */
    height: 100%;
    overflow: hidden auto;
}
.CustomFullCalendar.Weekview{
    /* width: calc(100% - 150px); */
    margin: 0 auto;
    border-top:0;
    border-left: 0;
    border-right: 0;
}
.CustomFullCalendar.Weekview .Weekbox{
    margin: 0px 5px;
    /* padding: 5px; */
    width: calc(14.28% - 6px);
    /* width: calc(14.28% - 0px); */
    /* background-color: var(--base-header-color); */
    /* border-right: 1px solid var(--color-border); */
    /* border-bottom: 1px solid var(--color-border); */
    /* border-top: 1px solid var(--color-border); */
    /* border-top:0; */
    border-color: transparent;
    border-bottom: 0;
}
.CustomFullCalendar.Weekview .Weekbox:nth-child(7n) {
    /* border-right: 1px solid var(--color-border); */
}
.CustomFullCalendar.Weekview .Weekbox.width33per{
    width: calc(33.33% - 0px);
}
.CustomFullCalendar.Weekview .Weekbox:first-child{
    /* border-left: 1px solid var(--color-border); */
    border-color: transparent;
    margin-left: 0;
} 
.ms_-5px{
    margin-left: -5px;
}
.CustomFullCalendar.Weekview .Weekbox:last-child{
    margin-right: 0;
} 
.CustomFullCalendar.Weekview .WeekDetailbox{
    /* padding: 5px; */
    margin-bottom: 12px;
}
.CustomFullCalendar.Weekview .WeekDetailHeader{
    padding: 7px;
    color: var(--color-black);
    border-radius: 3px 3px 0px 0px;
}
.CustomFullCalendar.Weekview .WeekDetailHeader.bg-request{
    background-color: var(--color-warning);
}
.CustomFullCalendar.Weekview .WeekDetailHeader.bg-notstarted{
    background-color: var(--inprogress-status);
}
.CustomFullCalendar.Weekview .WeekDetailHeader.bg-inprogress{
    background-color: var(--color-blue);
}
.CustomFullCalendar.Weekview .WeekDetailHeader.bg-done{
    background-color: var(--color-green);
}
.CustomFullCalendar.Weekview .CalendarProfile .assignee-list-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.comman_action_icon span{
    padding-left: 5px;
 }
.CustomFullCalendar .calender-action-icon {
    padding: 5px 10px;
    font-size: 14px;
    margin: 0px;
}
.radiustop{
    border-radius: 3px 3px 0px 0px ;
}
/* Month view css */
.CustomFullCalendar.Monthview{
    /* border: 0; */
    background-color: var(--white-color);
}
.CustomFullCalendar.Monthview .Monthbox{
    width: calc(14.28% - 0px);
    border-right: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    /* border: 1px solid var(--color-border); */
    min-height: 140px;
    padding-bottom: 15px;
    position: relative;
}
.CustomFullCalendar.Monthview .Monthbox.today::before{
    content: "";
    position: absolute;
    top: 9px;
    left: 6px;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    background-color: var(--lemon-yellow);
    z-index: 0;
}
.CustomFullCalendar.Monthview .Monthbox a.f-black:hover{
    color: var(--color-white-03);
}
.CustomFullCalendar.Monthview .MonthHeader{
    position: sticky;
    top: 0;
    text-align: center;
    font-weight: var(--fw-regular);
    background-color: var(--color-black);
    color: var(--white-color);
    border: 1px solid var(--black-op10);
    z-index: 9;
}
.CustomFullCalendar.Monthview .MonthHeaderbox{
    width: calc(14.28% - 0px);
    padding: 10px 5px;
    /* border-right: 1px solid var(--black-op10); */
}
.CustomFullCalendar.Monthview .Monthbox:nth-child(7n){
    border-right: 0;
}
.CustomFullCalendar.Monthview .Monthbox.disabled{
    background-color: var(--color-white-03);
}
.CustomFullCalendar.Monthview .MonthEventbox.bg-request{
    background-color: var(--lemon-yellow);
}
.CustomFullCalendar.Monthview .MonthEventbox.bg-notstarted{
    background-color: var(--color-black);
}
.CustomFullCalendar.Monthview .MonthEventbox.bg-notstarted a{
    color: var(--color-white-03);
}
.CustomFullCalendar.Monthview .MonthEventbox.bg-inprogress{
    background-color: var(--color-blue);
}
.CustomFullCalendar.Monthview .MonthEventbox.bg-done{
    background-color: var(--radium_green);
}
.CustomFullCalendar.Dayview .DayViewHeader.bg-request{
    background-color: var(--color-warning);
}
.CustomFullCalendar.Dayview .DayViewHeader.bg-notstarted{
    background-color: var(--inprogress-status);
}
.CustomFullCalendar.Dayview .DayViewHeader.bg-inprogress{
    background-color: var(--color-blue);
}
.CustomFullCalendar.Dayview .DayViewHeader.bg-done{
    background-color: var(--color-green);
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
    .CustomFullCalendar.Weekview .WeekDetailHeader{
        padding: 5px ;
    }
    .CustomFullCalendar.Weekview .CalendarProfile .f-14 {
        font-size: 10px ;
    }
    .CustomFullCalendar .calender-action-icon{
        margin: 0 0 ;
        padding: 4px 6px ;
        font-size: 10px ;
    }
}